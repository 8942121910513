import { t } from "components/CentralDataMangement/translation/Translation";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import React from "react";
import Accordion from "react-bootstrap/Accordion";
import CommProfileAccordion from "./CommProfileAccordion";
import { WageTabProps } from "components/CompanyCreation/AdminFiche/annotations/AdminFicheInterface";
import DeleteIcon from "static/images/DeleteIcon";
import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";

interface Props {
  WageAndPremiums: WageTabProps[];
  addWageData: (
    selectedOption: any,
    field: string,
    wageId?: number,
    profileId?: number
  ) => void;
  removeWageData: (
    wageId?: number,
    profileId?: number,
    codeId?: number
  ) => void;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    wageId: number,
    profileId?: number,
    codeId?: number,
    applicabilityId?: number
  ) => void;
  handleDateChange: (
    date: Date | null,
    filed: string,
    wageId: number,
    profileId?: number,
    codeId?: number
  ) => void;
  handleSelectChange: (
    value: Option | null,
    filed: string,
    wageId: number,
    profileId?: number,
    codeId?: number
  ) => void;
  clonePayProfile: (
    value: Option | null,
    wageId: number,
    profileId: number
  ) => void;
  commProfileList: OptionProps[];
  perfCodeList: Object[];
  autoTypeList: OptionProps[];
  ecoBenefitList: OptionProps[];
}
const PCAccordion: React.FC<Props> = ({
  WageAndPremiums,
  addWageData,
  removeWageData,
  handleChange,
  handleDateChange,
  handleSelectChange,
  commProfileList,
  perfCodeList,
  autoTypeList,
  clonePayProfile,
  ecoBenefitList
}) => {
  const getCommProfileDropdown = (wageId: number, pcId: number | undefined) => {
    if (!WageAndPremiums) return [];
    //Filter commProfileList based on pcId
    const filteredByPcId = commProfileList.filter(
      (option) => option.pc_id === pcId
    );
    // Get selected profiles from WageAndPremiums
    const selectedProfiles =
      Object.values(WageAndPremiums[wageId]?.commProfile || {})?.map((profile) => profile.profile) ||
      [];
    //Filter the options that are not in selectedProfiles
    const availableOptions = filteredByPcId
      .filter(
        (option) =>
          !selectedProfiles.some(
            (profile) => profile && profile.value === option.value
          )
      )
      .map((option: any) => ({
        value: option.value,
        label: option.label,
      }));
    return availableOptions.length ? availableOptions : [];
  };

  return (
    <>
      {WageAndPremiums?.length > 0 &&
        WageAndPremiums.map((wageData: WageTabProps, wageId: number) => (
          <div className="d-flex align-items-start w-100" key={`${wageId}`}>
            <Accordion defaultActiveKey="" className="mb-4 w-100" key={wageId}>
              <Accordion.Item eventKey="">
                <Accordion.Header className="w-100">
                  <div className="d-flex justify-content-between w-100">
                    <span>{wageData.pc?.label}</span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <CommProfileAccordion
                    wageData={wageData}
                    wageId={wageId}
                    addWageData={addWageData}
                    removeWageData={removeWageData}
                    handleChange={handleChange}
                    handleDateChange={handleDateChange}
                    handleSelectChange={handleSelectChange}
                    perfCodeList={perfCodeList}
                    pcId={wageData.pc?.value}
                    autoTypeList={autoTypeList}
                    getCommProfileDropdown={getCommProfileDropdown}
                    clonePayProfile={clonePayProfile}
                    ecoBenefitList={ecoBenefitList}
                  />
                  <div className="hideLabel">
                    <SelectWithSearch
                      search={true}
                      options={getCommProfileDropdown(wageId, wageData.pc?.value)}
                      value={""}
                      onChange={(e) => addWageData(e, "profile", wageId)}
                      name={"profile"}
                      placeHolder={t("Select commercial profile")}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="table-action-icons ms-3 wagePremiumActions">
              <span
                title={t("Delete")}
                onClick={() => removeWageData(wageId)}
                className="table-action-icons cursor-pointer rounded-0 shadow-none"
              >
                <DeleteIcon />
              </span>
            </div>
          </div>
        ))}
    </>
  );
};

export default React.memo(PCAccordion);
