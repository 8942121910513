import React, { useEffect, useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import Button from "components/common/atoms/Button";
import Pagination from "components/common/atoms/Pagination";
import ModalPopup from "components/common/atoms/ModalPopup";
import { Link } from "react-router-dom";
import CustomNotify from "components/common/atoms/CustomNotify";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import "static/css/translation.css";
import EditIcon from "static/images/EditIcon";
import ExportCSV from "./ExportCSV";
import { t, translate } from "./Translation";
import {
  ABSOLUTE_JOBS_APP,
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  MAX_FILE_SIZE,
  SUPER_ADMIN,
} from "Constants";
import {
  LOCALE_TARGETS,
  LOCALE_TARGETS_SEARCH,
  SAVE_TRANSLATION,
  UPDATE_TRANSLATIONS,
} from "routes/ApiEndpoints";
import Title from "components/common/atoms/Title";
import LinkTo from "components/common/atoms/LinkTo";
import Save from "static/images/Save";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import CSVReader from "components/common/molecules/CSVReader";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import Reset from "static/images/Reset";
import secureLocalStorage from "react-secure-storage";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import CloseFile from "static/images/CloseFile";
import ResetBtn from "components/common/atoms/ResetBtn";

interface Translation {
  id: number;
  translation: string;
  text: string;
  isEditing: boolean;
}

export interface LanguageData {
  [key: string]: {
    [key: string]: Translation;
  };
}
interface Language {
  id: number;
  name: string;
  key: string;
}

interface SearchProps {
  string: string;
}

const ManageTranslation = () => {
  const [languageData, setLanguageData] = useState<LanguageData>({});
  const [language, setLanguage] = useState("EN");
  const [languageDropDown, setLanguageDropDown] = useState<Language[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [key, setKey] = useState(0); // State to control the key prop of CSV reader
  const [searchData, setSearchData] = useState<SearchProps>({
    string: "",
  });
  const lang_key = secureLocalStorage.getItem("site_lang") ?? "en";
  const userAuth = useSelector(selectAuth);

  localStorage.translationStrings =
    localStorage.translationStrings || JSON.stringify([]);

  useEffect(() => {
    getLocalTarget();
  }, []);

  // Fetch language data when the component mounts
  const getLocalTarget = async () => {
    const requestData = {
      appName: ABSOLUTE_JOBS_APP,
      langKey: lang_key,
    };
    const response = await ApiCall.service(
      LOCALE_TARGETS,
      "POST",
      requestData,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      const initialLanguageData: LanguageData = {};
      setLanguageDropDown(response.languages);
      setLanguage(Object.keys(response.translations)[0]);
      Object.keys(response.translations).forEach((languageKey) => {
        initialLanguageData[languageKey] = {};
        Object.keys(response.translations[languageKey]).forEach(
          (translationKey) => {
            initialLanguageData[languageKey][translationKey] = {
              ...response.translations[languageKey][translationKey],
              isEditing: false, // Initialize the isEditing state
            };
          }
        );
      });
      setLanguageData(initialLanguageData);
    }
  };

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLanguage(event.target.value);
  };

  const handleModalClose = () => setShowModal(!showModal);

  /**
   * edit, save, cancel action to handler
   * @param key
   * @param type
   */
  const handleEditClick = async (key: any, type: string) => {
    if (type === "save") {
      if (languageData[language][key].translation === "") {
        CustomNotify({
          type: "warning",
          message: t("Please provide translation"),
        });
        return;
      }
      const response = await ApiCall.service(
        SAVE_TRANSLATION,
        "POST",
        {
          translation: languageData[language][key].translation,
          key: key,
        },
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response.status === 200) {
        CustomNotify({
          type: "success",
          message: t(response.message) || t(response.msg),
        });
      } else {
        CustomNotify({
          type: "error",
          message: t(response.message) || t(response.msg),
        });
      }
    }
    setLanguageData((prevLanguageData) => {
      const newLanguageData = {
        ...prevLanguageData,
        [language]: {
          ...prevLanguageData[language],
          [key]: {
            ...prevLanguageData[language][key],
            isEditing: !prevLanguageData[language][key].isEditing,
          },
        },
      };

      return newLanguageData;
    });
  };

  /**
   * Translation update in languageData
   * @param translationKey
   * @returns
   */
  const handleInputChange =
    (translationKey: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;

      setLanguageData((prevLanguageData) => {
        const newLanguageData = {
          ...prevLanguageData,
          [language]: {
            ...prevLanguageData[language],
            [translationKey]: {
              ...prevLanguageData[language][translationKey],
              translation: newValue,
            },
          },
        };

        return newLanguageData;
      });
    };

  /**
   * Page handler
   * @param pageNumber
   */
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  let itemsPerPage = 10;
  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const entriesArray =
    languageData[language] &&
    Object.values(languageData[language]).sort((a, b) =>
      a.text.localeCompare(b.text, undefined, { sensitivity: "base" })
    );
  const currentItems =
    languageData[language] &&
    entriesArray.slice(indexOfFirstItem, indexOfLastItem);
console.log(currentItems);

  // Calculate the total number of pages
  const totalPages =
    languageData[language] &&
    Math.ceil(Object.keys(languageData[language]).length / itemsPerPage);

  let index = 0;

  const importFromCSV = async (importedData: any, file: any) => {
    if (!importedData || importedData.length === 0) {
      setErrorMsg(t("Please select a CSV file to upload."));
      return;
    }

    if (file.type !== "text/csv") {
      setErrorMsg(
        t("Invalid file format, pleases upload only .csv file format.")
      );
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setErrorMsg(t("File size is greater than 10MB."));
      return;
    }

    const importedHeaders = importedData.data[0];

    if (!Object.keys(languageData).includes(importedHeaders[2])) {
      setErrorMsg(t("Imported CSV headers do not match the expected format."));
      return;
    }

    const missingDeveloperStrings: string[] = [];

    importedData.data.slice(1).forEach((row: any) => {
      const text = row[1];

      if (!text) {
        setErrorMsg(t("Empty developer string found in imported data."));
        return;
      }

      const matchingLanguage = Object.keys(languageData).find((language) =>
        Object.values(languageData[importedHeaders[2]]).some(
          (translation) => translation.text === text
        )
      );

      if (!matchingLanguage) {
        missingDeveloperStrings.push(text);
        return; // Skip further processing for this row
      }

      let id = NaN;
      if (row.length > 1) {
        for (const data in languageData[importedHeaders[2]]) {
          if (
            data &&
            languageData[importedHeaders[2]][data].text === text.toString()
          ) {
            id = languageData[importedHeaders[2]][data].id;
          }
        }

        const translation: Translation = {
          id: id,
          translation: row[index + 2],
          text,
          isEditing: false,
        };

        if (
          languageData &&
          languageData[importedHeaders[2]] &&
          languageData[importedHeaders[2]][translation.id]
        ) {
          languageData[importedHeaders[2]][translation.id] = translation;

          const updatedLanguageData = {
            ...languageData,
            [importedHeaders[2]]: {
              ...languageData[importedHeaders[2]],
              [translation.id]: translation,
            },
          };

          // Update the state with the new data
          setLanguageData(updatedLanguageData);
        }
      }
    });

    setErrorMsg("");

    let response = await ApiCall.service(
      UPDATE_TRANSLATIONS,
      "POST",
      {
        translations: languageData[importedHeaders[2]],
      },
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      setLanguage("EN");
      CustomNotify({
        type: "success",
        message: t(response.message) || t(response.msg),
      });
      setKey((prevKey) => prevKey + 1); // Update the key to force re-render
    }
  };

  const getLocaleStrings = async () => {
    const response = await ApiCall.service(
      LOCALE_TARGETS_SEARCH,
      "POST",
      searchData,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      setLanguageData(response.translations);
    }
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    await getLocaleStrings();
  };

  const handleReset = async (e: React.FormEvent) => {
    setSearchData({
      string: "",
    });
    setCurrentPage(1);
    getLocalTarget();
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Translation",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <Title title={t("Translations")} />
        <div className="form-height-dashboard">
          <div className="row search-bar">
            <div className="col-8">
              <LabelWithInputField
                label={t("Developer strings")}
                name="string"
                handleChange={handleSearchChange}
                placeholder={t("Search by developer strings")}
                value={searchData.string}
              />
            </div>
            <div className="col-4 text-end mt-34">
              <div className="d-flex align-items-center">
                <ResetBtn handleResetClick={handleReset} />
                <Button
                  title={t("Search")}
                  icon={faMagnifyingGlass}
                  type="submit"
                  handleClick={handleSearch}
                  className="form-button shadow-none search-btns text-start position-relative"
                />
              </div>
            </div>
          </div>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Translation",
                update: true,
                create: true,
              },
            ]}
            actions={true}
            strict={false}
          >
            <div className="csvReader">
              <CSVReader
                key={key}
                importFromCSV={importFromCSV}
                errorMsg={errorMsg}
              />
            </div>
          </AccessControl>
          {userAuth.role === SUPER_ADMIN && (
            <div className="row marginBotttom1">
              {/* <div className="col-lg-3 translation-language-dropdown mb-3 mb-lg-0">
                  <select
                  value={language}
                  onChange={handleLanguageChange}
                  className="border-0 form-select rounded-3 px-3 py-1 field-shadow select-field"
                >
                  {languageDropDown &&
                    languageDropDown
                      .sort((a, b) => a?.name.localeCompare(b.name)) // Sort the languages array by name
                      .map((language) => (
                        <option key={language.key} value={language.key}>
                          {language.name}
                        </option>
                      ))}
                </select>
                </div> */}
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Translation",
                    update: true,
                    create: true,
                  },
                ]}
                actions={true}
                strict={false}
              >
                {currentItems && currentItems.length > 0 && (
                  <ExportCSV languageData={languageData} language={language} />
                )}
              </AccessControl>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <div className="position-relative tableMainWrapper">
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Translation",
                      update: true,
                      create: true,
                    },
                  ]}
                  actions={true}
                  strict={false}
                >
                  <div className="row">
                    <div className="ManageCreateBtn">
                      <LinkTo
                        pagelink="/translation/link-app-language"
                        title={t("Link language")}
                        icon={faPlus}
                      />
                    </div>
                  </div>
                </AccessControl>
                <div
                  className="table-responsive tableSection"
                  style={{ paddingTop: "2vw" }}
                >
                  <table className="table table-hover">
                    <thead>
                      <tr className="TableHeader">
                        <th className="ps-lg-4" style={{ width: "40%" }}>
                          {t("Developer strings")}
                        </th>
                        <th style={{ width: "40%" }}>{t("Translations")}</th>
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "Translation",
                              update: true,
                            },
                          ]}
                        >
                          <th className="ps-4">{t("Action")}</th>
                        </AccessControl>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems && currentItems.length > 0 ? (
                        Object.values(currentItems).map((data, key) => (
                          <tr
                            key={key}
                            className="border-bottom box-shadow mb-3 align-middle"
                          >
                            <td
                              className="text-break ps-lg-4"
                              data-label={t("Developer String")}
                            >
                              {data.text}
                            </td>
                            <td
                              className="text-break"
                              data-label={t("Translation")}
                            >
                              <input
                                className="form-input form-control field-shadow rounded-3 text-break text-start"
                                type="text"
                                value={data.translation}
                                onChange={handleInputChange(data.id)}
                                disabled={!data.isEditing}
                              />
                            </td>
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Translation",
                                  update: true,
                                },
                              ]}
                            >
                              <td className="table-action-icons px-2 ps-lg-4">
                                <div className="d-none d-md-none d-lg-block">
                                  {data.isEditing ? (
                                    <>
                                      <span
                                        title={t("Save")}
                                        onClick={() =>
                                          handleEditClick(data.id, "save")
                                        }
                                        className="btn p-0 border-0 me-2"
                                      >
                                        <Save />
                                      </span>
                                      <span
                                        title={t("Cancel")}
                                        onClick={() =>
                                          handleEditClick(data.id, "cancel")
                                        }
                                        className="btn p-0 border-0 me-1"
                                      >
                                        <CloseFile />
                                      </span>
                                    </>
                                  ) : (
                                    <span
                                      title={t("Edit")}
                                      onClick={() =>
                                        handleEditClick(data.id, "edit")
                                      }
                                      className="cursor-pointer"
                                    >
                                      <EditIcon />
                                    </span>
                                  )}
                                </div>
                                <div className="d-block d-md-block d-lg-none text-center">
                                  {data.isEditing ? (
                                    <>
                                      <div>
                                        <Button
                                          title={t("Save")}
                                          handleClick={() =>
                                            handleEditClick(data.id, "save")
                                          }
                                          className="btn p-0 me-1 form-button mb-2 rounded-3 shadow-none  button-width"
                                        />
                                      </div>
                                      <div>
                                        <Button
                                          title={t("Cancel")}
                                          handleClick={() =>
                                            handleEditClick(data.id, "cancel")
                                          }
                                          className="btn p-0 me-1 delete-btn rounded-3 shadow-none  button-width mb-2"
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <Button
                                      title={t("Edit")}
                                      handleClick={() =>
                                        handleEditClick(data.id, "edit")
                                      }
                                      className="btn p-0 me-1 form-button rounded-3  button-width mb-2"
                                    />
                                  )}
                                </div>
                              </td>
                            </AccessControl>
                          </tr>
                        ))
                      ) : (
                        <tr className="border rounded-3">
                          <td
                            colSpan={4}
                            className="border-0 text-center py-3 no-records"
                          >
                            <span className="text-danger w-100 d-block text-center">
                              {t("No records")}
                            </span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {totalPages > 1 && (
                    <div className="pagination justify-content-center">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row backPadding">
          <div className="col-md-6 align-self-center">
            <Link
              to="/dashboard"
              className="back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Delete confirmation")}
          body={t("Are you sure want to delete") + "?"}
          onCloseButtonClick={handleModalClose}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </AccessControl>
    </>
  );
};

export default translate(ManageTranslation);
