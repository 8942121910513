import { OptionProps } from "components/common/utlis/TypeAnnotations";

interface dropDownList {
    offices: OptionProps[],
    agents: OptionProps[],
    units: OptionProps[],
    types: OptionProps[],
    status: OptionProps[],
}
export const createFilterConfig = (dropDownList: dropDownList) => {
    return [
        {
            name: "office",
            options: dropDownList.offices,
            fieldType: "multiSelect",
            placeholder: "Office",
            filterType: 'search'
        },
        {
            name: "companyName",
            fieldType: "text",
            placeholder: "Company",
            filterType: 'search'
        },
        {
            name: "unit",
            options: dropDownList.units,
            fieldType: "singleSelect",
            placeholder: "Unit",
            filterType: 'search',
            prefixClass: 'selectSection'
        },
        {
            name: "types",
            options: dropDownList.types,
            fieldType: "singleSelect",
            placeholder: "Type",
            filterType: 'search',
            prefixClass: 'selectSection'
        },
        {
            name: "salesAgent",
            options: dropDownList.agents,
            fieldType: "singleSelect",
            placeholder: "Sales agent",
            filterType: 'search',
            prefixClass: 'selectSection'
        },

        {
            name: "status",
            options: dropDownList.status,
            fieldType: "singleSelect",
            placeholder: "Status",
            filterType: 'search',
            prefixClass: 'selectSection'
        }
    ];
};
