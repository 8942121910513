import React, { useEffect, useState } from "react";
import ModalPopup from "components/common/atoms/ModalPopup";
import Button from "components/common/atoms/Button";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { t } from "components/CentralDataMangement/translation/Translation";
import * as ENDPOINTS from "../../../routes/ApiEndpoints";
import { ApiCall } from "../services/ApiServices";
import {
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  COMPANY_MICROSERVICE,
} from "Constants";
import SelectWithSearch from "../atoms/SelectWithSearch";
import SignatureField from "../atoms/SignatureField";

interface AuthenticationModalProps {
  companyId?: string | undefined | null;
  show: boolean;
  state: any;
  setState: any;
  handleSelectChange: (event: React.FormEvent, fieldName: string) => void;
  employerModal: (type: string) => void;
  checkAuthentication: (event: React.FormEvent) => void;
  errors: { [key: string]: string };
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SignAsEmployerModal: React.FC<AuthenticationModalProps> = ({
  companyId,
  show,
  state,
  setState,
  handleSelectChange,
  employerModal,
  errors,
  checkAuthentication,
  changeHandler,
}) => {
  const [signState, setSignState] = useState({
    sign: "",
    disabled: false,
  });
  useEffect(() => {
    getCompanyContactList();
  }, [!show]);

  const getCompanyContactList = async () => {
    const url = `${ENDPOINTS.GET_COMPANY_CONTACT_LIST}/${companyId}`;
    const response = await ApiCall.getService(
      url,
      "GET",
      COMPANY_MICROSERVICE,
      true
    );

    if (response.status === 200) {
      const data = response.data;
      setState((prevState: any) => ({
        ...prevState,
        contacts: data,
      }));
    }
  };

  const addEmployerSign = () => {
    setState((prevState: any) => ({
      ...prevState,
      addSignature: true,
    }));
  };

  const saveSign = async (signData: string) => {
    const data = {
      sign: signData,
      entityId: state?.selectedContact?.value,
    };
    const response = await ApiCall.service(
      ENDPOINTS.ADD_SIGNATURE,
      "POST",
      data,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      setState((prevState: any) => ({
        ...prevState,
        email: state.selectedContact.email,
        selectedContact: {
          ...prevState.selectedContact,
          signature_exists: true,
        },
      }));
    }
  };

  return (
    <>
      <ModalPopup
        show={show}
        onHide={employerModal}
        title={t("Sign as an employer")}
        body={
          <form onSubmit={checkAuthentication}>
            <SelectWithSearch
              search={true}
              options={state.contacts}
              value={state.selectedContact}
              onChange={(e) => handleSelectChange(e, "contacts")}
              name="contacts"
              title="Contacts"
              isMandatory={true}
              error={errors.contacts}
            />
            {state?.selectedContact?.signature_exists && (
              <div>
                <LabelWithInputField
                  handleChange={changeHandler}
                  label={t("Email")}
                  type="text"
                  isDisabled={true}
                  id="email"
                  name="email"
                  value={state.email}
                  error={errors.email}
                  autoComplete="off"
                  isMandatory={true}
                />
                <LabelWithInputField
                  handleChange={changeHandler}
                  isMandatory={true}
                  label={t("Password")}
                  type="password"
                  id="password"
                  name="password"
                  value={state.password}
                  error={errors.password}
                  autoComplete="new-password"
                />
                <Button
                  type="submit"
                  title={t("Authenticate")}
                  className="btn float-end form-button shadow-none rounded-3 me-2"
                />
              </div>
            )}
            {state?.selectedContact !== null &&
              state?.selectedContact?.value !== null &&
              !state?.selectedContact?.signature_exists &&
              !state.addSignature && (
                <>
                  <span>
                    Employer has not added the signature. Do you want to add the
                    signature?
                  </span>
                  <Button
                    type="submit"
                    title={t("Add signature")}
                    handleClick={addEmployerSign}
                    className="btn float-end form-button shadow-none rounded-3 me-2"
                  />
                </>
              )}
            {state?.selectedContact !== null &&
              state?.selectedContact?.value !== null &&
              !state?.selectedContact?.signature_exists &&
              state.addSignature && (
                <SignatureField
                  className={"signatureCanvas"}
                  onSave={saveSign}
                  disabled={signState.disabled}
                  dataUrl={signState.sign}
                  width={"100%"}
                />
              )}
          </form>
        }
        className="modal-lg"
      />
    </>
  );
};

export default SignAsEmployerModal;
