import { useFormContext } from "components/CandidateCreation/context/Context";
import FileUpload from "components/common/molecules/FileUpload";
import Calender from "components/common/molecules/Calender";
import { useState } from "react";
import Navigation from "../formNavigation/Navigation";
import { scrollToTop } from "components/common/services/ValidationService";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import LabelField from "components/common/atoms/LabelField";
import Certificates from "./tabContentComponents/Certificates";
import Documents from "./tabContentComponents/Documents";

const EmployeeDocumentsTab: React.FC = () => {
  const { state, dispatch, isViewMode } = useFormContext();

  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const fileUploadStyleObject: React.CSSProperties = {
    width: "100%",
  };

  // ****************Permit fields code start ***************

  const handlePermitFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isViewMode) return;
    const { name, checked } = event.target;
    const value = checked ? 1 : 0;
    dispatch({
      type: "UPDATE_PERMIT_FIELD",
      field: name,
      value: value,
    });
    if (value === 0) {
      resetPermitFields();
    }
  };

  const handlePermitFieldDateChange = (date: Date | null, name: string) => {
    if(isViewMode) return;
    const updatedDate = date ?? new Date();
    dispatch({
      type: "UPDATE_PERMIT_FIELD",
      field: name,
      value: updatedDate,
    });
    if (state.permitErrors.perFrom || state.permitErrors.perTo) {
      dispatch({ type: "UPDATE_WORK_PERMIT_ERROR", field: name, error: "" });
    }
  };

  const resetPermitFields = () => {
    const fieldsToClear = [
      { field: "perFrom", value: null },
      { field: "perTo", value: null },
      { field: "permitFileId", value: null },
      { field: "fileName", value: "" },
      { field: "filePath", value: "" },
    ];
    fieldsToClear.map((field) => {
      dispatch({
        type: "UPDATE_PERMIT_FIELD",
        field: field.field,
        value: field.value,
      });
    });
  };

  const permitFileUploadResponse = (responseObj: any) => {
    const response = responseObj["response"];
    const permitFields = ["permitFileId", "fileName", "filePath"];
    permitFields.forEach((fieldName: string, key: number) => {
      dispatch({
        type: "UPDATE_PERMIT_FIELD",
        field: fieldName,
        value: response[key],
      });
    });
  };

  const permitFileDeleteResponse = (field: string) => {
    const permitFields = ["permitFileId", "fileName", "filePath"];
    permitFields.forEach((fieldName: string, key: number) => {
      dispatch({
        type: "UPDATE_PERMIT_FIELD",
        field: fieldName,
        value: fieldName.endsWith("Id") ? null : "",
      });
    });
  };

  const returnFileUploadPermitErrors = (errorResposne: any) => {
    const step = errorResposne["step"];
    const error = errorResposne["error"];
    dispatch({ type: "UPDATE_WORK_PERMIT_ERROR", field: step, error: error });
  };

  // *********************** Permit fields code end ********************************

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop(400);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="form-border" style={{ paddingTop: "3.4vw" }}>
            <Certificates />
            {/* ************************************************************************ */}
            {/* Permit field code start */}
            <div className="row">
              <div className="col-12">
                <LabelField title={t("Documents")} className="tab-subtitle" />
              </div>
              <div className="col-md-12">
                <div
                  className={`marginBotttom1 ${isViewMode ? "form disableform" : "form"
                    }`}
                >
                  <CheckBoxField
                    label={t("Non european citizen")}
                    name="europeCitizen"
                    onChangeHandler={handlePermitFieldChange}
                    isChecked={state.workPermit.europeCitizen === 1}
                    className="document-checkbox"
                    lineHeight="1.1vw"
                    id="nonEuropeanCitizen"
                  />
                </div>
                {state.workPermit.europeCitizen === 1 && (
                  <div
                    className="d-grid"
                    style={{
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: "0.8vw",
                    }}
                  >
                    <div className="upload-certificate">
                      <FileUpload
                        id="permitFileId"
                        name="permitFileId"
                        label={t("Work permit")}
                        isMandatory={false}
                        edit={false}
                        fileId={
                          state.workPermit.permitFileId
                            ? state.workPermit.permitFileId
                            : null
                        }
                        filePath={
                          state.workPermit.filePath
                            ? state.workPermit.filePath
                            : ""
                        }
                        fileName={
                          state.workPermit.fileName
                            ? state.workPermit.fileName
                            : ""
                        }
                        style={fileUploadStyleObject}
                        multiple={false}
                        uploadPath="candidate-certificate"
                        formats=".pdf,.docx,.doc,.jpg,.jpeg"
                        returnFileUploadErrors={returnFileUploadPermitErrors}
                        fileUploadResponse={permitFileUploadResponse}
                        deleteResponse={permitFileDeleteResponse}
                        maxFileSize={10 * 1024 * 1024}
                        showDeleteIcon={!isViewMode}
                        isViewMode={isViewMode}
                      />
                      <div
                        className="height-20"
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <span className="text-danger">
                          {state.permitErrors.permitFileId}
                        </span>
                      </div>
                    </div>
                    <div
                      className={`position-relative ${isViewMode ? "form disableform" : "form"
                        }`}
                    >
                      <Calender
                        onChange={(date) =>
                          handlePermitFieldDateChange(date, "perFrom")
                        }
                        selectedDate={state.workPermit.perFrom}
                        label={t("From")}
                        name="preFrom"
                        maxDate={state.workPermit.perTo}
                        isMandatory={false}
                        error={state.permitErrors.perFrom}
                      />
                    </div>
                    <div
                      className={`position-relative ${isViewMode ? "form disableform" : "form"
                        }`}
                    >
                      <Calender
                        onChange={(date) =>
                          handlePermitFieldDateChange(date, "perTo")
                        }
                        selectedDate={state.workPermit.perTo}
                        label={t("To")}
                        name="to"
                        isMandatory={false}
                        minDate={state.workPermit.perFrom}
                        error={state.permitErrors.perTo}
                      />
                    </div>
                  </div>
                )}
                <div>{/* Permit field code end */}</div>
              </div>
              <Documents />
              <div className="disclaimerText">
                {t(
                  "Any form of discrimination based on sex, perceived race, colour, descent, nationality or ethnic origin, sexual orientation, marital status, birth, property, age, religious or philosophical beliefs, current or future state of health, disability or physical characteristic is prohibited."
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navigation validStatus={validStatus} />
    </form>
  );
};
export default translate(EmployeeDocumentsTab);
