import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { t } from "components/CentralDataMangement/translation/Translation";
import "../CandidateCreation/create-candidate/css/Candidate.css";
import CardCircle from "components/common/atoms/CardCircle";
import { FILE_URL } from "../../routes/ApiEndpoints";
import axios from "axios";
import * as ENDPOINTS from "../../routes/ApiEndpoints";
import DownloadIcon from "static/images/Download";
import RightArrow from "static/images/RightArrow";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import EditIcon from "static/images/EditIcon";
import { CALL_3CX, CANDIDATE_MICROSERVICE } from "Constants";
import SalesNote from "components/CentralDataMangement/salesNote/SalesNoteModalPopups";
import ModalPopup from "components/common/atoms/ModalPopup";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
import ToggleButton from "components/common/molecules/ToogleButton";
import CalendarIcon from "static/images/CalendarIcon";
import ResumeIcon from "static/images/ResumeIcon";
import Survey from "static/images/Survey";
import InfoIcon from "static/images/InfoIcon";

export type MatchingCandidateProfileCardType = {
  candidateId?: number | null;
  firstName: string;
  lastName: string;
  functions: any;
  mobNumber?: string;
  email?: string;
  street?: string;
  number?: string;
  box?: string;
  zipCode?: string;
  city?: string;
  picFileName?: string;
  picFilePath?: string;
  country?: string;
  cvData?: any;
  profilePicData?: any;
  isScreening?: boolean;
  source?: string;
  isMatching?: boolean;
  isEdit?: boolean;
  userId?: number | null;
  brightId?: number | null;
  consultantName?: string;
  consultantId?: number;
  is3daysago?: boolean;
  officeName?: string;
  createdAt?: string;
  postScreeningStatus?: number;
  dob?: Date | null;
  age?: string;
  consentForm?: string;
  officeUnit?: string;
  officeFullName?: string;
};

export interface MatchingProfileCardProps {
  profileCardProps: MatchingCandidateProfileCardType;
}

const MatchingProfileCard: React.FC<MatchingProfileCardProps> = (props) => {
  const navigate = useNavigate();
  const authUser = useSelector(selectAuth);
  const [screeningPopup, setScreeningPopup] = useState({
    isPopupOpen: false
  });

  const functionList =
    props.profileCardProps.functions === undefined
      ? []
      : props.profileCardProps.functions;

  let address = [];

  if (props.profileCardProps.street) {
    address.push(props.profileCardProps.street);
  }
  if (props.profileCardProps.number) {
    address.push(props.profileCardProps.number);
  }
  if (props.profileCardProps.zipCode) {
    address.push(props.profileCardProps.zipCode);
  }

  if (props.profileCardProps.city) {
    address.push(props.profileCardProps.city);
  }

  if (props.profileCardProps.country) {
    address.push(props.profileCardProps.country);
  }

  let fullAddress = address.join(", ");

  const downloadFile = async (
    id: number | null,
    name: string | null,
    path: string | null,
    uploadPath: string | null
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_SERVICE}/${ENDPOINTS.FILE_DOWNLOAD}/${id}/${uploadPath}`
      );
      if (response.status === 200) {
        const fileUrl = response.data.url;
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = fileUrl;
        link.target = "_blank";
        link.download = response.data.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error fetching download URL:", error);
    }
  };

  // Sales note
  const [salesnote, setSalesNote] = useState({
    name: "",
    phone: "" as string | undefined,
    id: null,
    navigate: "",
    activeRecordingId: null as number | null,
    triggerMeeting: false as boolean,
    type: null as number | null,
  });

  const handleMeeting = async (
    name: string,
    id: any | null,
    candidateId: any,
    phone?: string | null,
    navigate?: string
  ) => {
    setSalesNote({
      name: name,
      phone: phone !== null ? phone : "",
      id: id,
      navigate: navigate ?? "",
      activeRecordingId: candidateId,
      triggerMeeting: true,
      type: 1, // For company contacts type is 3, candidates  is 1, companies is 2
    });
  };

  const updateSalesNote = (updatedSalesNote: any) => {
    setSalesNote(updatedSalesNote);
  };

  const navigateToScreening = () => {
    if (!props?.profileCardProps?.is3daysago && authUser.userId !== props?.profileCardProps?.consultantId) {
      setScreeningPopup({ isPopupOpen: true })
    } else {
      navigate(`/screening/${props.profileCardProps.candidateId}`, { state: { origin: props.profileCardProps.source } });
    }
  }

  const handleModalClose = () => setScreeningPopup({ isPopupOpen: false });

  const handleSaveChanges = async () => {
    setScreeningPopup({ isPopupOpen: false });
    navigate(`/screening/${props.profileCardProps.candidateId}`, { state: { origin: props.profileCardProps.source } });
  }

  /***** For storing post screening status */
  const [localPostScreeningStatus, setLocalPostScreeningStatus] = useState(
    props.profileCardProps.postScreeningStatus
  );

  useEffect(() => {
    setLocalPostScreeningStatus(props.profileCardProps.postScreeningStatus);
  }, [props.profileCardProps.postScreeningStatus]);

  const handleToggleChange = async (newValue: boolean) => {
    const newStatus = newValue ? 1 : 0; // Toggle between 1 and 0
    const data = {
      candidateId: props.profileCardProps.candidateId,
      postScreeningStatus: newStatus,
    };

    setLocalPostScreeningStatus(newStatus);

    const response = await ApiCall.service(
      ENDPOINTS.STORE_POST_SCREENING_STATUS,
      "POST",
      data,
      true,
      CANDIDATE_MICROSERVICE
    );
    if (response.status === 200) {
      CustomNotify({
        type: "success",
        message: t("Post screening interest saved successfully") + "!",
      });
    }
  };

  const getAge = () => {
    if (props.profileCardProps.dob && props.profileCardProps.dob !== null) {
      const birthDate = new Date(props.profileCardProps.dob);
      if (!birthDate) {
        return null; // Return null if date of birth is not available
      }

      const birthYear = birthDate.getFullYear(); // Get birth year

      const currentDate = new Date(); // Get current date
      const currentYear = currentDate.getFullYear(); // Get current year

      return currentYear - birthYear; // Calculate and return the age
    }
    return null;
  };

  const location = useLocation();

  // Check if the URL path includes "candidate/corner"
  const isCandidateCornerPage = location.pathname.includes("candidate/corner");

  /***** For storing post screening status */

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Candidate",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className="row">
        <div className="col-md-7">
          <div
            style={{
              marginLeft: "3.5vw",
            }}
          >
            <div
              className="card position-relative border-0"
              style={{
                backgroundColor: "var(--profileBgColor)",
                padding: "2.5vw",
                borderRadius: "1vw"
              }}
            >
              <div className="cv-uplod">
                <CardCircle
                  position="absolute"
                  width="7.813vw"
                  height="7.813vw"
                  backgroundColor="var(--primaryBrandColor)"
                  left="0vw"
                  top={"50%"}
                  transform={`translate(-3.5vw, -3.9vw)`} // Centering horizontally and vertically
                  className="me-5 flex-column cursor-pointer justify-content-center"
                  color="var(--color-white)"
                  inputClass={"mb-2"}
                >
                  {props.profileCardProps?.profilePicData?.fileName !== "" &&
                    props.profileCardProps?.profilePicData?.fileName !==
                    undefined ? (
                    <img
                      src={
                        props.profileCardProps?.profilePicData?.filePath?.startsWith(
                          "https"
                        )
                          ? `${props.profileCardProps?.profilePicData?.filePath}`
                          : `${FILE_URL}/${props.profileCardProps?.profilePicData?.filePath}`
                      }
                      alt={t("Uploaded")}
                      style={{
                        backgroundColor: "var(--color-white)",
                        borderRadius: "50%",
                        boxShadow: "rgb(248 216 235) 1px 0px 10px 3px",
                      }}
                      width="100%"
                      height="100%"
                    />
                  ) : (
                    <img
                      src="/static/images/Person.svg"
                      alt="Profile"
                      width="100%"
                      height="100%"
                    />
                  )}
                </CardCircle>
              </div>
              <div className="row">
                <div className="col-md-10 ms-auto">
                  <div className="d-flex align-items-start justify-content-between text-break">
                    {(props.profileCardProps.firstName ||
                      props.profileCardProps.lastName) && (
                        <div className={`color-dark-pink name-text text-start`}>
                          {props.profileCardProps.firstName}{" "}
                          {props.profileCardProps.lastName}
                        </div>
                      )}
                    {props?.profileCardProps?.isEdit && (
                      <div className="float-end table-action-icons px-2">
                        <Link
                          to={`/candidate/edit/${props.profileCardProps.candidateId}`}
                          className="btn p-0 border-0 me-2 "
                          title={t("Edit")}
                        >
                          <EditIcon />
                        </Link>
                        {isCandidateCornerPage && props.profileCardProps.consentForm && (
                          <Link
                            to={props.profileCardProps.consentForm}
                            className="btn p-0 border-0 me-2"
                            title={t("View consent form")}
                            target="_blank"
                          >
                            <Survey />
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                  <div
                    className="job-title text-start"
                    style={{ marginTop: "0.8vw", marginBottom: "1vw" }}
                  >
                    <span>{t("looking for a job as")}</span>
                    <span>
                      {functionList.length > 0 && (
                        <strong> {functionList.join(", ")}</strong>
                      )}
                    </span>
                  </div>
                  <div className="candidate-person-details">
                    {props.profileCardProps.mobNumber && (
                      <div
                        className="d-flex align-items-center"
                        style={{ marginBottom: "1vw" }}
                      >
                        <a
                          onClick={() =>
                            handleMeeting(
                              props.profileCardProps.firstName,
                              props.profileCardProps.userId,
                              props.profileCardProps.candidateId,
                              props.profileCardProps.mobNumber,
                              CALL_3CX
                            )
                          }
                          className="cursor-pointer link-color"
                        >
                          <img
                            src="../../static/images/mobile.png"
                            alt={t("Mobile")}
                            className="profileCardImage"
                          />
                          <span className={`ms-3`}>
                            {props.profileCardProps.mobNumber}
                          </span>
                        </a>
                      </div>
                    )}
                    {props.profileCardProps.dob &&
                      props.profileCardProps.dob !== null && (
                        <div
                          className="d-flex align-items-center "
                          style={{ marginBottom: "0.8vw" }}
                        >
                          <span className="color-dark-pink profileCalendar">
                            <CalendarIcon />
                          </span>
                          <span className={`ms-3`}>
                            {`${props.profileCardProps.dob} (${getAge()} ${t(
                              "Years"
                            )})`}
                          </span>
                        </div>
                      )}
                    {props.profileCardProps.email && (
                      <div
                        className="d-flex align-items-center"
                        style={{ marginBottom: "1vw" }}
                      >
                        <a
                          href={`mailto:${props.profileCardProps.email}`}
                          target="_blank"
                          className="link-color"
                        >
                          <img
                            src="../../static/images/mail.png"
                            alt={t("Mail")}
                            className="profileCardImage"
                          />
                          <span className={`ms-3`}>
                            {props.profileCardProps.email}
                          </span>
                        </a>
                      </div>
                    )}
                    {fullAddress && (
                      <div className="d-flex align-items-start">
                        <img
                          src="../../static/images/home.png"
                          alt={t("Address")}
                          className="profileCardImage"
                        />
                        <span className={`ms-3`}>
                          <div className="mb-2 text-start">{fullAddress}</div>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 align-self-center candidate-cv-download">
          <div className="shout-icon d-flex align-items-center" style={{ paddingTop: "2vw" }}>
            <div className="d-flex">
              <img
                src="../../static/images/megaphone.svg"
                alt={t("Megaphone")}
                className="megaPhoneIcon"
              />
              <div className="available-text mb-2 fraunces-italic color-dark-pink">
                <div>{t("Available")}</div>
                <div>{t("for work!")}</div>
              </div>
            </div>
          </div>
          {props.profileCardProps?.cvData?.fileId &&
            props.profileCardProps?.cvData?.fileName &&
            props.profileCardProps?.cvData?.filePath && (
              <div className="d-flex matchingCandidateBtn">
                <span
                  onClick={(event) =>
                    downloadFile(
                      props.profileCardProps.cvData.fileId
                        ? props.profileCardProps.cvData.fileId
                        : null,
                      props.profileCardProps.cvData.fileName
                        ? props.profileCardProps.cvData.fileName
                        : "",
                      props.profileCardProps.cvData.filePath
                        ? props.profileCardProps.cvData.filePath
                        : "",
                      "candidate-cv"
                    )
                  }
                  className="cursor-pointer"
                >
                  <div
                    className="form-button candidateDownload matchingBtn d-flex justify-content-between align-items-center"
                    title={t("Download")}
                  >
                    <span className="me-3">{t("Download CV")}</span>
                    <DownloadIcon />
                  </div>
                </span>
              </div>
            )}
          <div className="d-flex align-items-center mt-2">
            {props.profileCardProps.isScreening && (
              <div className="d-flex matchingCandidateBtn marginRightPoint5 marginTop0">
                <Link
                  className="form-button matchingBtn"
                  title=""
                  to={""}
                  onClick={() => navigateToScreening()}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    {t("Screening")} &nbsp;
                    <span className="ms-3">
                      <RightArrow />
                    </span>
                  </div>
                </Link>
              </div>
            )}
            {isCandidateCornerPage && (
              <div className="d-flex align-items-center">
                <div className="color-dark-pink marginRightPoint5 postScreeingQns">
                  <span title={t("Are you interested in post screening stages") + "?"}>
                    <InfoIcon />
                  </span>
                </div>
                <ToggleButton
                  value={props.profileCardProps.postScreeningStatus === 1}
                  onToggle={handleToggleChange}
                  disabled={false} // Set this to true to disable the button
                  toggleClassName="" // Custom class for styling
                  activeText={t("Yes")}
                  inactiveText={t("No")}
                />
              </div>
            )}
          </div>

          {localPostScreeningStatus === 1 &&
            props.profileCardProps?.isMatching && (
              <div className="d-flex matchingCandidateBtn">
                <Link
                  className="form-button matchingBtn"
                  title=""
                  to={`/matching-list/candidate/${props.profileCardProps.candidateId}`}
                  state={{ origin: props.profileCardProps.source }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    {t("Matching")} &nbsp;
                    <span className="ms-3">
                      <RightArrow />
                    </span>
                  </div>
                </Link>
              </div>
            )}
        </div>

        {/* Sales note */}
        <SalesNote salesnote={salesnote} setSalesNote={updateSalesNote} />
        <ModalPopup
          size="lg"
          show={screeningPopup.isPopupOpen}
          onHide={() => setScreeningPopup({ isPopupOpen: false })}
          title={t("Action disabled")}
          body={`Hi, our colleague ${props?.profileCardProps?.consultantName} from Absolute ${props?.profileCardProps?.officeUnit} & ${props?.profileCardProps?.officeName} ${props?.profileCardProps?.officeFullName} recently registered this candidate.
           You can only officially re-register this candidate from the 3rd day count of ${props?.profileCardProps?.createdAt}.
           If you still want to re-register this candidate please be sure to call or mail ${props?.profileCardProps?.consultantName}  to inform. Good luck!`}
          closeTitle={t("Cancel")}
          confirmTitle={t("Proceed anyway")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
        />
      </div>
    </AccessControl>
  );
};

export default MatchingProfileCard;
