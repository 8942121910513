import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { useFormContext } from "components/CandidateCreation/context/Context";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import Calender from "components/common/molecules/Calender";
import Button from "components/common/atoms/Button";
import CloseFile from "static/images/CloseFile";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import PhoneInput from "react-phone-number-input";

import { useState } from "react";
import {
  validateForm,
  validateEmailCanBeNull,
  validateTextFieldAlphaCanBeNull,
  validatePhoneNumberCanBeNull,
  scrollToTop,
} from "components/common/services/ValidationService";
import Navigation from "../formNavigation/Navigation";
import {
  Option,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import LabelField from "components/common/atoms/LabelField";
import RadioField from "components/common/atoms/RadioField";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import LabelWithCKEditorField from "components/common/molecules/LabelWithCKEditorField";
import FileUpload from "components/common/molecules/FileUpload";
import CheckBoxField from "components/common/atoms/CheckBoxField";

const WorkExperienceTab: React.FC = () => {
  const { state, dispatch, isViewMode } = useFormContext();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined | number,
    index: number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      fName: [validateTextFieldAlphaCanBeNull],
      lName: [validateTextFieldAlphaCanBeNull],
      refEmail: [validateEmailCanBeNull],
      refPhone: [validatePhoneNumberCanBeNull],
    };
    const validationErrors = validateForm(
      { ...state.workExperience[index], [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: "UPDATE_WORK_FIELD_ERROR",
      field: name,
      error: validationErrors[name],
      index,
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      dispatch({
        type: "UPDATE_WORK_FIELD_ERROR",
        field: name,
        error: "",
        index,
      });
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const handleDateChange = (date: Date | null, name: string, index: number) => {
    const updatedDate = date !== null ? date : new Date();

    dispatch({
      type: "UPDATE_WORK_FIELD",
      field: name,
      value: updatedDate,
      index: index,
    });
    validation(name, updatedDate, index, true);
  };

  const resetFields = (index: number, fields: any) => {
    fields.map((object: any) => {
      dispatch({
        type: "UPDATE_WORK_FIELD",
        field: object.field,
        value: object.value,
        index,
      });
    });
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    if (isViewMode) return;

    const { name, value, type } = event.target;
    let updatedValue: string | number | null | Option;
    let updatedName: string;
    if (type === "radio" && name.startsWith("workExperience")) {
      updatedName = "workExperience";
      updatedValue = parseInt(value);
      if (updatedValue === 0) {
        const workFields = [
          { field: "title", value: "" },
          { field: "domain", value: null },
          { field: "function", value: null },
          { field: "employer", value: "" },
          { field: "description", value: "" },
          { field: "exp_from", value: null },
          { field: "exp_to", value: null },
          { field: "includeReasonInCv", value: 1 },
          { field: "reference", value: 0 },
          { field: "fName", value: "" },
          { field: "lName", value: "" },
          { field: "refEmail", value: "" },
          { field: "refPhone", value: "" },
          { field: "referenceInfo", value: "" },
          { field: "referenceTaken", value: "" },
          { field: "reasonForDeparture", value: null },
          { field: "reason", value: "" },
          { field: "referenceId", value: null },
          { field: "fileId", value: null },
          { field: "fileName", value: "" },
          { field: "filePath", value: "" },
        ];
        resetFields(index, workFields);
      }
    } else if (type === "radio" && name.startsWith("reference")) {
      updatedName = "reference";
      updatedValue = parseInt(value);
      if (updatedValue === 0) {
        const referenceFields = [
          { field: "fName", value: "" },
          { field: "lName", value: "" },
          { field: "refEmail", value: "" },
          { field: "refPhone", value: "" },
          { field: "referenceInfo", value: "" },
          { field: "referenceTaken", value: "" },
          { field: "referenceId", value: null },
          { field: "fileId", value: null },
          { field: "fileName", value: "" },
          { field: "filePath", value: "" },
        ];
        resetFields(index, referenceFields);
      }
    } else if (type === "checkbox" && event.target instanceof HTMLInputElement) {
      updatedName = name;
      updatedValue = event.target.checked ? 1 : 0;
    } else {
      updatedValue = value;
      updatedName = name;
    }
    dispatch({
      type: "UPDATE_WORK_FIELD",
      field: updatedName,
      value: updatedValue,
      index,
    });
    validation(updatedName, updatedValue, index, true);
  };

  const addFieldSet = () => {
    dispatch({ type: "ADD_WORK_FIELD" });
  };

  const removeFieldSet = (indexToRemove: number) => {
    dispatch({ type: "REMOVE_WORK_FIELD", indexToRemove });
  };

  const handleSelectChange = (
    selectedOption: any,
    name: string,
    index: number
  ) => {
    if (name === "domain") {
      // If "Job domain" is changed, clear the selected "Function"
      dispatch({
        type: "UPDATE_WORK_FIELD",
        field: "function",
        value: null, // Clear the selected function
        index,
      });
    }
    dispatch({
      type: "UPDATE_WORK_FIELD",
      field: name,
      value: selectedOption,
      index,
    });
    validation(name, selectedOption, index, true);
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop(400);
  };
  const handlePhoneNumberChange = (mobNumber: string, index: number) => {
    dispatch({
      type: "UPDATE_WORK_FIELD",
      field: "refPhone",
      value: mobNumber,
      index,
    });
    validation("refPhone", mobNumber, index, true);
  };

  const handleCKEditorChange = (
    event: any,
    editor: any,
    fieldName: string,
    index: number
  ) => {
    const content = editor.getData(); // Get the content from the editor
    const name = fieldName; // Use the fieldName parameter
    dispatch({
      type: "UPDATE_WORK_FIELD",
      field: fieldName,
      value: content,
      index,
    });
  };

  const fileUploadStyleObject: React.CSSProperties = {
    width: "100%",
  };

  const returnFileUploadErrors = (errorResposne: any, index: number) => {
    const step = errorResposne["step"];
    const error = errorResposne["error"];
    dispatch({
      type: "UPDATE_WORK_FIELD_ERROR",
      field: step,
      error: error,
      index,
    });
  };

  const fileUploadResponse = (responseObj: any, index: number) => {
    const step = responseObj["step"];
    const response = responseObj["response"];

    const fieldMappings: { [key: string]: string[] } = {
      fileId: ["fileId", "fileName", "filePath"],
    };

    const fieldNames = fieldMappings[step];

    if (fieldNames) {
      fieldNames.forEach((fieldName: string, key: number) => {
        dispatch({
          type: "UPDATE_WORK_FIELD",
          field: fieldName,
          value: response[key],
          index: index,
        });
      });
    }
  };

  const deleteResponse = (field: string, index: number) => {
    const fieldMappings: { [key: string]: string[] } = {
      fileId: ["fileId", "fileName", "filePath"],
    };

    const fieldNames = fieldMappings[field];

    if (fieldNames) {
      fieldNames.forEach((fieldName: string) => {
        dispatch({
          type: "UPDATE_WORK_FIELD",
          field: fieldName,
          value: fieldName.endsWith("Id") ? null : "",
          index,
        });
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className={`form-border ${isViewMode ? "form disableform" : "form"}`}
        style={{ paddingTop: "3.4vw" }}
      >
        <div className="row">
          <div className="col-12">
            {state.workExperience.map((workFields, index) => (
              <div
                key={index}
                className="field-set internalContentWrapper"
                style={{ marginBottom: "1vw" }}
              >
                <div className="row">
                  <div className={`col-sm-12 col-md-6 ${isViewMode ? "form disableform" : "form"}`}>
                    <LabelField
                      title={t("Work experience")}
                      isMandatory={false}
                    />
                    <br />
                    <RadioField
                      name={`workExperience-${index}`}
                      id={`workExperience-yes-${index}`}
                      value={1}
                      ischecked={workFields.workExperience === 1}
                      handleChange={(event) => {
                        handleFieldChange(event, index);
                      }}
                      label={t("Yes")}
                      className="marginRight1"
                    />
                    <RadioField
                      name={`workExperience-${index}`}
                      id={`workExperience-no-${index}`}
                      value={0}
                      ischecked={workFields.workExperience === 0}
                      handleChange={(event) => {
                        handleFieldChange(event, index);
                      }}
                      label={t("No")}
                    />
                  </div>
                  <div className="col-md-6">
                    {index > 0 && (
                      <span
                        title={t("Delete")}
                        onClick={() => removeFieldSet(index)}
                        className="table-action-icons cursor-pointer float-end rounded-0 shadow-none"
                      >
                        <CloseFile />
                      </span>
                    )}
                  </div>
                </div>
                {workFields.workExperience === 1 && (
                  <>
                    <div className="row" style={{ marginTop: "1vw" }}>
                      <div className="col-6 col-lg-4">
                        <SelectWithSearch
                          title={t("Job domain")}
                          isMandatory={false}
                          search={true}
                          options={state.dropDowndata.jobDomainList}
                          onChange={(e) =>
                            handleSelectChange(e, "domain", index)
                          }
                          isMulti={false}
                          name="domain"
                          value={workFields.domain}
                          error={state.workExperienceErrors[index]?.domain}
                          isTranslate={true}
                        />
                      </div>
                      <div className="col-6 col-lg-4">
                        <SelectWithSearch
                          title={t("Function")}
                          isMandatory={false}
                          search={true}
                          options={
                            workFields.domain && workFields.domain.value
                              ? (() => {
                                const domainId = workFields.domain.value;
                                const matchingDomainFunctions = (
                                  state.dropDowndata.domainFunctionLinking ??
                                  []
                                )
                                  .filter(
                                    (funcLink) =>
                                      funcLink.domain_id === domainId
                                  )
                                  .map((funcLink) => funcLink.function_id);
                                const matchingFunctions = (
                                  state.dropDowndata.functionList ?? []
                                )
                                  .filter((func) =>
                                    matchingDomainFunctions.includes(
                                      func.value
                                    )
                                  )
                                  .map((func) => ({
                                    value: func.value,
                                    label: func.label,
                                  }));
                                return matchingFunctions;
                              })()
                              : state.dropDowndata.functionList
                          }
                          onChange={(e) =>
                            handleSelectChange(e, "function", index)
                          }
                          isMulti={false}
                          name="function"
                          value={workFields.function}
                          error={state.workExperienceErrors[index]?.function}
                          isTranslate={true}
                        />
                      </div>
                      <div className="col-lg-4 col-6">
                        <LabelWithInputField
                          isMandatory={false}
                          name="title"
                          handleChange={(event) =>
                            handleFieldChange(event, index)
                          }
                          value={workFields.title}
                          id={`title-${index}`}
                          label={t("Job title")}
                          placeholder={t("Job title")}
                          type="text"
                        />
                      </div>
                      <div className="col-lg-4 col-6">
                        <LabelWithInputField
                          isMandatory={false}
                          name="employer"
                          handleChange={(event) =>
                            handleFieldChange(event, index)
                          }
                          value={workFields.employer}
                          id={`employer-${index}`}
                          label={t("Employer")}
                          placeholder={t("Employer")}
                          type="text"
                          error={state.workExperienceErrors[index]?.employer}
                        />
                      </div>
                      <div className="col-lg-2 col-6 position-relative">
                        <Calender
                          onChange={(date) =>
                            handleDateChange(date, "exp_from", index)
                          }
                          selectedDate={workFields.exp_from}
                          label={t("From")}
                          name="exp_from"
                          maxDate={workFields.exp_to}
                          isMandatory={false}
                          error={state.workExperienceErrors[
                            index
                          ]?.exp_from?.toString()}
                        />
                      </div>
                      <div className="col-lg-2 col-6 position-relative">
                        <Calender
                          onChange={(date) =>
                            handleDateChange(date, "exp_to", index)
                          }
                          selectedDate={workFields.exp_to}
                          label={t("To")}
                          name="exp_to"
                          isMandatory={false}
                          minDate={workFields.exp_from}
                          error={state.workExperienceErrors[
                            index
                          ]?.exp_to?.toString()}
                        />
                      </div>
                      <div className="col-lg-4 col-6">
                        <SelectWithSearch
                          title={t("Reason for departure")}
                          isMandatory={false}
                          search={true}
                          options={state.dropDowndata.reasonForDepartureList}
                          onChange={(e) =>
                            handleSelectChange(e, "reasonForDeparture", index)
                          }
                          isMulti={false}
                          name="reasonForDeparture"
                          value={workFields.reasonForDeparture}
                          isTranslate={true}
                        />
                      </div>
                    </div>
                    <div className="marginBotttom1">
                      <CheckBoxField
                        label={t("Include reason In CV")}
                        name="includeReasonInCv"
                        onChangeHandler={(event) =>
                          handleFieldChange(event, index)
                        }
                        isChecked={workFields.includeReasonInCv === 1}
                        lineHeight="1vw"
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <LabelWithTextAreaField
                          name="reason"
                          handleChange={(event) =>
                            handleFieldChange(event, index)
                          }
                          placeholder={t("Reason for leaving")}
                          label={t("Reason for leaving")}
                          value={workFields.reason}
                        />
                      </div>
                      <div className="col-md-6 ">
                        <LabelWithCKEditorField
                          label={t("Description")}
                          name="description"
                          value={workFields.description}
                          placeholder={t("Type here") + "..."}
                          handleChange={(event, editor) =>
                            handleCKEditorChange(
                              event,
                              editor,
                              "description",
                              index
                            )
                          }
                          className="field-shadow"
                          error={state.workExperienceErrors[index]?.description}
                          isDisabled={isViewMode}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-12  ${isViewMode ? "form disableform" : "form"}`}>
                        <LabelField
                          title={t("Reference")}
                          isMandatory={false}
                        />
                        <br />
                        <RadioField
                          name={`reference-${index}`}
                          id={`reference-yes-${index}`}
                          value={1}
                          ischecked={workFields.reference === 1}
                          handleChange={(event) => {
                            handleFieldChange(event, index);
                          }}
                          label={t("Yes")}
                          className="marginRight1"
                        />
                        <RadioField
                          name={`reference-${index}`}
                          id={`reference-no-${index}`}
                          value={0}
                          ischecked={workFields.reference === 0}
                          handleChange={(event) => {
                            handleFieldChange(event, index);
                          }}
                          label={t("No")}
                        />
                      </div>
                    </div>
                  </>
                )}
                {workFields.workExperience === 1 &&
                  workFields.reference === 1 && (
                    <>
                      <div className="row" style={{ marginTop: "1vw" }}>
                        <div className="col-lg-3 col-6">
                          <LabelWithInputField
                            isMandatory={false}
                            name="fName"
                            handleChange={(event) =>
                              handleFieldChange(event, index)
                            }
                            value={workFields.fName}
                            id={`firstName-${index}`}
                            label={t("First name")}
                            placeholder={t("First name")}
                            type="text"
                            error={state.workExperienceErrors[index]?.fName}
                          />
                        </div>
                        <div className="col-lg-3 col-6">
                          <LabelWithInputField
                            isMandatory={false}
                            name="lName"
                            handleChange={(event) =>
                              handleFieldChange(event, index)
                            }
                            value={workFields.lName}
                            id={`lastName-${index}`}
                            label={t("Last name")}
                            placeholder={t("Last name")}
                            type="text"
                            error={state.workExperienceErrors[index]?.lName}
                          />
                        </div>
                        <div className="col-lg-3 col-6">
                          <LabelWithInputField
                            isMandatory={false}
                            name="refEmail"
                            handleChange={(event) =>
                              handleFieldChange(event, index)
                            }
                            value={workFields.refEmail}
                            id={`refEmail-${index}`}
                            label={t("Email")}
                            placeholder={t("Email")}
                            type="email"
                            error={state.workExperienceErrors[index]?.refEmail}
                          />
                        </div>
                        <div className="col-lg-3 col-6">
                          <LabelField
                            title={t("Phone number")}
                            isMandatory={false}
                            key="PhoneInput"
                          />
                          <div
                            className="form-control field-shadow d-flex"
                            style={{ borderRadius: "0.5vw" }}
                          >
                            <PhoneInput
                              defaultCountry="BE" //Belgium country code
                              international
                              placeholder={t("Enter phone number")}
                              onChange={(mobNumber) =>
                                handlePhoneNumberChange(mobNumber!, index)
                              }
                              name="refPhone"
                              value={workFields.refPhone}
                              className="w-100"
                              readOnly={isViewMode}
                            />
                          </div>
                          <div
                            className="height-20"
                            style={{ marginBottom: "0.5vw" }}
                          >
                            {state.workExperienceErrors[index]?.refPhone && (
                              <span className="text-danger">
                                {state.workExperienceErrors[index]?.refPhone}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3 marginBotttom1">
                          <FileUpload
                            id="fileId"
                            name="fileId"
                            label={t("Upload reference document")}
                            isMandatory={false}
                            edit={false}
                            fileId={workFields.fileId ? workFields.fileId : null}
                            filePath={workFields.filePath ? workFields.filePath : ""}
                            fileName={workFields.fileName ? workFields.fileName : ""}
                            style={fileUploadStyleObject}
                            multiple={false}
                            uploadPath="candidate-certificate"
                            formats=".pdf,.docx,.doc,.jpg,.jpeg"
                            returnFileUploadErrors={(errorResponse) =>
                              returnFileUploadErrors(errorResponse, index)
                            }
                            fileUploadResponse={(uploadResposne) =>
                              fileUploadResponse(uploadResposne, index)
                            }
                            deleteResponse={(field) => deleteResponse(field, index)}
                            maxFileSize={10 * 1024 * 1024}
                            showDeleteIcon={!isViewMode}
                            isViewMode={isViewMode}
                          />
                          <span className="text-danger">
                            {state.workExperienceErrors[index]?.fileId}
                          </span>
                        </div>
                        <div className="col-sm-12 col-md-12">
                          <LabelWithTextAreaField
                            name="referenceInfo"
                            handleChange={(event) =>
                              handleFieldChange(event, index)
                            }
                            label={t("Reference info")}
                            value={workFields.referenceInfo}
                            id={`referenceInfo-${index}`}
                            isMandatory={false}
                            placeholder={t("Reference info")}
                          />
                        </div>

                      </div>
                    </>
                  )}
              </div>
            ))}
            <div className="text-end">
              <Button
                title={"+ " + t("Add another")}
                handleClick={addFieldSet}
                className="form-button shadow-none"
              />
            </div>

            <div className="disclaimerText">
              {t(
                "Any form of discrimination based on sex, perceived race, colour, descent, nationality or ethnic origin, sexual orientation, marital status, birth, property, age, religious or philosophical beliefs, current or future state of health, disability or physical characteristic is prohibited."
              )}
            </div>
          </div>
        </div>
      </div>

      <Navigation validStatus={validStatus} />
    </form>
  );
};
export default translate(WorkExperienceTab);
