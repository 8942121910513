import Title from "components/common/atoms/Title";
import OfficeTargetTable from "../common/OfficeTargetTable";
import { t } from "components/CentralDataMangement/translation/Translation";
import PersonalTargetTable from "../common/PersonalTargetTable";
import { useEffect, useState } from "react";
import { JobInterviewDetails, OfficeTarget, PersonalTarget, StarterDetails, StopperDetails, TargetAchievedTable } from "../Annotations";
import RecruiterTargetTable from "../common/RecruiterTargetTable";
import StartersTable from "../common/StartersTable";
import StoppersTable from "../common/StoppersTable";
import JobInterviewTable from "../common/JobInterviewTable";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import { ApiCall } from "components/common/services/ApiServices";
import { EDIT_MONTHLY_REPORTS, UPDATE_MONTHLY_REPORTS } from "../../../../routes/ApiEndpoints";
import { AREA_MANAGER, CENTRAL_DATA_MANAGEMENT_MICROSERVICE, OFFICE_MANAGER, RECRUITER } from "Constants";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import CustomNotify from "components/common/atoms/CustomNotify";
import EncryptDecryptService from "components/common/services/EncryptDecryptService";
import secureLocalStorage from "react-secure-storage";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/common/atoms/Button";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import ValidationDetails from "../common/ValidationDetails";

interface TargetData {
  officeTarget: OfficeTarget;
  personalTarget: PersonalTarget;
  recruiterTarget: TargetAchievedTable;
}

interface CandidatesData {
  starters: StarterDetails[];
  stoppers: StopperDetails[];
  jobInterviews: JobInterviewDetails[];
}

interface Analysis {
  id: string | number | null;
  period_id: number | null;
  analysis: string;
  recr_name: string;
  confirmed_at: string | null;
  smile_moments: string;
  growth_points: string;
  thoughts_comments: string;
  om_analysis: string;
  om_name: string;
  om_confirmed_at: string | null;
  am_analysis: string;
  am_name: string;
  am_confirmed_at: string | null;
}

const MonthlyReports: React.FC = () => {
  const [targetData, setTargetData] = useState<TargetData>();
  const [candidatesData, setCandidatesData] = useState<CandidatesData>({ starters: [], stoppers: [], jobInterviews: [] });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // const [periodId, setPeriodId] = useState<number>();
  const [analysisData, setAnalysisData] = useState<Analysis>({
    id: null,
    period_id: null,
    analysis: "",
    recr_name: "",
    confirmed_at: null,
    smile_moments: "",
    growth_points: "",
    thoughts_comments: "",
    om_analysis: "",
    om_name: "",
    om_confirmed_at: null,
    am_analysis: "",
    am_name: "",
    am_confirmed_at: null
  });
  const authUser = useSelector(selectAuth);
  let data = secureLocalStorage.getItem('monthlyReport') as string; // Get the 'data' query param as a string

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchData();
    }, 3000); // 3 seconds

    return () => clearTimeout(timeout); // Cleanup the timeout on component unmount
  }, []);

  const fetchData = async () => {
    let parsedData = {};
    try {
      if (data) {
        // Decrypt the recruiterData
        let decryptedData = EncryptDecryptService.decryptData(data);

        // If decryptedData is in JSON format, parse it
        parsedData = JSON.parse(decryptedData);
      }

    } catch (error) {
      CustomNotify({
        type: "error",
        message: t("Something went wrong"),
      });
      setLoading(false);
      return null;
    }

    const response = await ApiCall.service(
      EDIT_MONTHLY_REPORTS,
      "POST",
      { ...parsedData, type: "recruiter" },
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setTargetData(response?.data?.targets);
      setCandidatesData({
        starters: response?.data?.starters,
        stoppers: response?.data?.stoppers,
        jobInterviews: response?.data?.jobInterviews
      });
      if (response?.data?.analysis) {
        setAnalysisData(response?.data?.analysis);
      }
    }
    setLoading(false);
  }

  const handleAnalysisFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type, checked } = event.target as HTMLInputElement;

    setAnalysisData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent, isValidate: boolean = false) => {
    e.preventDefault();
    setLoading(true);

    let parsedData = {};
    try {
      if (data) {
        // Decrypt the recruiterData
        let decryptedData = EncryptDecryptService.decryptData(data);

        // If decryptedData is in JSON format, parse it
        parsedData = JSON.parse(decryptedData);
      }

    } catch (error) {
      CustomNotify({
        type: "error",
        message: t("Something went wrong"),
      });
      setLoading(false);
      return null;
    }

    const payload = {
      type: "recruiter",
      periodId: 0,
      analysis: analysisData,
      isValidate: isValidate,
      reportData: { ...parsedData, type: "recruiter" }
    };

    const url = UPDATE_MONTHLY_REPORTS;
    const response = await ApiCall.service(
      url,
      "POST",
      payload,
      false,
      "central-data-management"
    );
    if (response.status === 200) {
      CustomNotify({ type: "success", message: response.msg });
      navigate("/manage-reports");
    } else {
      CustomNotify({ type: "error", message: t("Something went wrong") })
    }
    setLoading(false);
  };

  const canValidate = () => {
    if (authUser.role === RECRUITER && !analysisData?.confirmed_at) {
      return true;
    } else if (authUser.role === OFFICE_MANAGER && analysisData?.confirmed_at && !analysisData?.om_confirmed_at) {
      return true;
    } else if (authUser.role === AREA_MANAGER && analysisData?.om_confirmed_at && !analysisData?.am_confirmed_at) {
      return true;
    }
    return false;
  }

  return (
    <>
      <Title title={t("Reporting (monthly recruiter)")} />
      {loading ? (
        <div style={{ height: "38vw" }} className="d-flex align-items-center justify-content-center">
          <LoadingIcon
            iconType="bars"
            color="#e55496"
            className="m-auto"
            height={"5vw"}
            width={"5vw"}
          />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-md-4">
              <OfficeTargetTable
                data={targetData?.officeTarget}
                tableTitle="OFFICE"
              />
            </div>
            <div className="col-md-8">
              <PersonalTargetTable
                data={targetData?.personalTarget}
                tableTitle={targetData?.personalTarget?.name ?? "-"}
              />
            </div>
            <div className="col-md-4 mt-4">
              <RecruiterTargetTable data={targetData?.recruiterTarget} />
            </div>
            <div className="col-md-8 mt-4">
              <StartersTable
                view="recruiter"
                data={candidatesData.starters}
                tableTitle="STARTERS"
              />
            </div>
            <div className="col-md-4">
              <StoppersTable
                view="recruiter"
                data={candidatesData.stoppers}
                tableTitle="STOPPER"
              />
            </div>
            <div className="col-md-8">
              <JobInterviewTable
                view="recruiter"
                data={candidatesData.jobInterviews}
                tableTitle="JOB INTERVIEWS"
              />
            </div>
          </div>
          <div className="row mt-3">
            <Title title={t("Analysis")} />
            {authUser.role === OFFICE_MANAGER && analysisData?.confirmed_at &&
              <ValidationDetails validatedBy={analysisData?.recr_name} validatedAt={analysisData?.confirmed_at} />
            }
            {authUser.role === AREA_MANAGER && analysisData?.om_confirmed_at &&
              <ValidationDetails validatedBy={analysisData?.om_name} validatedAt={analysisData?.om_confirmed_at} />
            }
            <div className="row mt-2">
              <div className="col-md-4">
                <LabelWithTextAreaField
                  name="analysis"
                  handleChange={handleAnalysisFieldChange}
                  label={t("Make an analysis of your month!")}
                  value={analysisData.analysis ?? ""}
                  isDisabled={authUser.role === RECRUITER ? !canValidate() : true}
                />
              </div>
              <div className="col-md-4">
                <LabelWithTextAreaField
                  name="smile_moments"
                  handleChange={handleAnalysisFieldChange}
                  label={t(`Share your "smile" moments with us!`)}
                  value={analysisData.smile_moments ?? ""}
                  isDisabled={authUser.role === RECRUITER ? !canValidate() : true}
                />
              </div>
              <div className="col-md-4">
                <LabelWithTextAreaField
                  name="growth_points"
                  handleChange={handleAnalysisFieldChange}
                  label={t("Share your growth points with us")}
                  value={analysisData.growth_points ?? ""}
                  isDisabled={authUser.role === RECRUITER ? !canValidate() : true}
                />
              </div>
              <div className="col-md-4">
                <LabelWithTextAreaField
                  name="thoughts_comments"
                  handleChange={handleAnalysisFieldChange}
                  label={t("Share your thoughts and comments with us.")}
                  value={analysisData.thoughts_comments ?? ""}
                  isDisabled={authUser.role === RECRUITER ? !canValidate() : true}
                />
              </div>
              <div className="col-md-4">
                <LabelWithTextAreaField
                  name="om_analysis"
                  handleChange={handleAnalysisFieldChange}
                  label={t("Besluit office manager")}
                  value={analysisData.om_analysis ?? ""}
                  isDisabled={authUser.role === OFFICE_MANAGER ? !canValidate() : true}
                />
              </div>
              <div className="col-md-4">
                <LabelWithTextAreaField
                  name="am_analysis"
                  handleChange={handleAnalysisFieldChange}
                  label={t("Besluit regio manager")}
                  value={analysisData.am_analysis ?? ""}
                  isDisabled={authUser.role === AREA_MANAGER ? !canValidate() : true}
                />
              </div>
            </div>
            <div className="row py-4">
              <div className="col-md-4 align-self-center">
                <Link
                  to="/manage-reports"
                  className=" back-btn text-decoration-underline"
                >
                  {t("Back")}
                </Link>
              </div>
              {/* <AccessControl
            requiredPermissions={[
              {
                permission: "Daily turnover",
                create: true,
                update: true,
              },
            ]}
            actions={true}
            strict={false}
          > */}
              <div className="col-md-8 d-flex justify-content-end align-items-end">
                {canValidate() ? (
                  !loading ? (
                    <>
                      <Button
                        title={t("Validate")}
                        type="submit"
                        className="form-button shadow-none me-2"
                        handleClick={(e) => handleSubmit(e, true)}
                      />
                      <Button
                        title={t("Save")}
                        type="submit"
                        className="form-button shadow-none"
                        handleClick={(e) => handleSubmit(e)}
                      />
                    </>
                  ) : (
                    <LoadingIcon
                      iconType="bars"
                      color="#ff4dae"
                      className="float-end"
                      width={"3vw"}
                      height={"3vw"}
                    />
                  )) : null
                }
              </div>
              {/* </AccessControl> */}
            </div>
          </div>
        </>
      )}
    </>

  );
};

export default MonthlyReports;
