import { useState } from "react";
import LabelField from "components/common/atoms/LabelField";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { useFormContext } from "components/CandidateCreation/context/Context";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import FileUpload from "components/common/molecules/FileUpload";
import Calender from "components/common/molecules/Calender";
import Button from "components/common/atoms/Button";
import CloseFile from "static/images/CloseFile";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import RadioField from "components/common/atoms/RadioField";
import Navigation from "../formNavigation/Navigation";
import {
  validateForm,
  validateFileId,
  scrollToTop,
} from "components/common/services/ValidationService";
import {
  Option,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Accordion from "react-bootstrap/Accordion";
import {
  Competence,
  Options,
  ChildCompetence,
} from "components/CandidateCreation/annotations/CandidateAnnotations";
import HelpInfoIcon from "static/images/HelpInfo";
import CustomRangeSlider from "../../../common/atoms/RangeSlider";

const StudiesTab: React.FC = () => {
  const { state, dispatch, isViewMode } = useFormContext();

  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    index: number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      certificateId: [validateFileId],
    };
    const validationErrors = validateForm(
      { ...state.studies[index], [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: "UPDATE_STUDY_FIELD_ERROR",
      field: name,
      error: validationErrors[name],
      index,
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      dispatch({
        type: "UPDATE_STUDY_FIELD_ERROR",
        field: name,
        error: "",
        index,
      });
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const fileUploadStyleObject: React.CSSProperties = {
    width: "100%",
  };

  const handleDateChange = (date: Date | null, name: string, index: number) => {
    const updatedDate = date !== null ? date : new Date();

    dispatch({
      type: "UPDATE_STUDY_FIELD",
      field: name,
      value: updatedDate,
      index: index,
    });

    validation(name, updatedDate, index, true);
  };

  const returnFileUploadErrors = (errorResposne: any, index: number) => {
    const step = errorResposne["step"];
    const error = errorResposne["error"];
    dispatch({
      type: "UPDATE_STUDY_FIELD_ERROR",
      field: step,
      error: error,
      index,
    });
  };

  const fileUploadResponse = (responseObj: any, index: number) => {
    const step = responseObj["step"];
    const response = responseObj["response"];

    const fieldMappings: { [key: string]: string[] } = {
      certificateId: ["certificateId", "fileName", "filePath"],
    };

    const fieldNames = fieldMappings[step];

    if (fieldNames) {
      fieldNames.forEach((fieldName: string, key: number) => {
        dispatch({
          type: "UPDATE_STUDY_FIELD",
          field: fieldName,
          value: response[key],
          index: index,
        });
      });
    }
  };

  const deleteResponse = (field: string, index: number) => {
    const fieldMappings: { [key: string]: string[] } = {
      certificateId: ["certificateId", "fileName", "filePath"],
    };

    const fieldNames = fieldMappings[field];

    if (fieldNames) {
      fieldNames.forEach((fieldName: string) => {
        dispatch({
          type: "UPDATE_STUDY_FIELD",
          field: fieldName,
          value: fieldName.endsWith("Id") ? null : "",
          index,
        });
      });
    }
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    if (isViewMode) return;

    const { name, value, type } = event.target;
    let updatedValue: string | number | null | Option;
    let updatedName: string;
    if (type === "radio") {
      updatedName = "completed";
      updatedValue = parseInt(value);
    } else {
      updatedValue = value;
      updatedName = name;
    }
    dispatch({
      type: "UPDATE_STUDY_FIELD",
      field: updatedName,
      value: updatedValue,
      index,
    });
    validation(updatedName, value, index, true);
  };

  const addFieldSet = () => {
    dispatch({ type: "ADD_STUDY_FIELD" });
  };

  const removeFieldSet = (indexToRemove: number) => {
    dispatch({ type: "REMOVE_STUDY_FIELD", indexToRemove });
  };

  const handleSelectChange = (
    selectedOption: any,
    name: string,
    index: number
  ) => {
    dispatch({
      type: "UPDATE_STUDY_FIELD",
      field: name,
      value: selectedOption,
      index,
    });
    validation(name, selectedOption, index, true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop(400);
  };

  const handleChildCompChange = (
    competenceIndex: number,
    selectedChildCompetences: Options[],
    competenceName: string
  ) => {
    const updatedCompetences: Competence[] = state.competences?.competence?.map(
      (competence, cIndex) => {
        if (cIndex === competenceIndex) {
          const updatedChildCompetences: ChildCompetence[] = [];
          selectedChildCompetences.forEach((selectedComp) => {
            const existingChildComp = competence?.child_competences.find(
              (childComp) =>
                childComp.name === selectedComp.value &&
                childComp.candidate_competence_details?.rating !== -1
            );
            if (existingChildComp) {
              if (
                existingChildComp.candidate_competence_details &&
                existingChildComp.candidate_competence_details?.rating === -1
              ) {
                existingChildComp.candidate_competence_details.rating = 0;
              }
              updatedChildCompetences.push(existingChildComp);
            } else {
              updatedChildCompetences.push({
                name: selectedComp.value,
                candidate_competence_details: {
                  name: "",
                  key: "",
                  rating: 1,
                },
              });
            }
          });

          return {
            ...competence,
            child_competences: updatedChildCompetences,
          };
        }
        return competence;
      }
    );

    dispatch({
      type: "UPDATE_CHILD_COMPETENCES",
      field: "competence",
      value: updatedCompetences,
    });
  };

  const handleInputChange = (
    competenceIndex: number,
    subIndex: number,
    fieldName: string,
    value: any
  ) => {
    if (isViewMode) return;

    const updatedCompetences =
      state.competences?.competence &&
      state.competences?.competence.map((competence, cIndex) => {
        if (cIndex === competenceIndex) {
          if (fieldName === "additional_info") {
            return {
              ...competence,
              [fieldName]: value,
            };
          }

          const updatedChildCompetences = competence.child_competences.map(
            (childCompetences, sIndex) => {
              if (sIndex === subIndex) {
                const updatedCanCompDetails = {
                  ...childCompetences.candidate_competence_details,
                  [fieldName]: value,
                };
                return {
                  ...childCompetences,
                  candidate_competence_details: updatedCanCompDetails,
                };
              }
              return childCompetences;
            }
          );

          return {
            ...competence,
            child_competences: updatedChildCompetences,
          };
        }
        return competence;
      });
    dispatch({
      type: "UPDATE_CHILD_COMPETENCES",
      field: "competence",
      value: updatedCompetences,
    });
  };

  const generateTooltipTitle = (
    competence_levels: { name: string; level: number }[]
  ) => {
    return competence_levels
      .map((level) => `${level.level}. ${t(level.name)}`)
      .join("\n");
  };

  const title = `A. ${t("Basic user")} (A1/A2: ${t("beginner")})\nB. ${t(
    "Independent user"
  )} (B1/B2: ${t("advanced")})\nC. ${t("Skilled user")} (C1/C2: ${t(
    "advanced"
  )})`;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-border" style={{ paddingTop: "3.4vw" }}>
        <div className="row">
          <div className="col-12">
            {state.studies.map((study, index) => (
              <div
                key={index}
                className="field-set internalContentWrapper"
                style={{ marginBottom: "1vw" }}
              >
                {index > 0 && (
                  <div className="row">
                    <div
                      className={`col-md-12 ${isViewMode ? "form disableform" : "form"
                        }`}
                    >
                      <span
                        title={t("Delete")}
                        onClick={() => removeFieldSet(index)}
                        className="table-action-icons cursor-pointer float-end rounded-0 shadow-none"
                      >
                        <CloseFile />
                      </span>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div
                    className={`col-lg-4 ${isViewMode ? "form disableform" : "form"
                      }`}
                  >
                    <SelectWithSearch
                      title={t("Diploma")}
                      isMandatory={false}
                      search={true}
                      options={state.dropDowndata.diplomaList}
                      id={`diploma-${index}`}
                      onChange={(e) => handleSelectChange(e, "diploma", index)}
                      isMulti={false}
                      name="diploma"
                      value={study.diploma}
                      error={state.studiesErrors[index]?.diploma}
                      isTranslate={true}
                    />
                  </div>
                  <div
                    className={`col-lg-4 ${isViewMode ? "form disableform" : "form"
                      }`}
                  >
                    <LabelWithInputField
                      isMandatory={false}
                      name="study"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={study.study}
                      id={`study-${index}`}
                      label={t("Field of study")}
                      placeholder={t("Field of study")}
                      type="text"
                      error={state.studiesErrors[index]?.study}
                    />
                  </div>
                  <div
                    className={`col-lg-4 ${isViewMode ? "form disableform" : "form"
                      }`}
                  >
                    <LabelWithInputField
                      isMandatory={false}
                      name="school"
                      handleChange={(event) => handleFieldChange(event, index)}
                      value={study.school}
                      id={`school-${index}`}
                      label={t("School")}
                      placeholder={t("School")}
                      type="text"
                      error={state.studiesErrors[index]?.school}
                    />
                  </div>
                  <div
                    className={`col-lg-2 position-relative ${isViewMode ? "form disableform" : "form"
                      }`}
                  >
                    <Calender
                      onChange={(date) => handleDateChange(date, "from", index)}
                      selectedDate={study.from}
                      label={t("From")}
                      name="from"
                      maxDate={study.to}
                      isMandatory={false}
                      error={state.studiesErrors[index]?.from?.toString()}
                    />
                  </div>
                  <div
                    className={`col-lg-2 position-relative ${isViewMode ? "form disableform" : "form"
                      }`}
                  >
                    <Calender
                      onChange={(date) => handleDateChange(date, "to", index)}
                      selectedDate={study.to}
                      label={t("To")}
                      name="to"
                      isMandatory={false}
                      error={state.studiesErrors[index]?.to?.toString()}
                      minDate={study.from}
                    />
                  </div>
                  <div
                    className={`col-sm-12 col-md-4 ${isViewMode ? "form disableform" : "form"
                      }`}
                  >
                    <LabelField title={t("Completed")} />
                    <div style={{ marginTop: "0.45vw" }}>
                      <RadioField
                        name={`completed-${index}`}
                        id={`completed-yes-${index}`}
                        value={1}
                        ischecked={study.completed === 1}
                        handleChange={(event) => {
                          handleFieldChange(event, index);
                        }}
                        label={t("Yes")}
                        className="marginRight1"
                      />
                      <RadioField
                        name={`completed-${index}`}
                        id={`completed-no-${index}`}
                        value={0}
                        ischecked={study.completed === 0}
                        handleChange={(event) => {
                          handleFieldChange(event, index);
                        }}
                        label={t("No")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <FileUpload
                      id="certificateId"
                      name="certificateId"
                      label={t("Upload certificate")}
                      isMandatory={false}
                      edit={false}
                      fileId={study.certificateId ? study.certificateId : null}
                      filePath={study.filePath ? study.filePath : ""}
                      fileName={study.fileName ? study.fileName : ""}
                      style={fileUploadStyleObject}
                      multiple={false}
                      uploadPath="candidate-certificate"
                      formats=".pdf,.docx,.doc,.jpg,.jpeg"
                      returnFileUploadErrors={(errorResponse) =>
                        returnFileUploadErrors(errorResponse, index)
                      }
                      fileUploadResponse={(uploadResposne) =>
                        fileUploadResponse(uploadResposne, index)
                      }
                      deleteResponse={(field) => deleteResponse(field, index)}
                      maxFileSize={10 * 1024 * 1024}
                      showDeleteIcon={!isViewMode}
                      isViewMode={isViewMode}
                    />
                    <span className="text-danger">
                      {state.studiesErrors[index]?.certificateId}
                    </span>
                  </div>
                  <div
                    className={`col-md-12 ${isViewMode ? "form disableform" : "form"
                      }`}
                  >
                    <LabelWithTextAreaField
                      name="info"
                      handleChange={(event) => handleFieldChange(event, index)}
                      label={t("Info")}
                      value={study.info}
                    />
                  </div>
                </div>
              </div>
            ))}
            <Button
              title={"+ " + t("Add another")}
              handleClick={addFieldSet}
              className={`form-button float-end shadow-none ${isViewMode ? "form disableform" : "form"
                }`}
            />
          </div>
          <div
            className={`field-set ${isViewMode ? "form disableform" : "form"}`}
            style={{ paddingTop: "1vw" }}
          >
            {state.competences?.competence &&
              state.competences?.childCompetences &&
              state.competences?.competence?.map(
                (competence, competenceIndex) => (
                  <Accordion
                    defaultActiveKey="0"
                    activeKey={isViewMode ? String(competenceIndex) : undefined} // Set activeKey for each Accordion
                    key={competence.competence_name}
                    className="compentencesCandidate"
                  >
                    <Accordion.Item eventKey={String(competenceIndex)}>
                      <Accordion.Header>
                        {competence.competence_name === "Soft skills"
                          ? competence.competence_name
                          : t(competence.competence_name)}
                      </Accordion.Header>
                      <Accordion.Body className="compentencesCandidateBody">
                        <div className="">
                          <SelectWithSearch
                            name="Select"
                            className="mt-1"
                            placeHolder="Select"
                            search={true}
                            isMulti={true}
                            options={(
                              state.competences?.childCompetences[
                              competence.competence_name
                              ] || []
                            ).map((filteredChildComp) => ({
                              label: filteredChildComp.name,
                              value: filteredChildComp.name.toString(),
                            }))}
                            onChange={(selected: Options[]) =>
                              handleChildCompChange(
                                competenceIndex,
                                selected,
                                competence?.competence_name
                              )
                            }
                            value={competence?.child_competences
                              .filter(
                                (childComp) =>
                                  childComp.candidate_competence_details
                                    .rating !== -1
                              )
                              .map((filteredChildComp) => ({
                                label: filteredChildComp.name,
                                value: filteredChildComp.name.toString(),
                              }))}
                            isTranslate={true}
                          />
                        </div>
                        {competence?.child_competences.filter(
                          (childComp) =>
                            childComp.candidate_competence_details.rating !== -1
                        ).length > 0 && (
                          <div className="border mb-4">
                            <div className="col-md-12 p-3 text-end">
                              <div
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title={
                                  competence.competence_name === "Languages"
                                    ? title
                                    : generateTooltipTitle(
                                        competence.competence_levels
                                      )
                                }
                              >
                                <HelpInfoIcon />
                              </div>
                            </div>
                            <div className="row row-cols-1 row-cols-xxl-2 px-4">
                              {competence?.child_competences
                                .filter(
                                  (childComp) =>
                                    childComp.candidate_competence_details
                                      .rating !== -1
                                )
                                .map((eachCompetance, subIndex) => (
                                  <div
                                    className="col"
                                    key={eachCompetance.name}
                                  >
                                    <div className="row">
                                      <div className="col-md-4 align-self-center mb-3 mb-md-0">
                                        <h6 className="m-0 compentencesName">
                                          {t(`${eachCompetance.name}`)}
                                        </h6>
                                      </div>

                                      <div className="col-md-4 align-self-center range-wrap">
                                        <CustomRangeSlider
                                          min={1}
                                          max={
                                            competence.competence_levels.length
                                          } // Adjusted max value to accommodate the range
                                          step={1} // Step size should match the range
                                          value={
                                            eachCompetance
                                              .candidate_competence_details
                                              .rating ?? 0
                                          }
                                          handleChange={(e) =>
                                            handleInputChange(
                                              competenceIndex,
                                              subIndex,
                                              "rating",
                                              e.target.value
                                            )
                                          }
                                        />
                                        <div className="d-flex justify-content-between marginBottomPoint5">
                                          {competence?.competence_levels.map(
                                            (level) => (
                                              <span key={level.id}>
                                                {level?.level}
                                              </span>
                                            )
                                          )}
                                        </div>

                                        <output className="bubble">
                                          {
                                            competence?.competence_levels[
                                              parseInt(
                                                eachCompetance.candidate_competence_details.rating.toString()
                                              ) - 1
                                            ]?.name
                                          }
                                        </output>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                        <div style={{ marginTop: "1vw" }}>
                          <h5
                            className="field-label"
                            style={{ marginBottom: "0.5vw" }}
                          >
                            {t("Additional info")}
                          </h5>
                          <textarea
                            name="additional_info"
                            className="form-control field-shadow"
                            onChange={(e) =>
                              handleInputChange(
                                competenceIndex,
                                -1,
                                "additional_info",
                                e.target.value
                              )
                            }
                            value={competence.additional_info ?? ""}
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )
              )}
            <div className="disclaimerText">
              {t(
                "Any form of discrimination based on sex, perceived race, colour, descent, nationality or ethnic origin, sexual orientation, marital status, birth, property, age, religious or philosophical beliefs, current or future state of health, disability or physical characteristic is prohibited."
              )}
            </div>
          </div>
        </div>
      </div>
      <Navigation validStatus={validStatus} />
    </form>
  );
};
export default translate(StudiesTab);
