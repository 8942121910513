import React, { useState } from "react";
import Navigation from "../form-navigation/Navigation";
import { useFormContext } from "../../context/Context";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import { t } from "components/CentralDataMangement/translation/Translation";
import {
  COST_CENTER_DETAILS,
  UPDATE_FIELDS,
} from "../../annotation/PlanningConstants";
import CostCenter from "./CostCenter";

const CostCenterTab: React.FC = () => {
  const { state, dispatch } = useFormContext();

  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = event.target;
    let updatedValue: string | null | number = value;
    if (type === "checkbox") {
      updatedValue = event.target.checked ? 1 : 0;
    }
    dispatch({
      type: UPDATE_FIELDS,
      tab: COST_CENTER_DETAILS,
      field: name,
      value: updatedValue,
    });
  };

  return (
    <>
      <div style={{ minHeight: "calc(100vh - 19vw)" }}>
        <div className="form-border" style={{ paddingTop: "3.4vw" }}>
          <div className="row">
            <div className="col-12 my-3">
              <CheckBoxField
                label={t("Do not take over cost centers when renewing")}
                name="costCenterRenew"
                onChangeHandler={handleFieldChange}
                isChecked={state.costCenterDetails.costCenterRenew === 1}
                lineHeight="1vw"
                id="costCenterRenew"
              />
            </div>
          </div>
          <div className="row">
            <CostCenter />
          </div>
        </div>
      </div>
      <Navigation validStatus={validStatus} />
    </>
  );
};

export default CostCenterTab;
