import { useEffect, useState } from "react";
import { useFormContext } from "components/CandidateCreation/context/Context";
import GeneralTab from "../formContent/GeneralTab";
import StudiesTab from "./../formContent/StudiesTab";
import WorkExperienceTab from "./../formContent/WorkExperienceTab";
import JobProfileTab from "./../formContent/JobProfileTab";
import EmployeeDocumentsTab from "./../formContent/EmployeeDocumentsTab";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ApiCall } from "components/common/services/ApiServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import "../css/Candidate.css";
import CandidateProfileCard from "./../formContent/CandidateProfileCard";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import InternalInfoTab from "./../formContent/InternalInfoTab";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { useTabUrlUpdater } from "components/common/utlis/TabUtility";
import { SetCandidateData } from "./SetCandidateData";
import { Alert } from "react-bootstrap";
import ButtonWithChildren from "components/common/atoms/ButtonWithChildren";
import FollowUpIcon from "static/images/FollowUpIcon";
import RightSideBar from "components/common/atoms/RightSideBar";
import ActionButtons from "components/common/utlis/ActionButtons";
import { actionButtons } from "../formContent/CandidateActionButtons";

const CandidateForm = () => {
  const { state, dispatch, isViewMode } = useFormContext();
  const { candidateId } = useParams<{ candidateId: string }>();
  const location = useLocation();
  const type = location.state?.origin === "manageUsers" ? "user" : "candidate";
  const [isSticky, setIsSticky] = useState(false);
  const userAuth = useSelector(selectAuth);
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const getCandidateDetails = async () => {
      // Check if there is a companyId in the URL
      const urlParams = new URLSearchParams(window.location.search);
      const applicationId = urlParams.get("applicationId");

      const editDetails = {
        type: type,
        userId: userAuth.userId,
        applicationId: applicationId ? applicationId : null,
      };

      const url = !candidateId
        ? `getCandidateDetails`
        : `getCandidateDetails/${candidateId}`;

      const response = await ApiCall.service(
        url,
        "POST",
        editDetails,
        true,
        "candidate-creation"
      );
      if (response.status === 200) {
        const id = candidateId ? candidateId : null;
        SetCandidateData(response.data, dispatch, id, state, applicationId);
        if (id && type === "user" && isViewMode) {
          navigate(
            `/candidate/view/${response.data.id}?tabId=general&destination=/manage-users`
          );
          window.location.reload();
        }
      }
    };

    getCandidateDetails();
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const ipadThreshold = 500; // Adjust this value based on testing

      const isIpad = /iPad|Macintosh/.test(navigator.userAgent); // For iPads, consider a different scroll position threshold

      if (isIpad && scrollPosition > ipadThreshold) {
        setIsSticky(true);
      } else if (!isIpad && scrollPosition > 275) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [candidateId, dispatch]);

  useTabUrlUpdater(state.tabDetails, dispatch); //To add tabId to url and stay on current tab on refresh

  const handleTabClick = (tabId: string) => {
    const currentTab = state.tabDetails.findIndex(
      (tab: any) => tabId === tab.id
    );
    state.tabDetails[currentTab].error = false;
    const updatedTabDetails = state.tabDetails.map((tab) => ({
      ...tab,
      showStatus: tab.id === tabId,
    }));

    dispatch({ type: "UPDATE_TAB_DETAILS", tabDetails: updatedTabDetails });
  };

  const ConstructTabs = (id: string, showStatus: boolean) => {
    switch (id) {
      case "general":
        return <GeneralTab />;
      case "studies":
        return <StudiesTab />;
      case "workExperience":
        return <WorkExperienceTab />;
      case "jobProfile":
        return <JobProfileTab />;
      case "employeeDocs":
        return <EmployeeDocumentsTab />;
      case "internalInfo":
        return <InternalInfoTab />;
      default:
        break;
    }
  };

  const permissionType = candidateId ? "update" : "create";
  const permissionObject: any = {
    permission: "Candidate",
  };
  permissionObject[permissionType] = true;

  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <div className="row header-sticky position-sticky">
        <div className="col-6">
          <Title title={t("Candidate")} />
        </div>
        {candidateId &&
          <div className="col-6 headerIcons text-end">
            <div className="d-inline-flex align-items-center">
              <ActionButtons buttons={actionButtons} filters={[]} />
              <ButtonWithChildren
                onClick={() => {
                  setIsSidebarOpen(!isSidebarOpen);
                }}
                className="d-inline-block form-manage-button headerBtn marginRightPoint5"
              >
                <FollowUpIcon />
                <span style={{ marginLeft: "0.3vw" }}>{t("Follow up")}</span>
              </ButtonWithChildren>
            </div>
          </div>
        }
      </div>

      <div className="row pt-4" style={{ paddingBottom: "5vw" }}>
        <div className="col-md-12">
          <CandidateProfileCard />
        </div>
      </div>
      {(state.general.firstName === "" ||
        state.general.lastName === "" ||
        state.general.email === "" ||
        state.general.mobNumber === "") &&
        state.tabDetails[0].draft === true &&
        state.general.formStatus !== 1 && (
          <div className="row">
            <div className="col-12" style={{ marginBottom: "1.5vw" }}>
              <Alert
                variant="danger"
                onClose={() => setShow(false)}
                dismissible
              >
                <div style={{ width: "90%" }}>
                  <span className="marginRightPoint5">
                    <FontAwesomeIcon icon={faCircleExclamation} />
                  </span>
                  {t("Please fill in the minimum required fields")}:{" "}
                  <strong>
                    {t("First name, Last name, Email, Phone number")}{" "}
                  </strong>
                  {t("to save as a draft.")}
                </div>
              </Alert>
            </div>
          </div>
        )}
      <div className="position-relative">
        <nav
          className={`candidate-step-tabs createFormTabs ${isSticky
            ? "createTabsSticky position-sticky"
            : "candidate-step-tabs-absolute position-absolute w-100"
            }`}
        >
          <div
            className="nav nav-tabs border-0 col-xxl-11 m-auto col-xl-12 col-lg-12 px-5"
            id="nav-tab"
            role="tablist"
          >
            {state.tabDetails.map((item: any, index) => (
              <button
                key={item.id}
                className={`nav-link shadow-none text-break ${item.error ? "error" : ""
                  } ${item.showStatus ? "active" : "inactive"}`}
                id={`${item.id}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#${item.id}`}
                type="button"
                role="tab"
                aria-controls={`nav-${item.id}`}
                aria-selected={item.showStatus}
                onClick={() => handleTabClick(item.id)}
                style={{
                  borderRadius: "0.5vw",
                  marginRight: "0.5vw",
                  padding: "0.5vw",
                }}
              >
                <div className="candidate-step-number d-flex align-items-center justify-content-center">
                  {t(`${item.title}`)}
                  {item.error && (
                    <span
                      className="exclamation-icon ms-2"
                      title={t("Please fill all the mandatory fields")}
                      data-toggle="tooltip"
                    >
                      <FontAwesomeIcon icon={faTriangleExclamation} />
                    </span>
                  )}
                </div>
              </button>
            ))}
          </div>
        </nav>
        <div className="tab-content py-0" id="nav-tabContent">
          {state.tabDetails.map((item: any) => (
            <div
              key={item.id}
              className={`tab-pane fade ${item.showStatus ? "show" : ""} ${item.showStatus ? "active" : ""
                }`}
              id={item.id}
              role="tabpanel"
              aria-labelledby={`${item.id}-tab`}
            >
              <div className="row">
                <div className="col-12">
                  {ConstructTabs(item.id, item.showStatus)}
                </div>
              </div>
            </div>
          ))}
        </div>
        {isSidebarOpen && (
          <RightSideBar
            title={"Follow-ups"}
            entityType={"candidate"}
            entityId={candidateId}
            isInBright={state.general.formStatus === 1}
            onClose={() => setIsSidebarOpen(false)}
            recordsLimit={10}
            className="right-sidebar p-3 scrollBarDesign companyRightSideBar"
            titleClassName="mt-4"
          />
        )}
      </div>
    </AccessControl>
  );
};

export default translate(CandidateForm);
