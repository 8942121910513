import React, { useState } from "react";
import Button from "components/common/atoms/Button";
import { scrollToTop } from "components/common/services/ValidationService";
import { Link, useNavigate, useParams } from "react-router-dom";
import { t } from "components/CentralDataMangement/translation/Translation";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import { useFormContext } from "../../context/Context";
import * as ADMINCONST from "../../annotations/AdminFicheConstants";
import * as CONST from "Constants";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
import { STORE_ADMIN_FICHE } from "routes/ApiEndpoints";
import { getNextTabId } from "components/common/utlis/TabUtility";
import ValidateData from "./ValidateData";
import { MANAGE_COOPERATION_AGREEMENT } from "routes/RouteConstants";
import ValidateWageTab from "./ValidateWageTab";

export interface MandatoryFields {
  [key: string]: string[];
}

const Navigation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useFormContext();
  const { id } = useParams<{ id: string }>();
  const { agreementId } = useParams<{ agreementId: string }>();
  const currentTab = state.tabDetails.find((tab) => tab.showStatus);
  const isGeneralTab = currentTab && currentTab.id === ADMINCONST.GENERAL;
  const isWageTab = currentTab && currentTab.id === ADMINCONST.WAGE;

  const handleNextStep = () => {
    dispatch({ type: ADMINCONST.NEXT_STEP });
    handleSubmitFormData(ADMINCONST.NEXT);
  };

  const handleSubmitFormData = async (type: string) => {
    if (type === CONST.SUBMIT) {
      setLoading(true);
    }
    let tabId = getNextTabId(state.tabDetails);

    const updatedWageAndPremiums = ValidateWageTab({
      state,
    });
    const data = {
      general: { ...state.general },
      contacts: { ...state.contacts },
      wageAndPremiums: updatedWageAndPremiums,
      shiftTables: { ...state.shiftTables },
      agreementId,
      adminFicheId: id,
      type: type,
    };

    const loadParams = isWageTab ? true : false;

    const response = await ApiCall.service(
      STORE_ADMIN_FICHE,
      "POST",
      data,
      loadParams,
      CONST.COMPANY_MICROSERVICE
    );
    if (response.status === 200) {
      if (type === CONST.DRAFT) {
        CustomNotify({
          type: CONST.SUCCESS,
          message: t(CONST.DRAFT_SUCCESS_MSG),
        });
        navigate(MANAGE_COOPERATION_AGREEMENT);
      } else if (type === CONST.SUBMIT) {
        CustomNotify({
          type: CONST.SUCCESS,
          message: t("Admin fiche saved successfully"),
        });
        navigate(
          `/admin-fiche/preview/${agreementId}/${response.data.adminFicheId}?companyId=${state.general.companyId}&coeff_type=${state.general.coeffType}`
        );
      } else {
        if (response?.data?.tableIds?.length > 0) {
          response.data.tableIds.forEach((tableId: number, index: number) => {
            dispatch({
              type: ADMINCONST.UPDATE_SHIFT_DATA,
              field: "tableId",
              value: tableId,
              tableId: index,
            });
          });
        }
        if (
          (state.general.encodage === 0 || state.general.encodage === null) &&
          tabId === "contacts"
        ) {
          tabId = "shiftTables";
        }
        navigate(
          `/admin-fiche/create/${agreementId}/${response.data.adminFicheId}?tabId=${tabId}`
        );
      }
    } else {
      CustomNotify({
        type: CONST.ERROR,
        message: t(CONST.SOME_ERROR_OCCURRED),
      });
    }
    setLoading(false);
  };

  const handlePreviousStep = () => {
    dispatch({ type: ADMINCONST.PREVIOUS_STEP });
  };

  const handleDraft = () => {
    const update = state.tabDetails.map((tab: any, index: any) => ({
      ...tab,
      draft: true,
    }));

    dispatch({ type: ADMINCONST.UPDATE_TAB_DETAILS, tabDetails: update });
    const valid = ValidateData({
      state: state,
      dispatch: dispatch,
      submitType: CONST.DRAFT,
    });
    if (!valid) {
      scrollToTop();
    } else {
      handleSubmitFormData(CONST.DRAFT);
    }
  };

  const handleSubmit = () => {
    const update = state.tabDetails.map((tab, index) => ({
      ...tab,
      draft: false,
    }));
    dispatch({ type: ADMINCONST.UPDATE_TAB_DETAILS, tabDetails: update });
    const wageError = Object.values(state.wageAndPremiums || {}).length === 0;
    const valid = ValidateData({
      state: state,
      dispatch: dispatch,
      submitType: CONST.SUBMIT,
    });
    if (!valid || wageError) {
      if (wageError) {
        CustomNotify({
          type: "error",
          message: t("Please select atleast one pc"),
        });
        dispatch({
          type: ADMINCONST.UPDATE_TAB_ERROR,
          tabIndex: 3,
          error: wageError,
        });
      }
      scrollToTop();
    } else {
      handleSubmitFormData(CONST.SUBMIT);
    }
  };

  return (
    <div className="row backPadding">
      <div className="col-md-6 align-self-center">
        {isGeneralTab && (
          <Link
            to=""
            className="back-btn text-decoration-underline"
            onClick={() => navigate(-1)}
          >
            {t("Back")}
          </Link>
        )}
        {!isGeneralTab && (
          <Button
            type="button"
            title={t("Back")}
            handleClick={handlePreviousStep}
            className="btn back-btn p-0 text-decoration-underline border-0"
            titleClassName="back-btn"
          />
        )}
      </div>
      <div className="col-md-6 ">
        {!loading ? (
          <Button
            type="submit"
            title={isWageTab ? t("Submit") : t("Next")}
            handleClick={isWageTab ? handleSubmit : handleNextStep}
            className="float-end form-button shadow-none"
          />
        ) : (
          <LoadingIcon
            iconType="bars"
            color="#e5007d"
            className="ms-auto mb-3"
            height={"3vw"}
            width={"3vw"}
          />
        )}
        {!isWageTab && state.general.status === null && (
          <Button
            type="submit"
            title={t("Save as draft")}
            handleClick={handleDraft}
            className="float-end form-button shadow-none marginRightPoint5"
          />
        )}
      </div>
    </div>
  );
};

export default Navigation;
