import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { useFormContext } from "components/CandidateCreation/context/Context";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import FileUpload from "components/common/molecules/FileUpload";
import Calender from "components/common/molecules/Calender";
import Button from "components/common/atoms/Button";
import { useState } from "react";
import {
  validateFileId,
  validateForm,
  validateRequired,
  validateSelectField,
} from "components/common/services/ValidationService";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import { FILE_URL } from "routes/ApiEndpoints";
import { t } from "components/CentralDataMangement/translation/Translation";
import { formatDate } from "components/common/utlis/HelperFunctions";
import ModalPopup from "components/common/atoms/ModalPopup";
import { Option } from "components/common/services/ValidationService";

import { DocumnetChecklistProps } from "components/CandidateCreation/annotations/CandidateAnnotations";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import ViewIcon from "static/images/ViewIcon";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

const Documents: React.FC = () => {
  const { state, dispatch, isViewMode } = useFormContext();
  const userAuth = useSelector(selectAuth);

  const [documentData, setDocumentData] = useState<DocumnetChecklistProps>({
    id: null,
    documentName: "",
    documentCategory: null,
    documentId: null,
    fileName: "",
    filePath: "",
    validFrom: null,
    validTo: null,
    extension: "",
    editId: null,
    uploadedAt: new Date(),
    uploadedBy: userAuth.name,
  });

  const fileUploadStyleObject: React.CSSProperties = {
    width: "100%",
  };

  const initialDocumentErrors: { [key: string]: string } = {};
  const [documentErrors, setDocumentErrors] = useState<{
    [key: string]: string;
  }>(initialDocumentErrors);

  const [isDocumentModalOpen, setDocumentModalOpen] = useState(false);
  const [documentIndex, setDocumentIndex] = useState(0);

  const checkDuplicateFileName = (
    documentChecklist: any,
    docCategory: any,
    docName: any
  ) => {
    const editId = documentData.editId; // Get the editId from documentData
    const isDuplicate = documentChecklist.some(
      (doc: any) =>
        doc.documentCategory?.value === docCategory?.value &&
        doc.documentName === docName &&
        doc.editId !== editId // Ignore duplicates with the same editId
    );
    if (isDuplicate) {
      setDocumentErrors((prevErrors) => ({
        ...prevErrors,
        documentName: t("File name must be unique within the same category"),
      }));
      return true;
    } else {
      setDocumentErrors((prevErrors) => ({
        ...prevErrors,
        documentName: "",
      }));
      return false;
    }
  };

  const documentValidation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      documentName: [validateRequired],
      documentCategory: [validateSelectField],
      documentId: [validateFileId],
    };
    const validationErrors = validateForm(
      { ...documentData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setDocumentErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setDocumentErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const addDocumentData = (index: number) => {
    setDocumentData((prev) => ({
      ...prev,
      editId: index,
    }));
    setDocumentIndex(index); // Set the index for dispatch
    setDocumentModalOpen(true);
  };

  const handleDocumentFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if(isViewMode) return;

    const { name, value } = event.target;
    let updatedValue: string | number | null = value;
    setDocumentData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }));
    documentValidation(name, value, true);
    if (name === "documentName") {
      checkDuplicateFileName(
        state.documentChecklist,
        documentData.documentCategory,
        value
      );
    }
  };

  const handleDocumentDateChange = (date: Date | null, name: string) => {
    setDocumentData((prevState) => ({
      ...prevState,
      [name]: date,
    }));
  };

  const handleDocumentSelectChange = (
    selectedOption: any,
    fieldName: string
  ) => {
    setDocumentData((prevState) => ({
      ...prevState,
      [fieldName]: selectedOption,
    }));
    documentValidation(fieldName, selectedOption, true);
    if (fieldName === "documentCategory") {
      checkDuplicateFileName(
        state.documentChecklist,
        selectedOption,
        documentData.documentName
      );
    }
  };

  const closeDocumentModal = () => {
    setDocumentModalOpen(false);
    resetDocumentFields();
    resetDocumentErrors();
  };

  const documentUploadResponse = (responseObj: any) => {
    const response = responseObj["response"];
    const fieldNames = ["documentId", "fileName", "filePath"];
    fieldNames.forEach((fieldName: string, key: number) => {
      setDocumentData((prevData) => ({
        ...prevData,
        [fieldName]: response[key],
      }));
    });
    // Extract file name and extension
    const fileNameWithExtension = response[1];
    const lastDotIndex = fileNameWithExtension.lastIndexOf(".");
    const fileName =
      lastDotIndex !== -1
        ? fileNameWithExtension.substring(0, lastDotIndex)
        : fileNameWithExtension;
    const extension =
      lastDotIndex !== -1
        ? fileNameWithExtension.substring(lastDotIndex + 1)
        : "";
    setDocumentData((prevState) => ({
      ...prevState,
      extension: extension,
      documentName: fileName,
    }));
  };

  const documentDeleteResponse = (field: string) => {
    const fieldNames = ["documentId", "fileName", "filePath"];
    fieldNames.forEach((fieldName: string, key: number) => {
      setDocumentData((prevData) => ({
        ...prevData,
        [fieldName]: fieldName.endsWith("Id") ? null : "",
      }));
      setDocumentData((prevData) => ({
        ...prevData,
        documentName: "",
        extension: "",
      }));
    });
  };

  const documentUploadErrors = (errorResposne: any) => {
    setDocumentErrors({
      documentId: errorResposne.error,
    });
  };

  const onDocumentOkButtonClick = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    const isDocumentNameValid = checkDuplicateFileName(
      state.documentChecklist,
      documentData.documentCategory,
      documentData.documentName
    );

    if (!isDocumentNameValid) {
      if (documentValidation(name, value)) {
        dispatch({
          type: "ADD_DOCUMENT_FIELD",
          data: documentData,
          index: documentIndex,
        });
        setDocumentModalOpen(false); // Close modal
        resetDocumentFields();
        resetDocumentErrors();
      }
    }
  };

  const removeDocumentFieldSet = (indexToRemove: number | null) => {
    if (indexToRemove !== null) {
      dispatch({ type: "REMOVE_DOCUMENT_FIELD", indexToRemove });
    }
  };

  const editDocumentData = (index: number | null) => {
    if (index !== null) {
      setDocumentIndex(index); // Set the index for dispatch
      setDocumentModalOpen(true);
      const documentToEdit = state.documentChecklist[index]; // Get the certificate to edit
      if (documentToEdit) {
        // Populate the certificate data in the modal fields
        setDocumentData({
          id: documentToEdit.id,
          documentName: documentToEdit.documentName,
          documentCategory: documentToEdit.documentCategory,
          documentNo: documentToEdit.documentNo,
          validFrom: documentToEdit.validFrom,
          validTo: documentToEdit.validTo,
          documentId: documentToEdit.documentId,
          fileName: documentToEdit.fileName,
          filePath: documentToEdit.filePath,
          extension: documentToEdit.extension,
          editId: index,
          uploadedAt: documentToEdit.uploadedAt,
          uploadedBy: documentToEdit.uploadedBy,
        });
      }
    }
  };

  const resetDocumentErrors = () => {
    setDocumentErrors({
      documentName: "",
      documentCategory: "",
      documentId: "",
    });
  };

  const resetDocumentFields = () => {
    setDocumentData({
      id: null,
      documentName: "",
      documentCategory: null,
      documentId: null,
      fileName: "",
      filePath: "",
      validFrom: null,
      validTo: null,
      extension: "",
      editId: null,
      uploadedAt: new Date(),
      uploadedBy: userAuth.name,
    });
  };
  // *************************SearchFields form ********************************
  interface SearchFields {
    documentCategory: Option | null;
    extension: Option | null;
    documentName: string;
  }
  const extensionOptions = [
    { value: 1, label: "doc" },
    { value: 2, label: "docx" },
    { value: 3, label: "jpeg" },
    { value: 4, label: "jpg" },
    { value: 5, label: "pdf" },
    { value: 6, label: "png" },
  ];

  const [searchFields, setSearchFields] = useState<SearchFields>({
    documentCategory: null,
    extension: null,
    documentName: "",
  });

  const handleSelectFieldForSearch = (
    selectedOption: any,
    fieldName: string
  ) => {
    setSearchFields((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };

  const handleInputFieldForSearch = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setSearchFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const countCategory = (categoryId: number | undefined | null) => {
    return state.documentChecklist.filter(
      (doc) => doc.documentCategory?.value === categoryId
    ).length;
  };

  // ************************checklist code ends ********************************
  return (
    <>
      {" "}
      {/* Document checklist code start */}
      {/* Search bar */}
      <div
        className={"d-grid"}
        style={{ gridTemplateColumns: "repeat(3, 1fr)", gap: "0.8vw" }}
      >
        <div>
          <SelectWithSearch
            isMandatory={false}
            title={t("Category")}
            search={true}
            options={[
              {
                value: 0,
                label: `All Files (${state.documentChecklist.length})`,
              }, // Add the "All Files" option with count
              ...state.dropDowndata.categoriesList.map((option) => ({
                ...option,
                label: `${option.label} (${countCategory(option.value)})`,
              })),
            ]}
            isSelectOption={false}
            onChange={(e) => handleSelectFieldForSearch(e, "documentCategory")}
            isMulti={false}
            name="attestType"
            value={
              searchFields.documentCategory || {
                value: 0,
                label: `All Files (${state.documentChecklist.length})`,
              }
            }
            isTranslate={true}
          />
        </div>
        <div>
          <LabelWithInputField
            isMandatory={false}
            label={t("File name")}
            name="documentName"
            handleChange={(event) => handleInputFieldForSearch(event)}
            value={searchFields.documentName}
            placeholder={t("File name")}
            type="text"
          />
        </div>
        <div>
          <SelectWithSearch
            isMandatory={false}
            title={t("File extension")}
            search={true}
            options={extensionOptions}
            onChange={(e) => handleSelectFieldForSearch(e, "extension")}
            isMulti={false}
            name="extension"
            value={searchFields.extension}
          />
        </div>
      </div>
      {/* Search bar */}
      <div className="pwa table-responsive marginBotttom1">
        <table className="table table-hover">
          <thead>
            <tr className="border-0 TableHeader bg-white">
              <th className="border-0 align-middle ps-lg-4">{t("File")}</th>
              <th className="border-0 align-middle">{t("Category")}</th>
              <th className="border-0 align-middle">{t("Valid from")}</th>
              <th className="border-0 align-middle">{t("Valid until")}</th>
              <th className="border-0 align-middle">{t("Uploaded at")}</th>
              <th className="border-0 align-middle">{t("Uploaded by")}</th>
              <th className="border-0 align-middle">{t("Action")}</th>
            </tr>
          </thead>
          <tbody>
            {state.documentChecklist
              .filter((document) => {
                // If no category is selected, include all documents
                if (
                  !searchFields.documentCategory ||
                  searchFields.documentCategory.value === 0
                ) {
                  // Check if the document name matches the search criteria
                  const fileNameMatches =
                    searchFields.documentName.trim() === "" ||
                    document.documentName
                      .toLowerCase()
                      .includes(searchFields.documentName.toLowerCase());

                  const extensionMatches =
                    !searchFields.extension ||
                    searchFields.extension?.label === "Select" ||
                    document.extension.toLowerCase() ===
                    searchFields.extension?.label;

                  return fileNameMatches && extensionMatches;
                }

                // Check if the document matches the selected category
                const categoryMatches =
                  document.documentCategory?.value ===
                  searchFields.documentCategory?.value;

                // Check if the document matches the file name
                const fileNameMatches =
                  searchFields.documentName.trim() === "" ||
                  document.documentName
                    .toLowerCase()
                    .includes(searchFields.documentName.toLowerCase());

                // Check if the document matches the file extension
                const extensionMatches =
                  !searchFields.extension ||
                  searchFields.extension?.label === "Select" ||
                  document.extension.toLowerCase() ===
                  searchFields.extension?.label;

                // // Include the document if it matches all filter criteria
                return categoryMatches && fileNameMatches && extensionMatches;
              })
              .map((document, index) => (
                <tr key={index}>
                  <td className="ps-lg-4 align-middle text-break">
                    <a
                      className="file-link"
                      href={
                        document.filePath?.startsWith("https")
                          ? document.filePath // If filePath starts with "https", use it directly
                          : `${FILE_URL}/${document.filePath}` // Otherwise, construct the URL using FILE_URL and picFilePath
                      }
                      target="_blank"
                    >
                      {document.documentName}
                    </a>
                  </td>
                  <td
                    className="align-middle text-break"
                    data-label={t("File")}
                  >
                    {document.documentCategory?.label}
                  </td>
                  <td
                    className="align-middle text-break"
                    data-label={t("Category")}
                  >
                    {formatDate(document.validFrom)}
                  </td>
                  <td
                    className="align-middle text-break"
                    data-label={t("Valid from")}
                  >
                    {formatDate(document.validTo)}
                  </td>
                  <td
                    className="align-middle text-break"
                    data-label={t("uploaded at")}
                  >
                    {formatDate(document.uploadedAt)}
                  </td>
                  <td
                    className="align-middle text-break"
                    data-label={t("uploaded by")}
                  >
                    {document.uploadedBy}
                  </td>
                  <td
                    className="align-middle text-break"
                    data-label={t("Valid until")}
                  >
                    <span
                      title={isViewMode ? t("View") : t("Edit")}
                      onClick={() => editDocumentData(document.editId)}
                      className="table-action-icons cursor-pointer marginRightPoint5 rounded-0 shadow-none"
                    >
                      {isViewMode ? <ViewIcon /> : <EditIcon />}
                    </span>
                    {!isViewMode &&  (userAuth.role === "ADMIN" || userAuth.role === "SUPER_ADMIN") && (
                      <span
                        title={t("Delete")}
                        onClick={() => removeDocumentFieldSet(index)}
                        className="table-action-icons cursor-pointer rounded-0 shadow-none"
                      >
                        <DeleteIcon />
                      </span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {!isViewMode && (
        <div className="col-md-12">
          <Button
            title={"+" + t("Add")}
            handleClick={() => addDocumentData(state.documentChecklist.length)}
            className="form-button float-end shadow-none"
          />
        </div>
      )}
      {/* Checklist section modal popup */}
      <ModalPopup
        size="xl"
        show={isDocumentModalOpen}
        onHide={closeDocumentModal}
        title={t("Document")}
        headerClassName={`col-11 mx-auto`}
        body={
          <>
            <div className="row justify-content-center">
              <div className="col-sm-12 col-lg-5 col-md-12 upload-certificate">
                <FileUpload
                  id="checkdocumentId"
                  name="documentId"
                  label={t("File upload")}
                  isMandatory={true}
                  edit={false}
                  fileId={documentData.documentId ?? null}
                  fileName={documentData.fileName ?? ""}
                  filePath={documentData.filePath ?? ""}
                  style={fileUploadStyleObject}
                  multiple={false}
                  uploadPath={
                    documentData.filePath?.includes("candidate/cv") 
                      ? "candidate-cv" 
                      : documentData.filePath?.includes("candidate_consent") 
                        ? "candidate_consent" 
                        : "candidate-certificate"
                  }                  
                  formats=".pdf,.docx,.doc,.jpeg,.png,.jpg"
                  returnFileUploadErrors={(errorResponse) =>
                    documentUploadErrors(errorResponse)
                  }
                  fileUploadResponse={(uploadResposne) => {
                    documentUploadResponse(uploadResposne);
                  }}
                  deleteResponse={(field) => documentDeleteResponse(field)}
                  maxFileSize={10 * 1024 * 1024}
                  showDeleteIcon={!isViewMode}
                  isViewMode={isViewMode}
                />
                <div
                  className="text-danger height-20"
                  style={{ marginBottom: "0.5vw" }}
                >
                  <span className="text-danger">
                    {documentErrors.documentId}
                  </span>
                </div>
              </div>
              <div
                className={`col-sm-12 col-lg-6 col-md-12 d-flex align-items-center ${isViewMode ? "form disableform" : "form"
                  }`}
              >
                <LabelWithInputField
                  isMandatory={true}
                  name="documentName"
                  handleChange={(event) => handleDocumentFieldChange(event)}
                  value={documentData.documentName}
                  id={`documentName-${documentIndex}`}
                  label={t("File name")}
                  placeholder={t("File name")}
                  type="text"
                  error={documentErrors.documentName}
                />
                <span className="ms-2 fw-bold">
                  {documentData.extension !== ""
                    ? `.${documentData.extension}`
                    : ""}
                </span>
              </div>
              <div
                className={`col-sm-12 col-md-12 col-lg-5 ${isViewMode ? "form disableform" : "form"
                  }`}
              >
                <SelectWithSearch
                  title={t("Category")}
                  isMandatory={true}
                  search={true}
                  options={state.dropDowndata.categoriesList}
                  id={`category-${documentIndex}`}
                  onChange={(e) =>
                    handleDocumentSelectChange(e, "documentCategory")
                  }
                  isMulti={false}
                  name="attestType"
                  value={documentData.documentCategory}
                  error={documentErrors.documentCategory}
                  isTranslate={true}
                />
              </div>
              <div
                className={`col-sm-12 col-md-6 col-lg-3 position-relative ${isViewMode ? "form disableform" : "form"
                  }`}
              >
                <Calender
                  onChange={(date) =>
                    handleDocumentDateChange(date, "validFrom")
                  }
                  selectedDate={documentData.validFrom}
                  label={t("Valid from")}
                  name="validFrom"
                  maxDate={documentData.validTo}
                  isMandatory={false}
                />
              </div>
              <div
                className={`col-sm-12 col-md-6 col-lg-3 position-relative ${isViewMode ? "form disableform" : "form"
                  }`}
              >
                <Calender
                  onChange={(date) => handleDocumentDateChange(date, "validTo")}
                  selectedDate={documentData.validTo}
                  label={t("Valid to")}
                  name="validTo"
                  isMandatory={false}
                  minDate={documentData.validFrom}
                />
              </div>
            </div>
            {!isViewMode && (
              <div className="col-11 mx-auto">
                <Button
                  handleClick={onDocumentOkButtonClick}
                  className="mx-0 shadow-none form-button float-end"
                >
                  {t("OK")}
                </Button>
              </div>
            )}
          </>
        }
        className="modal-lg documentsCertificates"
      />
    </>
  );
};
export default Documents;
