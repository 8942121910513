import { OptionProps } from "components/common/utlis/TypeAnnotations";

interface dropDownList {
  offices: OptionProps[];
  // agents: OptionProps[];
  status: OptionProps[];
  collegues: OptionProps[];
}
export const createFilterConfig = (dropDownList: dropDownList) => {
  return [
    {
      name: "office",
      options: dropDownList.offices,
      fieldType: "multiSelect",
      placeholder: "Office",
      filterType: "search",
    },
    {
      name: "companyName",
      fieldType: "text",
      placeholder: "Customer",
      filterType: "search",
    },
    {
      name: "collegue",
      fieldType: "text",
      placeholder: "Collegue",
      filterType: "search",
    },
    {
      name: "from",
      fieldType: "date",
      placeholder: "From",
      label: "From",
      filterType: "search",
    },
    {
      name: "to",
      fieldType: "date",
      placeholder: "To",
      label: "To",
      filterType: "search",
    }
    // {
    //   name: "status",
    //   options: dropDownList.status,
    //   fieldType: "singleSelect",
    //   placeholder: "Status",
    //   filterType: "search",
    //   prefixClass: "selectSection",
    // },
  ];
};
