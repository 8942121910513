import React, { useState } from "react";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/common/atoms/Button";
import HidePassword from "static/images/Hide.svg";
import ShowPassword from "static/images/Show.svg";
import LabelField from "components/common/atoms/LabelField";
import InputTextfield from "components/common/atoms/InputTextField";
import { ApiCall } from "components/common/services/ApiServices";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import { useDispatch } from "react-redux";
import EncryptDecryptService from "components/common/services/EncryptDecryptService";
import { addUserDetails } from "features/auth/AuthSlice";
import ImageField from "components/common/atoms/ImageField";
import "static/css/login.css";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {
  validateForm,
  validateEmail,
  validateRequired,
  validatePassword,
  validateLoginEmail,
  // validateCheckbox,
} from "components/common/services/ValidationService";
import CustomNotify from "components/common/atoms/CustomNotify";
import {
  GoogleReCaptchaProvider,
  // GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { CANDIDATE_ROLE, reCaptchaSiteKey } from "Constants";
import { LOGIN } from "routes/ApiEndpoints";
import { setLanguage, t, translate } from "components/CentralDataMangement/translation/Translation";
import secureLocalStorage from "react-secure-storage";

interface Credentials {
  email?: string;
  password?: string;
  isCaptcha?: boolean;
}

interface ValidationRules {
  [key: string]: Function[];
}

const LoginForm = () => {
  const [passwordType, setPasswordType] = useState("password");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [data, setData] = useState<Credentials>({
    email: "",
    password: "",
    isCaptcha: false,
  });

  const [errors, setErrors] = useState<Credentials>({});

  const [loading, setLoading] = useState(false);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
    validation(name, value, true);
  };

  const validation = (
    name: string,
    value: string,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      email: [validateRequired, validateLoginEmail],
      password: [validateRequired, validatePassword],
      // isCaptcha: [validateCheckbox],
    };

    const validationErrors = validateForm(
      { ...data, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;
    const formData = {
      email: data.email,
      password: EncryptDecryptService.encryptData(
        JSON.stringify(data.password)
      ),
    };

    if (validation(name, value)) {
      const response = await ApiCall.service(LOGIN, "POST", formData, true);
      if (response.status === 200) {
        const getRefreshToken = secureLocalStorage.getItem("refresh_token");
        let token;
        if (getRefreshToken === "" || getRefreshToken === null) {
          token = null;
        } else {
          const refreshTokenObj = JSON.parse(getRefreshToken as string);
          token = refreshTokenObj.token;
        }

        const user = {
          token: response.access_token,
          email: response.data["email"],
          role: response.data["role"],
          userId: response.data["userId"],
          name: response.data["name"],
          fullName: response.data["fullName"],
          profilePicName: response.data["prof_pic_name"],
          profilePicId: response.data["profPicId"],
          profilePicPath: response.data["profPicPath"],
          token_type: response.token_type,
          isAuthenticated: true,
          refreshToken: token,
          candidateId: response.data["candidateId"],
          lastLogin: response.data["lastLogin"],
          office: response.data["office"],
          officeId: response.data["officeId"],
          correspondingOffice: response.data["correspondingOffice"],
          isSignature: response.data['is_signature'],
          userPrefLang: response.data["userPrefLang"],
          consentStatus: response.data["consentStatus"],
          consentToTerms: response.data["consentToTerms"],
        };
        dispatch(addUserDetails(user));
        if (user.lastLogin === null) {
          navigate("/");
        } else {
          navigate("/dashboard");
        }
        CustomNotify({
          type: "success",
          message: t("Logged in successfully") + "!",
        });
        secureLocalStorage.setItem("site_lang", response?.data["userPrefLang"] ?? 'en');
        setLanguage(response?.data["userPrefLang"] ?? 'en');
      } else {
        setErrors({ email: response.msg });
        CustomNotify({
          type: "error",
          message: t(response.msg),
          autoClose: 2000,
        });
      }
    }
    setLoading(false);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  // const handleCaptchaVerify = (response: string | null) => {
  //   if (response) {
  //     setData((prevFormData) => ({
  //       ...prevFormData,
  //       isCaptcha: true,
  //     }));
  //   }
  // };

  return (
    <>
      <div className="container-fluid login-bg">
        <div className="row">
          <div className="col-6 px-0 vh-100">
            <ImageField
              altText={t("Banner")}
              className="w-100 h-100 img-fluid object-fit-cover"
              src="/static/images/Welcome-dashboard.png"
            />
          </div>
          <div className="col-6 align-self-center">
            <div style={{ width: "60%", margin: "auto" }}>
              <div className="text-center" style={{ marginBottom: "3vw" }}>
                <ImageField
                  altText={t("Logo")}
                  className="text-center logo-width"
                  src="static/images/logo.svg"
                />
              </div>
              <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSiteKey}>
                <form onSubmit={submitHandler} className="login-form">
                  <div className="text-center" style={{ marginBottom: "2vw" }}>
                    <span className="text-center login-title position-relative">
                      {t("Login")}
                      <img
                        src="../static/images/Splash_1.svg"
                        alt="dot-arrow"
                        className="dot-arrow position-absolute"
                      />
                    </span>
                  </div>
                  <LabelWithInputField
                    label={t("Email")}
                    id="email"
                    isMandatory={true}
                    placeholder={t("Enter email")}
                    type="email"
                    name="email"
                    handleChange={changeHandler}
                    value={data.email}
                    error={errors.email}
                    autoComplete="username"
                  />
                  <div className="col-lg-12">
                    <LabelField
                      title={t("Password")}
                      isMandatory={true}
                      htmlfor="password"
                    />
                    <div className="d-flex position-relative">
                      <InputTextfield
                        handleChange={changeHandler}
                        type={passwordType}
                        value={data.password}
                        id="password"
                        placeholder={t("Enter password")}
                        name="password"
                        autoComplete="current-password"
                      />
                      <p
                        onClick={togglePassword}
                        className="position-absolute cursor-pointer eye-icon"
                      >
                        {passwordType === "password" ? (
                          <img src={HidePassword} alt={t("Hide Password")} />
                        ) : (
                          <img src={ShowPassword} alt={t("Show Password")} />
                        )}
                      </p>
                    </div>
                    <div className="row">
                      <div
                        className="height-20"
                        style={{ marginBottom: "0.5vw" }}
                      >
                        {errors.password && (
                          <span className="text-danger">{errors.password}</span>
                        )}
                      </div>
                    </div>
                    <div className="row" style={{ marginBottom: "1.6vw" }}>
                      <div className="col-md-12">
                        <div className="ms-auto">
                          <Link
                            to="/forgot-password"
                            className="float-end link-color"
                          >
                            {t("Forgot password") + "?"}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      {/* <GoogleReCaptcha
                      onVerify={handleCaptchaVerify}
                    /> */}
                    </div>
                    <div className="row">
                      <div className="text-center">
                        {errors.isCaptcha && (
                          <span className="text-danger">
                            {t("Please verify that you are not a robot.")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-10 mx-auto text-center">
                      {!loading ? (
                        <Button
                          title={t("Login")}
                          icon={faAngleRight}
                          type="submit"
                          className="form-button w-100"
                          style={{ marginBottom: "1.5vw" }}
                        />
                      ) : (
                        <div>
                          <LoadingIcon
                            iconType="bars"
                            color="#ff4dae"
                            height={"3vw"}
                            width={"3vw"}
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-md-12 text-center">
                      <span>
                        {t("Don't have an account") + "?"}&nbsp;
                        <Link to="/register" className="link-color ms-1">
                          {t("Register as a candidate")}
                        </Link>
                      </span>
                    </div>
                  </div>
                </form>
              </GoogleReCaptchaProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(LoginForm);
