import React, { useEffect, useState } from "react";
import { t } from "components/CentralDataMangement/translation/Translation";
import { Modal } from "react-bootstrap";
import Button from "components/common/atoms/Button";
import "./Matching.css";
import LabelField from "components/common/atoms/LabelField";
import RightSideBar from "components/common/atoms/RightSideBar";
import { ApiCall } from "components/common/services/ApiServices";
import { FILE_URL, VACANCY_DETAILS } from "routes/ApiEndpoints";
import { useNavigate } from "react-router-dom";
interface workExperience {
  company: string;
  date: string;
  title: string;
  description: string;
}

interface studies {
  date: string;
  diploma: string;
  fieldOfStudy: string;
  school: string;
}

export interface CandidateDetails {
  candidateId: number | string;
  birthDate: string;
  countryOfBirth: string;
  emailAddress: string;
  employeeType: string;
  experience: string;
  jobDomains: string;
  infoDesiredJobs: string;
  maximumDistance: string;
  minimumSalary: string;
  name: string;
  nationalities: string[];
  office: string;
  phoneNumber: string;
  regimes: string;
  salaryExpectation: string;
  sectors: string;
  address: string;
  languageDocuments: string;
  gender: string;
  educationalLevel: string;
  workExperiences: workExperience[];
  studies: studies[];
  filePath: string;
  fileName: string;
  civilStatus: string;
}

export interface VacancyDetails {
  function: string;
  office: string;
  jobdomain: string;
  statute: string;
  employment: string;
  jobOffer: string;
  jobDesc: string;
  profileDesc: string;
  contactPerson: string;
  address: string;
  company: string;
  pay_type: string;
  salary_amount_min: string | number;
  salary_amount_max: string | number;
  vacancyId: number | null;
  email: string;
  phone: string;
}

interface MatchingModalPopUpProps {
  type: string | undefined;
  title: string;
  show: boolean;
  onClose?: () => void;
  canOrVacId: number | null;
  handlePrevious: () => void;
  handleNext: () => void;
  currentIndex: number;
  totalRecords: number;
  score: number;
}

const MatchingModalPopUp: React.FC<MatchingModalPopUpProps> = ({
  type,
  title,
  show,
  onClose,
  canOrVacId,
  handlePrevious,
  handleNext,
  currentIndex,
  totalRecords,
  score
}) => {
  const renderContent = (content: string | null) => {
    return content ? (
      <div dangerouslySetInnerHTML={{ __html: content }} />
    ) : (
      <span></span>
    );
  };
  const navigate = useNavigate();
  const [candidate, setCandidate] = useState(null as CandidateDetails | null);
  const [vacancy, setVacancy] = useState(null as VacancyDetails | null);

  useEffect(() => {
    if (canOrVacId) {
      handleClick(canOrVacId);
    }
  }, [canOrVacId]);

  const handleClick = async (canOrVacId: number | null) => {
    const data = {
      id: canOrVacId,
    };
    if (type === "vacancy") {
      const url = `candidateDetails/${canOrVacId}`;
      const response = await ApiCall.service(
        url,
        "POST",
        data,
        true,
        "candidate-creation"
      );

      if (response.status === 200) {
        setCandidate(response.data);
      }
    } else if (type === "candidate") {
      const data = {
        type: "vacancy",
      };

      const url = `${VACANCY_DETAILS}/${canOrVacId}`;
      const response = await ApiCall.service(
        url,
        "POST",
        data,
        true,
        "central-data-management"
      );
      if (response.status === 200) {
        setVacancy(response.data);
      }
    }
  };

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={onClose}
        centered
        className={`matchingModalPopUP`}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton title="Close" className="border-0 px-0">
          <Modal.Title>
            {title && title.charAt(0).toUpperCase() + title.slice(1)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-0 border-0 matchingModalPopUpBody scrollBarDesign">
          <div>
            {type === "vacancy" && candidate !== null ? (
              <div className="row">
                {/* Left section */}
                <div className="col-9 marginBotttom1">
                  <div className="d-flex">
                  <div style={{ width: "18%" }} className="me-auto">
                      {candidate.fileName !== "" &&
                        candidate.fileName !== undefined ? (
                        <img
                          src={
                            candidate.filePath?.startsWith("https")
                              ? `${candidate.filePath}`
                              : `${FILE_URL}/${candidate.filePath}`
                          }
                          alt={t("Uploaded")}
                          style={{
                            backgroundColor: "var(--color-white)",
                            borderRadius: "50%",
                            boxShadow:
                              "rgb(248 216 235) 0.052vw 0px 0.521vw 0.156vw",
                          }}
                          width="100%"
                          height="100%"
                        />
                      ) : (
                        <img
                          src="/static/images/Person.svg"
                          alt="Profile"
                          className="matchingPopUpProfileImage"
                        />
                      )}
                    </div>
                    <div style={{ width: "80%" }}>
                      {/* section 1 */}
                      <div className="bg-white padding1 h-100">
                        <div className="d-grid matchingModalPopUPGrid">
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Name")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate?.name}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Phone")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate.phoneNumber}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Office")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate.office}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Address")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate.address}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Email")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate.emailAddress}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                {/* Right section */}
                <div className="col-3 marginBotttom1">
                  <div className="bg-white padding1 h-100">
                    <div className="roobert-semibold martchingCandidateScoreLabel">
                      {t("Matching candidate score")}:
                    </div>
                    <div className="roobert-semibold marginBottomPoint5">
                      {t("Score")}
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <span
                        className="color-dark-pink"
                        style={{ width: "4vw" }}
                      >
                        {Math.round(score)}%
                      </span>
                      <div className="progress-bar-container">
                        <div
                          className="progress-bar"
                          style={{ width: `${score}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  {/* section 2 */}
                  <div>
                    <div className="bg-white padding1 marginBotttom1">
                      <div className="d-grid matchingModalPopUPGrid">
                        <div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Date of birth")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate.birthDate}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Country of birth")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate.countryOfBirth}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Nationality")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate.nationalities}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="matchingModalPopUPGridlabel">
                            {t("Language documents")}:
                          </div>
                          <div className="matchingModalPopUPGridContent">
                            {candidate?.languageDocuments}
                          </div>
                        </div>
                        <div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Civil status")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate?.civilStatus}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Gender")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate?.gender}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Educational level")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate?.educationalLevel}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* section 3 */}
                  <div>
                    <div className="bg-white padding1 marginBotttom1">
                      <div className="d-grid matchingModalPopUPGridSection3">
                        <div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Desired sectors")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate?.sectors}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Desired job domains")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate?.jobDomains}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Info desired jobs")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate?.infoDesiredJobs}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Maximum distance home-work")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate?.maximumDistance}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Desired regime")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate?.regimes}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Salary expectation")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate?.salaryExpectation}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Minimum salary")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {candidate?.minimumSalary ? `${candidate.minimumSalary} €` : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* section 4 */}
                  <div>
                    <div className="d-grid matchingModalPopUPGrid">
                      <div className="w-100 bg-white padding1 marginBotttom1">
                        <LabelField
                          className="tab-subtitle"
                          title={t("Studies")}
                        />

                        {candidate.studies && candidate.studies.length > 0 ? (
                          <ul>
                            {candidate.studies.map((item, index) => (
                              <li key={index}>
                                <div className="marginBotttom1">
                                  <div>
                                    <strong>{item.diploma}</strong>{" "}
                                    {item.fieldOfStudy ? " (" + item.fieldOfStudy + ")" : ""}
                                  </div>
                                  <div>{item.school}</div>
                                  <div>{item.date.trim() !== '-' ? item.date : ''}</div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <div>{t("Not available.")}</div>
                        )}
                      </div>
                      <div className="bg-white padding1 marginBotttom1">
                        <LabelField
                          title={t("Work experience")}
                          className="tab-subtitle"
                        />
                        {candidate.workExperiences &&
                          candidate.workExperiences.length > 0 ? (
                          <ul>
                            {candidate.workExperiences.map((item, index) => (
                              <li key={index}>
                                <div>
                                  <strong>{item.title}</strong>
                                  {item.company ? " (" + item.company + ")" : ""}
                                </div>
                                <div>{item.date.trim() !== '-' ? item.date : ''}</div>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <div>{t("No work experience available.")}</div>
                        )}
                      </div>
                      <div className="bg-white padding1 marginBotttom1">
                        <LabelField
                          title={t("Follow ups")}
                          className="tab-subtitle pb-0"
                        />
                        <RightSideBar
                          entityType={"candidate"}
                          entityId={canOrVacId ? canOrVacId.toString() : ''}
                          getWidth={() => { }}
                          recordsLimit={5}
                          isInBright={true}
                          titleClassName="mt-0 addFollowUp"
                          className="p-0"
                          isInPopUp={true}
                          contentScroll="matchingPopupFollowUpHeight scrollBarDesign"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              vacancy !== null && (
                <div className="row">
                  {/* Left section */}
                  <div className="col-9 marginBotttom1">
                    {/* section 1 */}
                    <div className="bg-white padding1 ">
                      <div className="d-grid matchingModalPopUPGrid">
                        <div>
                          <div className="d-flex marginBottomPoint5">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Function")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {vacancy?.function}
                            </div>
                          </div>
                          <div className="d-flex marginBottomPoint5">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Statute")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {vacancy.statute}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Address")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {vacancy.address}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex marginBottomPoint5">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Phone")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {vacancy.phone}
                            </div>
                          </div>
                          <div className="d-flex marginBottomPoint5">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Email")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {vacancy.email}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex marginBottomPoint5">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Company")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {vacancy.company}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Office")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {vacancy.office}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Right section */}
                  <div className="col-3 marginBotttom1">
                    <div className="bg-white padding1 h-100">
                      <div className="roobert-semibold martchingCandidateScoreLabel">
                        {t("Matching vacancy score")}:
                      </div>
                      <div className="roobert-semibold marginBottomPoint5">
                        {t("Score")}
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <span
                          className="color-dark-pink"
                          style={{ width: "4vw" }}
                        >
                          {Math.round(score)}%
                        </span>
                        <div className="progress-bar-container">
                          <div
                            className="progress-bar"
                            style={{ width: `${score}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    {/* section 2 */}
                    <div>
                      <div className="bg-white padding1 marginBotttom1 matchingDetailVacancyPopup">
                        <div className="d-grid  matchingModalPopUPGrid">
                          <div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Pay type")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {vacancy.pay_type}
                            </div>
                          </div>
                          < div className="d-flex">
                            <div className="matchingModalPopUPGridlabel">
                              {t("Starter wage")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {vacancy.salary_amount_min ? `${vacancy.salary_amount_min} €` : ''}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div
                              className="matchingModalPopUPGridlabel"
                              style={{ whiteSpace: "break-spaces" }}
                            >
                              {t("Experienced wage")}:
                            </div>
                            <div className="matchingModalPopUPGridContent">
                              {vacancy?.salary_amount_max ? `${vacancy.salary_amount_max} €` : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* section 3*/}
                    <div>
                      <div className="d-flex">
                        <div style={{ width: "75%" }}>
                          <div className="w-100 bg-white padding1 marginBotttom1">
                            <LabelField
                              className="tab-subtitle"
                              title={t("Job description")}
                            />
                            {vacancy.jobDesc &&
                              vacancy.jobDesc.trim() !== "" ? (
                              <div className="mt-3">
                                {renderContent(vacancy.jobDesc)}
                              </div>
                            ) : (
                              <div>{t("No job description available.")}</div>
                            )}
                          </div>
                          <div className="bg-white padding1 marginBotttom1">
                            <LabelField
                              title={t("Offer")}
                              className="tab-subtitle"
                            />
                            {vacancy.jobOffer &&
                              vacancy.jobOffer.trim() !== "" ? (
                              <div className="mt-3">
                                {renderContent(vacancy.jobOffer)}
                              </div>
                            ) : (
                              <div>{t("No offer available.")}</div>
                            )}
                          </div>
                          <div className="bg-white padding1">
                            <LabelField
                              title={t("Profile")}
                              className="tab-subtitle"
                            />
                            {vacancy.profileDesc &&
                              vacancy.profileDesc.trim() !== "" ? (
                              <div className="mt-3">
                                {renderContent(vacancy.profileDesc)}
                              </div>
                            ) : (
                              <div>{t("No profile available.")}</div>
                            )}
                          </div>
                        </div>
                        <div style={{ width: "23.8%" }} className="ms-auto">
                          <div className="bg-white padding1 marginBotttom1 h-100">
                            <LabelField
                              title={t("Follow ups")}
                              className="tab-subtitle pb-0"
                            />
                            <RightSideBar
                              entityType={"vacancy"}
                              entityId={canOrVacId ? canOrVacId.toString() : ''}
                              isInBright={true}
                              getWidth={() => { }}
                              recordsLimit={5}
                              titleClassName="mt-0 addFollowUp"
                              contentScroll="matchingPopupFollowUpHeight scrollBarDesign"
                              className="p-0"
                              isInPopUp={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 px-0 d-block">
          <div className="d-flex justify-content-between">
            <div>
              {currentIndex >= 1 && (
                <Button
                  handleClick={handlePrevious}
                  className="marginRightPoint5 form-button shadow-none"
                >
                  {t("Previous")}
                </Button>
              )}
              {currentIndex <= totalRecords - 2 && (
                <Button
                  handleClick={handleNext}
                  className="marginRightPoint5 form-button shadow-none"
                >
                  {t("Next")}
                </Button>
              )}
            </div>
            <div>
              <Button
                handleClick={onClose}
                className="marginRightPoint5 form-button shadow-none"
              >
                {t("Close")}
              </Button>
              <Button
                handleClick={() => {
                  navigate(
                    type === "vacancy" && candidate
                      ? `/candidate/edit/${canOrVacId}`
                      : `/vacancy/${canOrVacId}`
                  );
                }}
                className="marginRightPoint5 form-button shadow-none"
              >
                {t("Edit")}
              </Button>
              {/* <Button
                handleClick={handleFollowUp}
                className="form-button shadow-none"
              >
                {t("Followup")}
              </Button> */}
            </div>
          </div>
        </Modal.Footer>
      </Modal >
    </>
  );
};

export default MatchingModalPopUp;
