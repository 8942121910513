import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import GetQueryParams from "components/common/services/GetQueryParams";
import {
  createQueryParams,
  filterAndMerge,
  getQueryParamsFromRedux,
} from "components/common/utlis/HelperFunctions";
import { createFilterConfig } from "./Filter";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import { ApiCall } from "components/common/services/ApiServices";
import { FETCH_PLANNINGS } from "../annotation/PlanningConstants";
import { COMPANY_MICROSERVICE } from "Constants";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import Title from "components/common/atoms/Title";
import { t } from "components/CentralDataMangement/translation/Translation";
import SearchFields from "components/common/atoms/SearchFields";
import ResetBtn from "components/common/atoms/ResetBtn";
import Sorting from "components/common/atoms/Sorting";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import { Link } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import NoRecords from "components/common/atoms/NoRecords";
import PaginationNew from "components/common/atoms/PaginationNew";
import LinkTo from "components/common/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface PlanningProps {
  office: string;
  customer: string;
  collegue: string;
  from: string;
  to: string;
  planningId: number;
  first_name: string;
  last_name: string;
}

interface SearchProps {
  office: number[];
  companyName: string;
  candidateName: string;
  from: Date | null | string;
  to: Date | null | string;
  orderByColumn: string;
  orderIn: "" | "asc" | "desc"; // Ensure correct typing here
  currentPage: number;
  recordsPerPage: number;
}

const ManagePlanning: React.FC = () => {
  const dispatch = useDispatch();
  const [planningData, setPlanningData] = useState<PlanningProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    totalRecords: 0,
  });
  const [dropdownList, setDropdownList] = useState({
    // agents: [] as OptionProps[],
    status: [] as OptionProps[],
    offices: [] as OptionProps[],
    collegues: [] as OptionProps[],
  });
  const destinationPath = useSelector(
    (state: RootState) => state.navigation.destinationPath
  );

  const getUrlParams = GetQueryParams();
  const paramFilter = getUrlParams.get("filter");
  const queryParams = getQueryParamsFromRedux(destinationPath, paramFilter);

  const filters = createFilterConfig(dropdownList);
  const defaultSearchData: SearchProps = {
    office: [],
    companyName: "",
    candidateName: '',
    from: "",
    to: "",
    orderByColumn: "",
    orderIn: "",
    currentPage: 1,
    recordsPerPage: 10,
  };

  const filteredSearchData = filterAndMerge(defaultSearchData, queryParams);

  // Initialize search data based on query params, merging with default values
  const [searchData, setSearchData] = useState<SearchProps>({
    ...defaultSearchData,
    ...filteredSearchData,
  });

  useEffect(() => {
    dispatch(clearDestinationPath());
    const filterData = { ...searchData };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchPlanningDetails({ ...searchData });
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  const fetchPlanningDetails = async (filterData: any) => {
    setLoading(true);
    const data = {
      search: filterData,
    };

    const response = await ApiCall.service(
      FETCH_PLANNINGS,
      "POST",
      data,
      false,
      COMPANY_MICROSERVICE
    );
    if (response.status === 200) {
      const { apiData, dropdownData, paginationData } = response.data;
      setPlanningData(apiData);
      setPaginationData(paginationData);
      // const usersList = dropdownData.agents.map((item: any) => ({
      //   value: item.userId,
      //   label: `${item.first_name} ${item.last_name}`,
      // }));

      setDropdownList((prevData) => ({
        ...prevData,
        ...dropdownData,
        // agents: usersList,
      }));
    }
    setLoading(false);
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      fromDashboard: false,
      [name]: value,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: pageNumber,
    }));
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      fromDashboard: false,
      [fieldName]: selectedOption,
    }));
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setSearchData((prev) => ({
      ...prev,
      recordsPerPage: newItemsPerPage,
      currentPage: 1,
    }));
  };

  const handleReset = async (e: React.FormEvent) => {
    dispatch(clearDestinationPath());
    setSearchData({ ...defaultSearchData, office: [] });
  };

  const handleOrderByColumn = (column: string) => {
    let newOrder: "" | "asc" | "desc" = ""; // Explicit type declaration
    let newColumn = column;

    if (searchData.orderByColumn === column) {
      // Cycle through the three possible order values: '', 'asc', 'desc'
      if (searchData.orderIn === "") {
        newOrder = "asc";
      } else if (searchData.orderIn === "asc") {
        newOrder = "desc";
      } else {
        newOrder = "";
        newColumn = "";
      }
    } else {
      newOrder = "asc";
    }

    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      orderByColumn: newColumn,
      orderIn: newOrder,
    }));
  };
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Contract",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className={`main-container rightSidebar`}>
        <Title title={t("Contracts")} />
        <div className="form-height-dashboard">
          <div className="position-relative tableMainWrapper">
            <div className="row">
              <div className="ManageCreateBtn">
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Contract",
                      create: true,
                    },
                  ]}
                >
                  <LinkTo
                    pagelink="/planning/create"
                    title={t("Create planning")}
                    icon={faPlus}
                    className="me-3"
                  />
                </AccessControl>
              </div>
            </div>
            <div className="table-responsive tableSection">
              <table className="table manageContractsWrapper border-0 mb-0">
                <thead>
                  <tr className="tableSearchFields">
                    <SearchFields
                      searchData={searchData}
                      filters={filters}
                      handleSearchChange={handleSearchChange}
                      handleSelectChange={handleSelectChange}
                    />
                    <th className="border-0">
                      <div className="d-flex align-items-center w-100">
                        <ResetBtn handleResetClick={handleReset} />
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
              <div className="tableContainer scrollBarDesign">
                <table className="table table-hover manageContractsWrapper">
                  <thead>
                    <tr className="TableHeader">
                      <th onClick={() => handleOrderByColumn("office")}>
                        {t("Office")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "office"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={() => handleOrderByColumn("company")}>
                        {t("Customer")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "company"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={() => handleOrderByColumn("collegue")}>
                        {t("Collegue")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "collegue"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th>{t("From")}</th>
                      <th>{t("To")}</th>
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Contract",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                      >
                        <th>{t("Action")}</th>
                      </AccessControl>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <SpinnerWrapper headerLength={7} />
                    ) : (
                      <>
                        {planningData && planningData.length > 0 ? (
                          planningData.map(
                            (item: PlanningProps, index: number) => (
                              <tr
                                key={index}
                                className="border-bottom mb-3 box-shadow align-middle"
                              >
                                <td
                                  className="text-break ps-lg-4"
                                  data-label={t("Office")}
                                >
                                  {item.office}
                                </td>
                                <td
                                  className="text-break"
                                  data-label={t("Customer")}
                                >
                                  {item.customer}
                                </td>
                                <td
                                  className="text-break"
                                  data-label={t("Collegue")}
                                >
                                  {t(`${item.collegue}`)}
                                </td>
                                <td className="text-break" data-label={t("From")}>
                                  {item.from}
                                </td>
                                <td className="text-break" data-label={t("To")}>
                                  {item.to}
                                </td>
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "Contract",
                                      update: true,
                                      delete: true,
                                    },
                                  ]}
                                  actions={true}
                                  strict={false}
                                >
                                  <td className="table-action-icons px-2">
                                    <AccessControl
                                      requiredPermissions={[
                                        {
                                          permission: "Contract",
                                          read: true,
                                        },
                                      ]}
                                    >
                                      <Link
                                        to={`/planning/edit/${item.planningId}`}
                                        className="btn p-0 border-0 me-2"
                                        title={t("Edit")}
                                      >
                                        <EditIcon />
                                      </Link>
                                    </AccessControl>
                                  </td>
                                </AccessControl>
                              </tr>
                            )
                          )
                        ) : (
                          <NoRecords headerLength={7} />
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {!loading && (
                <div className="pagination d-block">
                  <PaginationNew
                    currentPage={searchData.currentPage}
                    totalPages={paginationData.totalPages}
                    totalRecords={paginationData.totalRecords}
                    recordsPerPage={searchData.recordsPerPage}
                    onItemsPerPageChange={handleItemsPerPageChange}
                    onPageChange={(newPage: any) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="col-md-6 align-self-center backPadding">
          <Link
            to="/manage-companies"
            className=" back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default ManagePlanning;
