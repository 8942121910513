import { useState, ReactElement, useEffect } from "react";
import LabelField from "components/common/atoms/LabelField";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { useFormContext } from "components/CandidateCreation/context/Context";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import ModalPopup from "components/common/atoms/ModalPopup";
import { Option } from "components/common/utlis/TypeAnnotations";
import Navigation from "../formNavigation/Navigation";
import { ApiCall } from "components/common/services/ApiServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomNotify from "components/common/atoms/CustomNotify";
import { VALIDATION_FOR_CANDIDATE_FIELDS } from "routes/ApiEndpoints";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import Calender from "components/common/molecules/Calender";
import Button from "components/common/atoms/Button";
import InternalInfoModal from "./tabContentComponents/InternalInfoModal";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";
import ScreeningAndMatchingModalPopup from "./tabContentComponents/ScreeningAndMatchingModalPopup";
import { InputGroupText } from "components/common/atoms/InputGroupText";
import { useSSNorEmailValidation } from "./tabContentComponents/SSNOrEmailValidation";

const InternalInfoTab: React.FC = () => {
  const { state, dispatch, isViewMode } = useFormContext();
  const navigate = useNavigate();
  const { candidateId } = useParams<{ candidateId: string }>();
  const location = useLocation();
  const type = location.state?.origin === "manageUsers" ? "user" : "candidate";

  const destination = new URLSearchParams(location.search).get('destination') || "/manage-candidates";

  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  //for infomodal
  const [isInternalInfoModalOpen, setInternalInfoModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  //for screening and pop-up modal
  const [showModal, setShowModal] = useState(false);

  const [modalBody, setModalBody] = useState<ReactElement | null>(null);

  //SSN and email validation pop up

  ////******** SSN OR EMAIL VALIDATION ****
  const {
    canId,
    SSNorEmailModal,
    modalTitle,
    emailOrSSNModalBody,
    closeModal,
    handleCandidate,
    checkForSSNorEmailExistence,
  } = useSSNorEmailValidation();
  /* */

  const handleModalClose = () => {
    setShowModal(!showModal);
    navigate("/manage-candidates");
  };

  const handleScreeningandMatchigModalClose = () => {
    setShowModal(!showModal);
    if (state.general.payRoll !== 1) {
      navigate(`/matching-list/candidate/${candId}`);
    }
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
  };

  const [roleType, setRoleType] = useState(type);
  const [msg, setMsg] = useState<string>("");
  const [candId, setCandId] = useState<number | null>(null);
  const handleMatching = () => { };

  const [loading, setLoading] = useState(false);
  const userAuth = useSelector(selectAuth);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDateChange = (date: Date | null, name: string) => {
    const updatedDate = date !== null ? date : new Date();

    dispatch({
      type: "UPDATE_GENERAL_FIELD",
      field: name,
      value: updatedDate,
    });
  };

  const openInternalInfoModal = (type: string) => {
    setInternalInfoModalOpen(true);
    setModalType(type);
  };

  const closeInternalInfoModal = () => {
    setInternalInfoModalOpen(false);
  };

  const resetFields = () => {
    const fields = [
      { field: "reasonForBlocking", value: "" },
      { field: "blockedBy", value: null },
      { field: "infoBlocking", value: "" },
    ];

    fields.forEach((field) => {
      dispatch({
        type: "UPDATE_INTERNAL_INFO_FIELD",
        field: field.field,
        value: field.value,
      });
    });
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (isViewMode) return;

    const { name, value, type, checked } = event.target as HTMLInputElement;
    let updatedValue: string | number | null | Option;
    if (type === "radio") {
      updatedValue = parseInt(value);
    } else if (type === "checkbox") {
      updatedValue = checked ? 1 : 0;
      if (name === "blocked" && updatedValue === 0) {
        resetFields();
      }
    } else {
      updatedValue = value;
    }
    dispatch({
      type: "UPDATE_INTERNAL_INFO_FIELD",
      field: name,
      value: updatedValue,
    });
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: "UPDATE_INTERNAL_INFO_FIELD",
      field: name,
      value: selectedOption,
    });
  };

  // common functions
  const dispatchAndNotifySingleField = (
    fieldName: string,
    errorMessage: string
  ) => {
    dispatch({
      type: "UPDATE_FIELD_ERROR",
      fieldName: fieldName,
      error: t(errorMessage),
    });
    CustomNotify({ type: "error", message: t(errorMessage) });
  };

  const dispatchAndNotifyMultipleFields = (
    fieldA: string,
    fieldB: string,
    fieldAErrMsg: string,
    fieldBErrMsg: string,
    commonMessage: string
  ) => {
    dispatch({
      type: "UPDATE_FIELD_ERROR",
      fieldName: fieldA,
      error: t(fieldAErrMsg),
    });
    dispatch({
      type: "UPDATE_FIELD_ERROR",
      fieldName: fieldB,
      error: t(fieldBErrMsg),
    });
    CustomNotify({ type: "error", message: t(commonMessage) });
  };

   // Check if there is a companyId in the URL
   const urlParams = new URLSearchParams(window.location.search);
   const applicationId = urlParams.get("applicationId");
   const appStatus = urlParams.get("appStatus");
 
   const applicationData = {
     applicationId: applicationId ? applicationId : null,
     appStatus: appStatus ? appStatus : null,
   };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (validationStatus.isValid) {
      if (!candidateId) {
        const ssn = state.general.ssn.replace(/[.-]/g, "");
        const email = state.general.email;
        checkForSSNorEmailExistence(email, ssn);
        if (emailOrSSNModalBody !== "") {
          return;
        }
      }
      const idmData = {
        ...state.general,
        profilePicId: state.profileCard.picFileId,
        profilePicName: state.profileCard.picFileName,
        profilePicPath: state.profileCard.picFilePath,
        ...(candidateId ? { id: state.general.userId } : {}),
        ...(candidateId ? { userStatus: state.general.candidateStatus } : {}),
        formStatus: 1,
      };

      let validationStatus;
      let validationApiCall;
      if (state.general.ssn === "" && state.general.iban === "") {
        validationStatus = 200;
      } else {
        const validationFields = {
          type: type,
          ssn: state.general.ssn.replace(/[.-]/g, ""),
          iban: state.general.iban,
        };
        const validationUrl = !candidateId
          ? `${VALIDATION_FOR_CANDIDATE_FIELDS}`
          : `${VALIDATION_FOR_CANDIDATE_FIELDS}/${candidateId}`;
        validationApiCall = await ApiCall.service(
          validationUrl,
          "POST",
          validationFields,
          true,
          "candidate-creation"
        );
        validationStatus = validationApiCall.status;
      }

      if (validationStatus === 200) {
        const idmUrl = !candidateId ? `registration` : `updateCandidates`;
        const getUserId = await ApiCall.service(
          idmUrl,
          "POST",
          idmData,
          true,
          "identity-manager"
        );
        if (getUserId.status === 200) {
          const updatedFormData = {
            type: type,
            profileCard: state.profileCard,
            general: state.general,
            emergencyContacts: state.emergencyContact,
            other: state.other,
            jobProfile: state.jobProfile,
            workExperience: state.workExperience,
            studies: state.studies,
            competences: state.competences,
            certificates: state.certificates,
            workPermitDetails: state.workPermit,
            checklistDocDetails: state.documentChecklist,
            internalInfo: state.internalInfo,
            ...(candidateId
              ? { personId: state.general.userId }
              : { personId: getUserId.userId }),
            candidateStatus: state.general.candidateStatus,
            sourceId: state.general.sourceId,
            formStatus: 1,
            submitType:
              userAuth.role === "CANDIDATE" || userAuth.role === "EMPLOYER"
                ? "draft"
                : "submit",
            src: "internalRegister",
            applicationData: applicationData,
          };

          const url = !candidateId
            ? `registerCandidate`
            : `registerCandidate/${candidateId}`;
          const response = await ApiCall.service(
            url,
            "POST",
            updatedFormData,
            true,
            "candidate-creation"
          );
          if (response.status === 200) {
            const candidateID = response.candidateId;
            setLoading(false);
            const msg =
              state.general.formStatus === 1
                ? "Candidate has been updated successfully"
                : "Candidate has been created successfully";
            if (userAuth.role === "CANDIDATE" || userAuth.role === "EMPLOYER") {
              CustomNotify({ type: "success", message: msg });
              navigate(`/dashboard`);
            } else if (state.general.formStatus !== 1) {
              setShowModal(true);
              setCandId(candidateID);
            } else {
              CustomNotify({ type: "success", message: msg });
              navigate(destination);
            }
            setMsg(msg);

            // Todo creation and updation
            const data = {
              candidateId: candidateID,
              stage: "New",
              source: 2,
            };
            const createTodoResponse = await ApiCall.service(
              "createTodo",
              "POST",
              data,
              false,
              CENTRAL_DATA_MANAGEMENT_MICROSERVICE
            );
            if (createTodoResponse.status === 200) {
              const data = {
                todoId: createTodoResponse.todoId,
                userId: userAuth.userId,
                stageId: 1,
                actionType: "pickUpTodo",
              };
              const assignTodoResponse = await ApiCall.service(
                "assignTodo",
                "POST",
                data,
                false,
                CENTRAL_DATA_MANAGEMENT_MICROSERVICE
              );
              if (assignTodoResponse.status === 200) {
                const data = {
                  candidateId: candidateID,
                  stage: "Screening",
                  submitType: "accept",
                  isConsent: state.general.consentToTerms
                };
                const updateTodoResponse = await ApiCall.service(
                  "updateTodo",
                  "POST",
                  data,
                  false,
                  CENTRAL_DATA_MANAGEMENT_MICROSERVICE
                );
              }
            }
          } else if (response.status === 400) {
            if (response.exception && response.exception.includes("ssnumber")) {
              dispatchAndNotifySingleField("ssn", response.exception);
              dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
            } else {
              CustomNotify({
                type: "error",
                message: response.exception,
              });
            }
          }
        } else if (getUserId.status === 400) {
          dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
          if (getUserId.errors.email && getUserId.errors.phone_number) {
            dispatchAndNotifyMultipleFields(
              "email",
              "mobNumber",
              getUserId.errors.email,
              getUserId.errors.phone_number,
              "Email and Phone number already exists"
            );
          } else if (getUserId.errors.email) {
            dispatchAndNotifySingleField("email", "Email already exists");
          } else if (getUserId.errors.phone_number) {
            dispatchAndNotifySingleField(
              "mobNumber",
              "Phone number already exists"
            );
          }
        }
      } else if (validationApiCall.status === 400) {
        dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
        if (validationApiCall.errors.ssn && validationApiCall.errors.iban) {
          dispatchAndNotifyMultipleFields(
            "ssn",
            "iban",
            validationApiCall.errors.ssn,
            validationApiCall.errors.iban,
            "SSN number and IBAN already exist"
          );
        } else if (validationApiCall.errors.ssn) {
          dispatchAndNotifySingleField("ssn", validationApiCall.errors.ssn);
        } else if (validationApiCall.errors.iban) {
          dispatchAndNotifySingleField("iban", validationApiCall.errors.iban);
        }
      }
    } else {
      if (windowWidth <= 1199) {
        setShowModal(true);
        setModalBody(
          <div className="d-block d-xl-none mb-5 px-4 text-danger text-center">
            <div className="mb-3">
              {t("Please fill all the following mandatory fields under ")}
            </div>
            {state.tabDetails.map(
              (item: any, index) =>
                item.error && (
                  <div className="mb-2" key={index}>
                    <strong>{item.title}</strong>
                  </div>
                )
            )}
          </div>
        );
      } else {
        setShowModal(false);
        setModalBody(null); // Reset modal body when not visible
      }
    }
    setLoading(false);
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div
          className={`form-border ${isViewMode ? "form disableform " : "form"}`}
          style={{ paddingTop: "3.4vw" }}
        >
          <div className="col-md-12">
            <CheckBoxField
              label={t("Active")}
              name="active"
              onChangeHandler={handleFieldChange}
              isChecked={state.internalInfo.active === 1}
              id="active"
              lineHeight="1.1vw"
            />
          </div>
          <div className="row" style={{ marginTop: "1vw" }}>
            <div className="col-lg-4">
              <SelectWithSearch
                title={t("Inflow channel")}
                search={true}
                options={state.dropDowndata.inflowChannelList}
                onChange={(e) => handleSelectChange(e, "inflowChannel")}
                isMulti={false}
                name="inflowChannel"
                value={state.internalInfo.inflowChannel}
                isTranslate={true}
              />
            </div>
            <div className="col-lg-4">
              <LabelWithInputField
                isMandatory={false}
                name="externalReference"
                handleChange={handleFieldChange}
                value={state.internalInfo.externalReference}
                isDisabled={false}
                id="externalReference"
                label={t("External reference")}
                type="text"
                placeholder={t("External reference")}
              />
            </div>
            <div className="col-lg-4">
              <SelectWithSearch
                title={t("Selection partner")}
                isMandatory={false}
                search={true}
                options={state.dropDowndata.selectionPartnerList}
                onChange={(e) => handleSelectChange(e, "selectionPartner")}
                isMulti={false}
                name="selectionPartner"
                value={state.internalInfo.selectionPartner}
                isTranslate={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 mb-3 hotlistWrapper">
              <CheckBoxField
                label={t("Blocked")}
                name="blocked"
                onChangeHandler={handleFieldChange}
                isChecked={state.internalInfo.blocked === 1}
                className="blocked-checkbox"
                id="blocked"
                lineHeight="1.1vw"
              />
            </div>
            <div className="col-lg-4">
              <LabelWithInputField
                isMandatory={false}
                name="reasonForBlocking"
                handleChange={handleFieldChange}
                value={state.internalInfo.reasonForBlocking}
                isDisabled={state.internalInfo.blocked === 0}
                id="reasonForBlocking"
                label={t("Reason for blocking")}
                type="text"
                placeholder={t("Reason for blocking")}
              />
            </div>
            <div className="col-lg-4">
              <SelectWithSearch
                title={t("Blocked by")}
                isMandatory={false}
                search={true}
                isDisabled={state.internalInfo.blocked === 0}
                options={state.dropDowndata.blockedByPersonList}
                onChange={(e) => handleSelectChange(e, "blockedBy")}
                isMulti={false}
                name="blockedBy"
                value={state.internalInfo.blockedBy}
              />
            </div>
            <div className="col-lg-6">
              <LabelWithTextAreaField
                name="infoBlocking"
                handleChange={handleFieldChange}
                label={t("Additional info blocking ")}
                isDisabled={state.internalInfo.blocked === 0}
                isMandatory={false}
                value={state.internalInfo.infoBlocking}
              />
            </div>

            <div className="col-lg-6">
              <LabelWithTextAreaField
                name="internalInfo"
                handleChange={handleFieldChange}
                label={t("Internal info")}
                isMandatory={false}
                value={state.internalInfo.internalInfo}
              />
            </div>
          </div>
          <LabelField title={"@" + t("Work")} className="tab-subtitle " />
          <div className="col-md-12 d-flex align-items-center mb-3">
            <LabelField
              title={t("Student") + "@" + t("Work") + "2024"}
              className="tab-subtitle pb-0"
            />

            <Button
              title={
                state.internalInfo.studentCertificate !== null &&
                  state.internalInfo.studentRemainingQuota !== ""
                  ? t("Modify")
                  : t("Add")
              }
              type="submit"
              handleClick={() => openInternalInfoModal("student")}
              className="form-button shadow-none marginLeft1"
            />
          </div>
          <div className="row">
            <div className="col-lg-4 position-relative">
              <Calender
                onChange={handleDateChange}
                selectedDate={state.internalInfo.studentCertificate}
                label={t("Date attestation")}
                isMandatory={false}
                isDisabled={true}
                name=""
              />
            </div>
            <div className="col-lg-4 remaining-section">
              <InputGroupText
                name=""
                handleChange={handleFieldChange}
                value={state.internalInfo.studentRemainingQuota}
                type="text"
                isDisabled={true}
                label={t("Remaining quota")}
                isSuffix={t("hours")}
              />
            </div>
            <div className="col-lg-4 remaining-section">
              <InputGroupText
                name=""
                handleChange={handleFieldChange}
                value={state.internalInfo.studentRemainingQuota}
                type="text"
                isDisabled={true}
                label={t("Remaining balance")}
                isSuffix={t("hours")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex align-items-center mb-3">
              <LabelField
                title={t("Green") + "@" + t("Work") + "2024"}
                className="tab-subtitle pb-0"
              />
              <Button
                title={
                  state.internalInfo.greenCertificate !== null &&
                    state.internalInfo.greenRemainingQuota !== ""
                    ? t("Modify")
                    : t("Add")
                }
                type="submit"
                handleClick={() => openInternalInfoModal("green")}
                className="form-button shadow-none marginLeft1"
              />
            </div>
            <div className="col-lg-4 position-relative">
              <Calender
                onChange={handleDateChange}
                selectedDate={state.internalInfo.greenCertificate}
                label={t("Date attestation")}
                isMandatory={false}
                name=""
                isDisabled={true}
              />
            </div>
            <div className="col-lg-4 remaining-section">
              <InputGroupText
                name=""
                handleChange={handleFieldChange}
                value={state.internalInfo.greenRemainingQuota}
                isDisabled={true}
                type="text"
                label={t("Remaining quota")}
                isSuffix={t("days")}
              />
            </div>
            <div className="col-lg-4 remaining-section">
              <InputGroupText
                name=""
                handleChange={handleFieldChange}
                value={state.internalInfo.greenRemainingQuota}
                isDisabled={true}
                type="text"
                label={t("Remaining balance")}
                isSuffix={t("days")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex align-items-center">
              <LabelField
                title={t("Horeca") + "@" + t("Work") + "2024"}
                className="tab-subtitle pb-0"
              />
              <Button
                title={
                  state.internalInfo.horecaCertificate !== null &&
                    state.internalInfo.horecaRemainingQuota !== ""
                    ? t("Modify")
                    : t("Add")
                }
                type="submit"
                handleClick={() => openInternalInfoModal("horeca")}
                className="form-button shadow-none marginLeft1"
              />
            </div>
            <div className="col-sm-12 col-xxl-4 col-md-6 position-relative">
              <Calender
                onChange={handleDateChange}
                selectedDate={state.internalInfo.horecaCertificate}
                label={t("Date attestation")}
                isMandatory={false}
                name=""
                isDisabled={true}
              />
            </div>
            <div className="col-xxl-4 col-md-6 remaining-section">
              <InputGroupText
                name=""
                handleChange={handleFieldChange}
                value={state.internalInfo.horecaRemainingQuota}
                type="text"
                isDisabled={true}
                label={t("Remaining quota")}
                isSuffix={t("days")}
              />
            </div>
            <div className="col-xxl-4 col-md-6 remaining-section">
              <InputGroupText
                name=""
                handleChange={handleFieldChange}
                value={state.internalInfo.horecaRemainingQuota}
                type="text"
                isDisabled={true}
                label={t("Remaining balance")}
                isSuffix={t("days")}
              />
            </div>
          </div>
          <InternalInfoModal
            show={isInternalInfoModalOpen}
            onHide={closeInternalInfoModal}
            type={modalType}
            title={t(`${modalType}`) + "@" + t("Work employee")}
            className="internalInfoModal  "
          />
          <div className="mb-3 mt-4 mt-xxl-0">
            {t(
              "Any form of discrimination based on sex, perceived race, colour, descent, nationality or ethnic origin, sexual orientation, marital status, birth, property, age, religious or philosophical beliefs, current or future state of health, disability or physical characteristic is prohibited."
            )}
          </div>
        </div>
        <Navigation validStatus={validStatus} isLoading={loading} />
        {!validationStatus.isValid ? (
          <ModalPopup
            show={showModal}
            onHide={handleModalClose}
            title=""
            body={modalBody}
            onCloseButtonClick={handleModalClose}
            onConfirmButtonClick={handleSaveChanges}
            className="modal-lg"
          />
        ) : (
          <ScreeningAndMatchingModalPopup
            show={showModal}
            onHide={handleScreeningandMatchigModalClose}
            title=""
            className="modal-lg candidateSuccessPopup"
            candidateId={candId}
            msg={msg}
            roleType={roleType}
            onMatching={handleMatching}
            payRoll={state.general.payRoll}
          />
        )}
        <ModalPopup
          size="lg"
          show={SSNorEmailModal}
          onHide={closeModal}
          title={modalTitle}
          body={emailOrSSNModalBody}
          className="modal-lg"
          onConfirmButtonClick={handleCandidate}
          confirmTitle={canId ? t("Yes") : false}
        />
      </form>
    </>
  );
};
export default translate(InternalInfoTab);
