import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import {
  ADMIN_FICHE_PREVIEW_BACKEND,
  MANAGE_ADMIN_FICHE,
  MANAGE_COOPERATION_AGREEMENT,
} from "routes/RouteConstants";
import * as ENDPOINTS from "../../../../routes/ApiEndpoints";
import { COMPANY_MICROSERVICE } from "Constants";
import { ApiCall } from "components/common/services/ApiServices";
import EncryptDecryptService from "components/common/services/EncryptDecryptService";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  validateEmail,
  validateFileId,
  validateForm,
  validatePassword,
  validateRequired,
  validateSelectField,
} from "components/common/services/ValidationService";
import AuthenticationModal from "components/common/utlis/AuthenticationModal";
import ActionButtonsSection from "./ActionButtonsSection";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";
import CustomNotify from "components/common/atoms/CustomNotify";
import { t } from "components/CentralDataMangement/translation/Translation";
import SignAsEmployerModal from "components/common/utlis/SignAsEmployerModal";
import ModalPopup from "components/common/atoms/ModalPopup";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import Button from "components/common/atoms/Button";
import GetQueryParams from "components/common/services/GetQueryParams";
import FileUpload from "components/common/molecules/FileUpload";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";

interface Preview {
  iframeUrl: string;
  isTermsAccepted: boolean;
  email: string;
  password: string;
  isAuthenticated: boolean;
  // signAsEmployerPopup: boolean;
  employerId: number | null;
  selectedContact: null;
  addSignature: boolean;
  contacts: any[];
}

const AdminFichePreview: React.FC = () => {
  const { agreementId, adminFicheId } = useParams<{
    agreementId: string;
    adminFicheId: string;
  }>();
  const queryParams = GetQueryParams();
  const companyId = queryParams.get("companyId") ?? null;
  const feeBased = queryParams.get("coeff_type");

  const userAuth = useSelector(selectAuth);
  const navigate = useNavigate();
  const isEmployer = userAuth.role === "EMPLOYER";
  const langKey = secureLocalStorage.getItem("site_lang") ?? "en";

  const initialState: Preview = {
    iframeUrl: `${process.env.REACT_APP_COMPANY_SERVICE}/${ADMIN_FICHE_PREVIEW_BACKEND}/${agreementId}/${adminFicheId}?lang=${langKey}&employer=${isEmployer ? userAuth.userId : ''}`,
    isTermsAccepted: false,
    email: isEmployer ? userAuth.email : "",
    password: "",
    isAuthenticated: isEmployer ? true : false,
    // signAsEmployerPopup: false,
    employerId: isEmployer ? userAuth.userId : null,
    contacts: [],
    selectedContact: null,
    addSignature: false,
  };

  const [state, setState] = useState<Preview>(initialState);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [signAsEmployer, setSignAsEmployer] = useState(false);
  const [sendToEmployer, setSendToEmployer] = useState(false);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    if (event.target.type === "checkbox" && !checked) {
      setState(initialState);
    }
    setState((prevState) => ({
      ...prevState,
      [name]: event.target.type === "checkbox" ? checked : value,
    }));
  };

  // const signAsEmployer = () => {
  //   setState((prevData) => ({
  //     ...prevData,
  //     signAsEmployerPopup: true,
  //   }));
  // };

  // const handleModalClose = (type: string) => {
  //   setState((prevData) => ({
  //     ...prevData,
  //     signAsEmployerPopup: false,
  //   }));
  // };

  const validation = (
    name: string,
    value: string | boolean | number | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      email: name === "contacts" ? [] : [validateRequired, validateEmail],
      password: name === "contacts" ? [] : [validateRequired, validatePassword],
      contacts: name === "contacts" ? [validateSelectField] : [],
    };
    const validationErrors = validateForm(
      { ...state, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const checkAuthentication = async (event: React.FormEvent) => {
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;
    const formData = {
      email: state.email,
      password: EncryptDecryptService.encryptData(
        JSON.stringify(state.password)
      ),
      agreementId: agreementId,
      adminFicheId: adminFicheId,
      companyId: companyId,
    };
    if (validation(name, value)) {
      const response = await ApiCall.service(
        ENDPOINTS.CHECK_AUTHENTICATION,
        "POST",
        formData,
        true,
        COMPANY_MICROSERVICE
      );

      if (response.status === 200) {
        setState((prevData) => ({
          ...prevData,
          iframeUrl: `${process.env.REACT_APP_COMPANY_SERVICE}/${ADMIN_FICHE_PREVIEW_BACKEND}/${agreementId}/${adminFicheId}?employer=${response.msg}&verify=1&lang=${langKey}`,
          isAuthenticated: true,
          // signAsEmployerPopup: false,
          employerId: response.msg,
        }));
        setErrors({
          email: "",
        });
        setSignAsEmployer(!signAsEmployer);
      } else {
        setErrors({
          email: response.message,
        });
      }
    }
  };

  const handleSubmit = async (sendToEmployer: boolean) => {
    if (sendToEmployer && !validation("contacts", state.selectedContact)) {
      return;
    }
    const formData = {
      sendToEmployer: sendToEmployer,
      agreementId: agreementId,
      adminFicheId: adminFicheId,
      loggedInUserId: userAuth.userId,
      langKey: langKey,
      employerId: state.employerId,
      email: state.email,
      companyId: companyId
    };
    const response = await ApiCall.service(
      ENDPOINTS.APPROVE_ADMIN_FICHE,
      "POST",
      formData,
      true,
      COMPANY_MICROSERVICE
    );

    if (response.status === 200) {
      CustomNotify({
        type: "success",
        message: t("Mail sent to employer successfully"),
      });
      navigate(MANAGE_ADMIN_FICHE);
    } else {
      CustomNotify({
        type: "error",
        message: response.message,
      });
    }
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (selectedOption?.value !== "" || selectedOption?.value !== null) {
      setState((prevData) => ({
        ...prevData,
        selectedContact: selectedOption,
        // employerSign: selectedOption?.signature_exists,
        // signAsEmployerPopup: data !== null,
        email: selectedOption?.signature_exists && selectedOption?.email,
        password: "",
        employerId: selectedOption?.value,
        iframeUrl: `${process.env.REACT_APP_COMPANY_SERVICE}/${ADMIN_FICHE_PREVIEW_BACKEND}/${agreementId}/${adminFicheId}?employer=${selectedOption?.value}&verify=0&lang=${langKey}`,
      }));
    }
    validation(fieldName, selectedOption);
  };

  const employerModal = () => {
    setSignAsEmployer(!signAsEmployer);
    setState((prevState) => ({
      ...prevState,
      selectedContact: null,
    }));
  };

  const sendToEmployerModal = () => {
    setSendToEmployer(!sendToEmployer);
    setState((prevState) => ({
      ...prevState,
      selectedContact: null,
    }));
  };

  const handleSendToBright = async () => {
    const data = {
      type: "toBright",
      adminFicheId,
      agreementId,
      companyId,
      langKey: langKey,
    };
    const response = await ApiCall.service(
      ENDPOINTS.SEND_DATA_TO_BRIGHT,
      "POST",
      data,
      true,
      COMPANY_MICROSERVICE
    );

    if (response.status === 200) {
      CustomNotify({ type: "success", message: t(response.msg) });
      navigate(MANAGE_ADMIN_FICHE);
    }
  };

  //File Upload section
  const [isDocumentModalOpen, setDocumentModalOpen] = useState(false);
  const [documentData, setDocumentData] = useState({
    documentName: "",
    documentCategory: { value: 50, label: "Adminfiche" },
    documentId: null,
    fileName: "",
    filePath: "",
    extension: "",
    selectedContact: null,
  });
  const fileUploadStyleObject: React.CSSProperties = {
    width: "100%",
  };
  const initialDocumentErrors: { [key: string]: string } = {};
  const [documentErrors, setDocumentErrors] = useState<{
    [key: string]: string;
  }>(initialDocumentErrors);
  const documentValidation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      documentName: [validateRequired],
      documentCategory: [validateSelectField],
      documentId: [validateFileId],
      selectedContact: [validateSelectField],
    };
    const validationErrors = validateForm(
      { ...documentData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setDocumentErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setDocumentErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const closeDocumentModal = () => {
    setDocumentModalOpen(false);
    resetDocumentFields();
    resetDocumentErrors();
  };

  const handleDocumentFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    let updatedValue: string | number | null = value;
    setDocumentData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }));
    documentValidation(name, value, true);
  };

  const handleDocumentSelectChange = (
    selectedOption: any,
    fieldName: string
  ) => {
    setDocumentData((prevState) => ({
      ...prevState,
      [fieldName]: selectedOption,
    }));
    documentValidation(fieldName, selectedOption, true);
  };

  const documentUploadResponse = (responseObj: any) => {
    const response = responseObj["response"];
    const fieldNames = ["documentId", "fileName", "filePath"];
    fieldNames.forEach((fieldName: string, key: number) => {
      setDocumentData((prevData) => ({
        ...prevData,
        [fieldName]: response[key],
      }));
    });
    // Extract file name and extension
    const fileNameWithExtension = response[1];
    const lastDotIndex = fileNameWithExtension.lastIndexOf(".");
    const fileName =
      lastDotIndex !== -1
        ? fileNameWithExtension.substring(0, lastDotIndex)
        : fileNameWithExtension;
    const extension =
      lastDotIndex !== -1
        ? fileNameWithExtension.substring(lastDotIndex + 1)
        : "";
    setDocumentData((prevState) => ({
      ...prevState,
      extension: extension,
      documentName: fileName,
    }));
  };

  const documentDeleteResponse = (field: string) => {
    const fieldNames = ["documentId", "fileName", "filePath"];
    fieldNames.forEach((fieldName: string, key: number) => {
      setDocumentData((prevData) => ({
        ...prevData,
        [fieldName]: fieldName.endsWith("Id") ? null : "",
      }));
      setDocumentData((prevData) => ({
        ...prevData,
        documentName: "",
        extension: "",
      }));
    });
  };

  const documentUploadErrors = (errorResposne: any) => {
    setDocumentErrors({
      documentId: errorResposne.error,
    });
  };

  const handleUpload = () => {
    setDocumentModalOpen(true);
  };

  const resetDocumentErrors = () => {
    setDocumentErrors({
      documentName: "",
      documentCategory: "",
      documentId: "",
    });
  };

  const resetDocumentFields = () => {
    setDocumentData({
      documentName: "",
      documentCategory: { value: 16, label: "Cooperation Agreement" },
      documentId: null,
      fileName: "",
      filePath: "",
      extension: "",
      selectedContact: null,
    });
  };

  const saveFileAndNotifyAM = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    const updatedDocumentData = {
      ...documentData,
      uploadedBy: userAuth.name,
      createdAt: new Date(),
      companyId,
      adminFicheId,
      agreementId,
      approved: false,
      loggedInUserId: userAuth.userId,
      type: "file",
      langKey: langKey,
    };
    if (documentValidation(name, value)) {
      const response = await ApiCall.service(
        ENDPOINTS.SEND_DATA_TO_BRIGHT,
        "POST",
        updatedDocumentData,
        true,
        COMPANY_MICROSERVICE
      );

      if (response.status === 200) {
        CustomNotify({ type: "success", message: t(response.msg) });
        navigate(MANAGE_ADMIN_FICHE);
      }
      setDocumentModalOpen(false); // Close modal
      resetDocumentFields();
      resetDocumentErrors();
    }
  };

  return (
    <div>
      <iframe
        className="iframeHeight"
        width="100%"
        src={state.iframeUrl}
        title="Embedded Content"
        allowFullScreen
      ></iframe>

      <ActionButtonsSection
        isAuthenticated={state.isAuthenticated}
        isTermsAccepted={state.isTermsAccepted}
        changeHandler={changeHandler}
        employerModal={employerModal}
        handleSubmit={handleSubmit}
        sendToEmployerModal={sendToEmployerModal}
        agreementId={agreementId || ""}
        adminFicheId={adminFicheId || ""}
        langKey={langKey}
        isEmployer={isEmployer}
        isFeeBased={feeBased}
        handleSendToBright={handleSendToBright}
        handleUpload={handleUpload}
      />

      {/* <AuthenticationModal
        show={state.signAsEmployerPopup}
        handleModalClose={handleModalClose}
        checkAuthentication={checkAuthentication}
        state={state}
        errors={errors}
        changeHandler={changeHandler}
      /> */}
      <ModalPopup
        show={sendToEmployer}
        onHide={sendToEmployerModal}
        title={t("Send to employer")}
        body={
          <>
            <SelectWithSearch
              search={true}
              options={state.contacts}
              value={state.selectedContact}
              onChange={(e) => handleSelectChange(e, "contacts")}
              name="contacts"
              title="Contacts"
              error={errors.contacts}
              isMandatory={true}
            />
            <Button
              type="submit"
              title={t("Send")}
              handleClick={() => handleSubmit(true)}
              className="float-end form-button shadow-none marginRightPoint5"
            />
          </>
        }
        className="modal-lg"
      />

      <ModalPopup
        size="md"
        show={isDocumentModalOpen}
        onHide={closeDocumentModal}
        title={t("Document")}
        headerClassName={`col-12 mx-auto`}
        body={
          <>
            <div className="row">
              <div className="col-sm-12 col-md-6 upload-certificate">
                <SelectWithSearch
                  search={true}
                  options={state.contacts}
                  value={documentData.selectedContact}
                  onChange={(e) =>
                    handleDocumentSelectChange(e, "selectedContact")
                  }
                  name="selectedContact"
                  title="Contacts"
                  isMandatory={true}
                  error={documentErrors.selectedContact}
                />
              </div>
              <div className="col-sm-12 col-md-6 upload-certificate">
                <FileUpload
                  id="documentId"
                  name="documentId"
                  label={t("File upload")}
                  isMandatory={true}
                  edit={false}
                  fileId={documentData.documentId ?? null}
                  fileName={documentData.fileName ?? ""}
                  filePath={documentData.filePath ?? ""}
                  style={fileUploadStyleObject}
                  multiple={false}
                  uploadPath={"company-adminFiche"}
                  formats=".pdf"
                  returnFileUploadErrors={(errorResponse: any) =>
                    documentUploadErrors(errorResponse)
                  }
                  fileUploadResponse={(uploadResposne: any) => {
                    documentUploadResponse(uploadResposne);
                  }}
                  deleteResponse={(field: any) => documentDeleteResponse(field)}
                  maxFileSize={10 * 1024 * 1024}
                  microserviceURL={`${process.env.REACT_APP_COMPANY_SERVICE}`}
                />
                <div
                  className="text-danger height-20"
                  style={{ marginBottom: "0.5vw" }}
                >
                  <span className="text-danger">
                    {documentErrors.documentId}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 d-flex align-items-center">
                <div style={{ width: documentData.extension ? "90%" : "100%" }}>
                  <LabelWithInputField
                    isMandatory={true}
                    name="documentName"
                    handleChange={(event) => handleDocumentFieldChange(event)}
                    value={documentData.documentName}
                    label={t("File name")}
                    placeholder={t("File name")}
                    type="text"
                    error={documentErrors.documentName}
                  />
                </div>
                {documentData.extension && (
                  <span
                    className="fw-bold"
                    style={{ marginLeft: "0.25vw", width: "10%" }}
                  >
                    {documentData.extension !== ""
                      ? `.${documentData.extension}`
                      : ""}
                  </span>
                )}
              </div>
              <div className="col-sm-12 col-md-6 upload-certificate">
                <SelectWithSearch
                  title={t("Category")}
                  isMandatory={true}
                  search={true}
                  options={[{ value: 50, label: "Adminfiche" }]}
                  onChange={(e) =>
                    handleDocumentSelectChange(e, "documentCategory")
                  }
                  isMulti={false}
                  isDisabled={true}
                  name="attestType"
                  value={{ value: 50, label: "Adminfiche" }}
                  error={documentErrors.documentCategory}
                  isTranslate={true}
                />
              </div>
            </div>
            <div className="col-12 mx-auto">
              <Button
                handleClick={saveFileAndNotifyAM}
                className="mx-0 shadow-none form-button float-end"
              >
                {t("Save")}
              </Button>
            </div>
          </>
        }
        className="modal-lg documentsCertificates"
      />

      <SignAsEmployerModal
        companyId={companyId}
        show={signAsEmployer}
        handleSelectChange={handleSelectChange}
        state={state}
        setState={setState}
        employerModal={employerModal}
        errors={errors}
        changeHandler={changeHandler}
        checkAuthentication={checkAuthentication}
      />
    </div>
  );
};

export default AdminFichePreview;
