import React from "react";
import { Link } from "react-router-dom";
import Button from "components/common/atoms/Button";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import { t } from "components/CentralDataMangement/translation/Translation";

interface ActionButtonsSectionProps {
  isAuthenticated: boolean;
  isTermsAccepted: boolean;
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  employerModal: () => void;
  sendToEmployerModal: () => void;
  handleSubmit: (sendToEmployer: boolean) => void;
  agreementId: string;
  adminFicheId: string;
  langKey: string | number | boolean | object;
  isEmployer: boolean;
  isFeeBased: string | null;
  handleSendToBright: () => void;
  handleUpload: () => void;
}

const ActionButtonsSection: React.FC<ActionButtonsSectionProps> = ({
  isAuthenticated,
  isTermsAccepted,
  changeHandler,
  employerModal,
  handleSubmit,
  sendToEmployerModal,
  agreementId,
  langKey,
  isEmployer,
  adminFicheId,
  isFeeBased,
  handleSendToBright,
  handleUpload,
}) => {
  return (
    <div className="px-4">
      <CheckBoxField
        name="isTermsAccepted"
        onChangeHandler={changeHandler}
        isChecked={isTermsAccepted}
        id="isTermsAccepted"
        className="cursor-pointer"
        label={t(
          "I hereby declare that I have reviewed the document and confirm that I agree with all details."
        )}
      />

      <div className="row py-4">
        <div className="col-md-2 align-self-center">
          <Link
            to={`/admin-fiche/create/${agreementId}/${adminFicheId}?tabId=wage`}
            className="back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>

        {isAuthenticated && isTermsAccepted && (
          <div>
            <Button
              type="submit"
              title={t("Approve")}
              handleClick={() => handleSubmit(false)}
              className="float-end form-button shadow-none marginRightPoint5"
            />
          </div>
        )}

        {!isAuthenticated && isTermsAccepted && !isEmployer && (
          <div className="col-10 align-self-center">
            <Button
              type="submit"
              title={t("Sign as employer")}
              handleClick={() => employerModal()}
              className="float-end form-button shadow-none"
            />
            <Button
              type="submit"
              title={t("Send to employer")}
              handleClick={() => sendToEmployerModal()}
              className="float-end form-button shadow-none marginRightPoint5"
            />
            {isFeeBased !== "1" && (
              <Button
                type="submit"
                title={t("Send to Bright")}
                handleClick={handleSendToBright}
                className="float-end form-button shadow-none marginRightPoint5"
              />
            )}
            <Link
              to={`${process.env.REACT_APP_COMPANY_SERVICE}/api/admin-fiche/download/${agreementId}/${adminFicheId}?lang=${langKey}`}
              className="float-end form-button shadow-none marginRightPoint5 d-flex align-items-center justify-content-center"
            >
              {t("Download")}
            </Link>
            <Button
              type="submit"
              title={t("Upload")}
              handleClick={handleUpload}
              className="float-end form-button shadow-none marginRightPoint5"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionButtonsSection;
