interface Props {
    validatedBy: string;
    validatedAt: string;
}


const ValidationDetails: React.FC<Props> = ({
    validatedBy,
    validatedAt
}) => {
    return <div>
        <strong>{`Validated By: `}</strong>{validatedBy}<br/>
        <strong>{`Validated At: `}</strong>{validatedAt}
    </div>
}

export default ValidationDetails;