import Title from "components/common/atoms/Title";
import { ApiCall } from "components/common/services/ApiServices";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import {
  MANAGE_COOPERATION_AGREEMENT,
  SEND_FOR_AM_APPROVAL,
} from "routes/ApiEndpoints";
import { COMPANY_MICROSERVICE } from "Constants";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import PdfIcon from "static/images/Pdf";
import "../css/Agreement.css";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import LinkIcon from "static/images/LinkIcon";
import SearchFields from "components/common/atoms/SearchFields";
import { createFilterConfig } from "./Filter";
import ResetBtn from "components/common/atoms/ResetBtn";
import { useDispatch } from "react-redux";
import {
  createQueryParams,
  filterAndMerge,
  formatDateAndTime,
  getQueryParamsFromRedux,
} from "components/common/utlis/HelperFunctions";
import { RootState } from "store";
import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import ActionIconWrapper from "components/common/atoms/ActionIconWrapper";
import { Option } from "components/common/services/ValidationService";
import GetQueryParams from "components/common/services/GetQueryParams";
import PaginationNew from "components/common/atoms/PaginationNew";
import Sorting from "components/common/atoms/Sorting";
import DocumentModal from "components/common/molecules/DocumentModal";
import CustomNotify from "components/common/atoms/CustomNotify";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import UserInfoTooltip from "components/common/atoms/UserInfoTooltip";
import NoRecords from "components/common/atoms/NoRecords";
import Approve from "static/images/Approve";

interface CoopertionProps {
  id: number;
  company_id: number;
  companyName: string;
  first_name: string;
  last_name: string;
  save_as_draft: number;
  file_path: string;
  approved: number;
  unitType: string;
  coeffType: number;
  sendToEmployer: number | null;
  sendForApproval: number | null;
  documentName: string;
  filePath: string;
  documentId: number | null;
  office: string;
  adminFicheId: number | null;
  created_by: string;
  updated_by: string;
  updated_at: Date | null;
  created_at: Date | null;
}

interface SearchProps {
  office: number[];
  company: string;
  unit: Option | null;
  sales: number[];
  status: Option | null;
  agreementType: number | null;
  orderByColumn: string;
  orderIn: "" | "asc" | "desc"; // Ensure correct typing here
  currentPage: number;
  recordsPerPage: number;
}

const ManageAgreement: React.FC = () => {
  const userAuth = useSelector(selectAuth);
  const dispatch = useDispatch();
  const [agreementData, setAgreementData] = useState<CoopertionProps[]>([]);
  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    totalRecords: 0,
  });

  const [loading, setLoading] = useState(false);
  const [dropdownList, setDropdownList] = useState({
    salesDropdown: [] as OptionProps[],
    statusDropdown: [] as OptionProps[],
    officeDropdown: [] as OptionProps[],
    unitDropdown: [] as OptionProps[],
    agreementTypeDropdown: [] as OptionProps[],
  });
  const filters = createFilterConfig(dropdownList);

  const destinationPath = useSelector(
    (state: RootState) => state.navigation.destinationPath
  );
  const getUrlParams = GetQueryParams();
  const paramFilter = getUrlParams.get("filter");
  const approvalFilter = getUrlParams.get("approval");
  let approvalFilterData = {};
  if (approvalFilter) {
    dispatch(clearDestinationPath());
    const unit = {
      value: Number(getUrlParams.get("unit_value")), // Ensure it's a number
      label: getUrlParams.get("unit_label"),
    };

    approvalFilterData = {
      company: getUrlParams.get("company"),
      unit: unit, // Keep unit as an object
    };
  }
  const queryParams = getQueryParamsFromRedux(destinationPath, paramFilter);

  const defaultSearchData: SearchProps = {
    office: [],
    company: "",
    unit: null,
    sales: [],
    status: null,
    agreementType: null,
    orderByColumn: "",
    orderIn: "",
    currentPage: 1,
    recordsPerPage: 10,
  };

  const filteredSearchData = filterAndMerge(defaultSearchData, queryParams);

  const [searchData, setSearchData] = useState<SearchProps>({
    ...defaultSearchData,
    ...(approvalFilter ? approvalFilterData : filteredSearchData),
  });

  //file upload
  const [isDocumentModalOpen, setDocumentModalOpen] = useState(false);
  const [documentData, setDocumentData] = useState({});
  //file upload ends
  const fetchDetails = async (filterData: any) => {
    setLoading(true);
    const data = {
      search: filterData,
      role: userAuth.role,
    };

    const response = await ApiCall.service(
      MANAGE_COOPERATION_AGREEMENT,
      "POST",
      data,
      false,
      COMPANY_MICROSERVICE
    );
    if (response.status === 200) {
      const { coopData, paginationData, dropdownData } = response.data;
      const usersList = dropdownData.salesDropdown.map((item: any) => ({
        value: item.userId,
        label: `${item.first_name} ${item.last_name}`,
      }));

      setDropdownList((prevData) => ({
        ...prevData,
        ...dropdownData,
        salesDropdown: usersList,
      }));
      setAgreementData(coopData);
      setPaginationData(paginationData);
    }
    setLoading(false);
  };

  useEffect(() => {
    const filterData = { ...searchData };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchDetails(filterData);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  //** change handlers */
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [name]: value,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: pageNumber,
    }));
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [fieldName]: selectedOption,
    }));
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setSearchData((prev) => ({
      ...prev,
      recordsPerPage: newItemsPerPage,
      currentPage: 1,
    }));
  };

  const handleReset = async (e: React.FormEvent) => {
    dispatch(clearDestinationPath());
    setSearchData(defaultSearchData);
    fetchDetails({
      ...defaultSearchData,
    });
  };

  const handleOrderByColumn = (column: string) => {
    let newOrder: "" | "asc" | "desc" = ""; // Explicit type declaration
    let newColumn = column;

    if (searchData.orderByColumn === column) {
      // Cycle through the three possible order values: '', 'asc', 'desc'
      if (searchData.orderIn === "") {
        newOrder = "asc"; // From '' to 'asc'
      } else if (searchData.orderIn === "asc") {
        newOrder = "desc"; // From 'asc' to 'desc'
      } else {
        newOrder = ""; // From 'desc' to ''
        newColumn = ""; // Reset column if no order
      }
    } else {
      // If it's a new column, start with ascending
      newOrder = "asc";
    }

    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1, // Reset pagination on sort change
      orderByColumn: newColumn, // Set the new column or empty string
      orderIn: newOrder, // Set the new order (asc, desc, or empty)
    }));
  };

  const handleApprove = (
    documentId: number | null,
    documentName: string,
    filePath: string,
    companyId: number,
    agreementId: number
  ) => {
    // Extract the name without extension
    const nameWithoutExtension =
      documentName && documentName.includes(".")
        ? documentName.slice(0, documentName.lastIndexOf("."))
        : documentName;
    const data = {
      documentName: nameWithoutExtension,
      documentCategory: { value: 16, label: "Cooperation Agreement" },
      documentId: documentId,
      fileName: documentName,
      filePath: filePath,
      extension: "pdf",
      companyId,
      agreementId,
      approved: true,
      loggedInUserId: userAuth.userId,
    };
    setDocumentData(data);
    setDocumentModalOpen(true); // Open the modal
  };

  const handleSave = async () => {
    const response = await ApiCall.service(
      SEND_FOR_AM_APPROVAL,
      "POST",
      documentData,
      true,
      COMPANY_MICROSERVICE
    );

    if (response.status === 200) {
      CustomNotify({ type: "success", message: t(response.msg) });
      setDocumentModalOpen(false);
      window.location.reload();
      // navigate("/manage/cooperation-agreement");
    }
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Cooperation agreeement",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Cooperation agreements")} />
      <div className="position-relative tableMainWrapper mt-0">
        <div className="table-responsive tableSection tableSectionWithoutBtn">
          <table className="table manageAgreementClass border-0 mb-0">
            <thead>
              <tr className="tableSearchFields">
                <SearchFields
                  searchData={searchData}
                  filters={filters}
                  handleSearchChange={handleSearchChange}
                  handleSelectChange={handleSelectChange}
                />
                <th className="border-0">
                  <div className="d-flex align-items-center w-100">
                    <ResetBtn handleResetClick={handleReset} />
                  </div>
                </th>
              </tr>
            </thead>
          </table>
          <div className="tableContainerAgreement scrollBarDesign">
            <table className="table table-hover manageAgreementClass">
              <thead>
                <tr className="TableHeader">
                  <th onClick={() => handleOrderByColumn("office")}>
                    {t("Office")}
                    <Sorting
                      sortType={
                        searchData.orderByColumn === "office"
                          ? searchData.orderIn
                          : ""
                      }
                    />
                  </th>
                  <th onClick={() => handleOrderByColumn("company")}>
                    {t("Company")}
                    <Sorting
                      sortType={
                        searchData.orderByColumn === "company"
                          ? searchData.orderIn
                          : ""
                      }
                    />
                  </th>
                  <th>{t("Unit")}</th>
                  <th>{t("Type")}</th>
                  <th onClick={() => handleOrderByColumn("sales")}>
                    {t("Sales agent")}
                    <Sorting
                      sortType={
                        searchData.orderByColumn === "sales"
                          ? searchData.orderIn
                          : ""
                      }
                    />
                  </th>
                  <th>{t("Status")}</th>
                  <AccessControl
                    requiredPermissions={[
                      {
                        permission: "Cooperation agreeement",
                        update: true,
                        delete: true,
                        read: true,
                      },
                    ]}
                    actions={true}
                    strict={false}
                  >
                    <th>{t("Actions")}</th>
                  </AccessControl>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <SpinnerWrapper headerLength={7} />
                ) : (
                  <>
                    {agreementData && agreementData.length > 0 ? (
                      agreementData.map(
                        (item: CoopertionProps, index: number) => (
                          <tr key={index} className="align-middle">
                            <td className="text-break" data-label={t("Office")}>
                              {item.office}
                            </td>
                            <td
                              className="text-break"
                              data-label={t("Company")}
                            >
                              {item.companyName}
                            </td>
                            <td className="text-break" data-label={t("Unit")}>
                              {t(`${item.unitType}`)}
                            </td>
                            <td className="text-break" data-label={t("Type")}>
                              {item.coeffType === 1
                                ? t("Fee based")
                                : t("Coefficient")}
                            </td>
                            <td
                              className="text-break"
                              data-label={t("Sales agent")}
                            >
                              {item.first_name
                                ? item.first_name + " " + item.last_name
                                : ""}
                            </td>
                            <td
                              className="text-break ps-4"
                              data-label={"status"}
                            >
                              <span
                                className={`d-inline-block rounded-circle status-icon ${
                                  item.save_as_draft === 0
                                    ? "bg-success"
                                    : item.approved === null &&
                                      item.sendToEmployer === 1
                                    ? "bg-primary"
                                    : "bg-warning"
                                }`}
                                data-toggle="tooltip"
                                title={
                                  item.save_as_draft === 0
                                    ? t("Active")
                                    : item.approved === null &&
                                      item.sendToEmployer === 1
                                    ? t("Sent to Employer")
                                    : t("Pending")
                                }
                              ></span>
                            </td>
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Cooperation agreeement",
                                  update: true,
                                  delete: true,
                                },
                              ]}
                              actions={true}
                              strict={false}
                            >
                              <td className="table-action-icons">
                                <ActionIconWrapper initialVisibleCount={5}>
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Cooperation agreeement",
                                        update: true,
                                      },
                                    ]}
                                  >
                                    {userAuth.role === "EMPLOYER" ? (
                                      <Link
                                        to={`/cooperation-agreement/preview/${item.company_id}/${item.id}`}
                                        title={t("Edit")}
                                      >
                                        <EditIcon />
                                      </Link>
                                    ) : (
                                      <Link
                                        to={`/cooperation-agreement/create/${item.company_id}/${item.id}`}
                                        title={t("Edit")}
                                      >
                                        <EditIcon />
                                      </Link>
                                    )}
                                  </AccessControl>
                                  {item.approved !== null && process.env.REACT_APP_NODE_ENV !== "production" && (
                                    <AccessControl
                                      requiredPermissions={[
                                        {
                                          permission: "Admin fiche",
                                          read: true,
                                        },
                                      ]}
                                    >
                                      {userAuth.role === "EMPLOYER" ? (
                                        item.adminFicheId && (
                                          <Link
                                            to={`/admin-fiche/preview/${item.id}/${item.adminFicheId}?companyId=${item.company_id}`}
                                            title={t("Admin fiche")}
                                          >
                                            <LinkIcon />
                                          </Link>
                                        )
                                      ) : (
                                        <Link
                                          to={`/admin-fiche/create/${item.id}${
                                            item.adminFicheId
                                              ? `/${item.adminFicheId}`
                                              : ""
                                          }`}
                                          title={t("Admin fiche")}
                                        >
                                          <LinkIcon />
                                        </Link>
                                      )}
                                    </AccessControl>
                                  )}
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Cooperation agreeement",
                                        read: true,
                                      },
                                    ]}
                                  >
                                    {item.approved !== null && (
                                      <Link
                                        to={item.file_path}
                                        // {`${process.env.REACT_APP_COMPANY_SERVICE}/api/agreement/download/${item.company_id}/${item.id}`}
                                        title={t("Download pdf")}
                                        target="_blank"
                                      >
                                        <PdfIcon />
                                      </Link>
                                    )}
                                  </AccessControl>

                                  {[
                                    "ADMIN",
                                    "AREA_MANAGER",
                                    "SUPER_ADMIN",
                                  ].includes(userAuth.role) &&
                                    item.sendForApproval === 1 && (
                                      <AccessControl
                                        requiredPermissions={[
                                          {
                                            permission:
                                              "Cooperation agreeement",
                                            read: true,
                                          },
                                        ]}
                                      >
                                        <Link
                                          to={""}
                                          onClick={() =>
                                            handleApprove(
                                              item.documentId,
                                              item.documentName,
                                              item.filePath,
                                              item.company_id,
                                              item.id
                                            )
                                          }
                                          // {`${process.env.REACT_APP_COMPANY_SERVICE}/api/agreement/download/${item.company_id}/${item.id}`}
                                          title={t("Approve")}
                                          // target="_blank"
                                        >
                                          <Approve />
                                        </Link>
                                      </AccessControl>
                                    )}
                                  <div>
                                    <UserInfoTooltip
                                      createdBy={item.created_by}
                                      createdAt={formatDateAndTime(
                                        item.created_at
                                      )}
                                      updatedAt={formatDateAndTime(
                                        item.updated_at
                                      )}
                                      updatedBy={item.updated_by}
                                    />
                                  </div>
                                </ActionIconWrapper>
                              </td>
                            </AccessControl>
                          </tr>
                        )
                      )
                    ) : (
                      <NoRecords headerLength={10} />
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
          {!loading && (
            <div className="pagination d-block">
              <PaginationNew
                currentPage={searchData.currentPage}
                totalPages={paginationData.totalPages}
                totalRecords={paginationData.totalRecords}
                recordsPerPage={searchData.recordsPerPage}
                onItemsPerPageChange={handleItemsPerPageChange}
                onPageChange={(newPage: any) => handlePageChange(newPage)}
              />
            </div>
          )}
        </div>
      </div>

      <div>
        <div
          className="col-md-6 align-self-center"
          style={{ padding: "1vw 0" }}
        >
          <Link
            to="/manage-companies"
            className=" back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </div>
      <DocumentModal
        isModalOpen={isDocumentModalOpen}
        closeModal={() => setDocumentModalOpen(false)}
        documentData={documentData}
        documentErrors={{}}
        onFileUpload={() => {}}
        onFileDelete={() => {}}
        onFileUploadError={() => {}}
        onFieldChange={() => {}}
        onSave={handleSave}
        categoryOptions={[]}
        buttonTitle="Approve and push data to bright"
        showDeleteIcon={false}
        isDisabled={true}
      />
    </AccessControl>
  );
};

export default translate(ManageAgreement);
