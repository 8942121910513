import { t } from "components/CentralDataMangement/translation/Translation";
import InputTextAreaField from "components/common/atoms/InputTextAreaField";
import InputTextfield from "components/common/atoms/InputTextField";
import LabelField from "components/common/atoms/LabelField";
import RadioField from "components/common/atoms/RadioField";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";
import { WageTabProps } from "components/CompanyCreation/AdminFiche/annotations/AdminFicheInterface";
import React from "react";

interface Props {
  wageData: WageTabProps;
  wageId: number;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    wageId: number,
    profileId?: number,
    codeId?: number,
    applicabilityId?: number
  ) => void;
  handleSelectChange: (
    value: Option | null,
    filed: string,
    wageId: number,
    profileId?: number,
    codeId?: number
  ) => void;
  ecoBenefitList: OptionProps[];
}
const MealAndEcoCheques: React.FC<Props> = ({
  wageData,
  wageId,
  handleChange,
  handleSelectChange,
  ecoBenefitList,
}) => {
  return (
    <div className="mb-3">
      <table className="table table-bordered mb-0">
        <thead>
          <tr className="TableHeader">
            <th style={{ width: "30%" }}>{t("Eco cheque")}</th>
            <th style={{ width: "70%" }}>{t("Meal vouchers")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="row">
                <div className="col-md-12">
                  <LabelField title={t("Eco cheque")} />
                  <div className="row marginBottomPoint5">
                    <div className="col-12">
                      <div className="d-inline-block me-3">
                        <RadioField
                          label={t("Yes")}
                          name={`isEcoCheque`}
                          value={1}
                          ischecked={wageData.isEcoCheque === 1}
                          handleChange={(e) => handleChange(e, wageId)}
                        />
                      </div>
                      <div className="d-inline-block">
                        <RadioField
                          label={t("No")}
                          name={`isEcoCheque`}
                          value={0}
                          ischecked={wageData.isEcoCheque === 0}
                          handleChange={(e) => handleChange(e, wageId)}
                        />
                      </div>
                    </div>
                  </div>

                  {wageData.isEcoCheque === 1 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="input-group ecoVouchers position-relative">
                          <InputTextfield
                            type="text"
                            name="value"
                            value={wageData.value ?? ""}
                            handleChange={(e) => handleChange(e, wageId)}
                          />
                          <div className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                            €
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    {wageData.isEcoCheque === 0 && (
                      <div className={`ecoCheque ${wageData.chequeType?.value === 5 ? "col-4" : "col-12"} hideLabel`}>
                        <SelectWithSearch
                          name="chequeType"
                          value={wageData.chequeType}
                          options={ecoBenefitList ?? []}
                          search={true}
                          onChange={(e) =>
                            handleSelectChange(e, "chequeType", wageId)
                          }
                        />
                      </div>
                    )}
                    {wageData.chequeType?.value === 5 && (
                      <div className="col-8">
                        <InputTextAreaField
                          name="otherInfo"
                          value={wageData.otherInfo ?? ""}
                          handleChange={(e) => handleChange(e, wageId)}
                          //   error={errors?.["otherInfo"]}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div className="row">
                <div className="col-4">
                  <div className="input-group mealVouchers position-relative">
                    <LabelWithInputField
                      type="text"
                      label={t("Mtc total value")}
                      name="totalValue"
                      value={wageData.totalValue ?? ""}
                      handleChange={(e) => handleChange(e, wageId)}
                    />
                    <div className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                      €
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="input-group mealVouchers position-relative">
                    <LabelWithInputField
                      type="text"
                      label={t("Mtc employer part")}
                      name="employerPart"
                      value={wageData.employerPart ?? ""}
                      handleChange={(e) => handleChange(e, wageId)}
                    />
                    <div className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                      €
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="input-group mealVouchers position-relative">
                    <LabelWithInputField
                      type="text"
                      label={t("Mtc minimum hours")}
                      name="minimumHours"
                      value={wageData.minimumHours ?? ""}
                      handleChange={(e) => handleChange(e, wageId)}
                    />
                    <div className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                      €
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MealAndEcoCheques;
