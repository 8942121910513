import { t, translate } from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import { ApiCall } from "components/common/services/ApiServices";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import ModalPopup from "components/common/atoms/ModalPopup";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
    validateEmail,
    validateForm,
    validatePassword,
    validateRequired,
} from "components/common/services/ValidationService";
import EncryptDecryptService from "components/common/services/EncryptDecryptService";
import CustomNotify from "components/common/atoms/CustomNotify";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";

interface Preview {
    iframeUrl: string;
    acceptTerms: number;
    candidateSign: boolean;
    signAsCandidatePopup: boolean;
    candidateVerify: boolean;
    email: string;
    password: string;
    candidateId: number | null;
    canUserId: number | null;
}

const ContractPage = () => {
    const { todoExtensionId, companyId, candidateId, todoId, functionName, presentationId, stageId } = useParams<{
        todoExtensionId: string;
        companyId: string;
        candidateId: string;
        todoId: string;
        functionName: string;
        presentationId: string;
        stageId: string;
    }>();
    const location = useLocation();
    const userAuth = useSelector(selectAuth);
    const candidate = userAuth.role === "CANDIDATE";
    const navigate = useNavigate();
    const lang_key = secureLocalStorage.getItem('site_lang') ?? 'en';

    const initialState = {
        iframeUrl: `${process.env.REACT_APP_CDM_SERVICE}/api/get/contract/details?candidate=${candidateId}&company=${companyId}&presentationId=${presentationId}&role=${userAuth.role}&userId=${userAuth.userId}&lang=${lang_key}`,
        acceptTerms: 0,
        candidateSign: true,
        signAsCandidatePopup: false,
        candidateVerify: candidate ?? false,
        email: "",
        password: "",
        candidateId: null,
        canUserId: null
    };
    const [state, setState] = useState<Preview>(initialState);
    const [loading, setLoading] = useState(false);

    const initialErrors: { [key: string]: string } = {};
    const [errors, setErrors] = useState<{ [key: string]: string }>(
        initialErrors
    );
    const handleModalClose = (type: string) => {
        if (type === "candidateSign") {
            setState((prevData) => ({
                ...prevData,
                signAsCandidatePopup: false,
            }));
        } else {
            setState((prevData) => ({
                ...prevData,
                candidateSign: true,
            }));
        }
    };

    const validation = (
        name: string,
        value: string | boolean | number,
        isSingleFieldValidation: boolean = false
    ) => {
        const validationRules: ValidationRules = {
            email: [validateRequired, validateEmail],
            password: [validateRequired, validatePassword],
        };

        const validationErrors = validateForm(
            { ...state, [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );

        if (isSingleFieldValidation && Object.keys(errors).length > 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: validationErrors[name],
            }));
        } else {
            setErrors(validationErrors);
        }

        if (Object.keys(validationErrors).length > 0) {
            return false;
        }

        return true;
    };
    const checkCandidateSign = async () => {
        const url = `${"checkCandidateSign"}/${candidateId}`;
        const response = await ApiCall.getService(
            url,
            "GET",
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
            true
        );

        if (response.status === 200) {
            const data = response.data;

            const canUserId = data !== null ? data['userId'] : null;
            setState((prevData) => ({
                ...prevData,
                candidateSign: data !== null,
                signAsCandidatePopup: data !== null,
                email: data !== null && data['userMail'],
                canUserId: canUserId ? canUserId : null,
                iframeUrl: `${process.env.REACT_APP_CDM_SERVICE}/api/get/contract/details?candidate=${candidateId}&canUserId=${canUserId}&verify=0&presentationId=${presentationId}&company=${companyId}&role=${userAuth.role}&userId=${userAuth.userId}&lang=${lang_key}`,
            }));
        }
    };

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = event.target;
        let updatedValue: string | boolean | number;
        if (type === "checkbox") {
            updatedValue = checked ? 1 : 0;
            if (updatedValue === 0) {
                setState(initialState);
            }
        } else {
            updatedValue = value;
        }
        setState((prevData) => ({
            ...prevData,
            [name]: updatedValue,
            iframeUrl: `${process.env.REACT_APP_CDM_SERVICE}/api/get/contract/details?candidate=${candidateId}&company=${companyId}&canUserId=${state.canUserId}&presentationId=${presentationId}&role=${userAuth.role}&userId=${userAuth.userId}&lang=${lang_key}`,
        }));
        validation(name, value, true);
    };



    const authenticateCandidate = async (event: React.FormEvent) => {
        event.preventDefault();
        const { name, value } = event.target as HTMLInputElement;
        const formData = {
            email: state.email,
            password: EncryptDecryptService.encryptData(
                JSON.stringify(state.password)
            ),
            presentationId: presentationId,
            candidateId: candidateId,
            canUserId: state.canUserId,
            stage: stageId === "9" ? "Contract" : "Sign",
            todoExtensionId: todoExtensionId,
            todoId: todoId,
            functionName: functionName !== 'undefined' && functionName !== 'null' && functionName !== '' && functionName !== '--' ? functionName : null,
            submitType: "accept",
            lang: lang_key
        };
        if (validation(name, value)) {
            const response = await ApiCall.service(
                "authenticateCandidate",
                "POST",
                formData,
                true,
                CENTRAL_DATA_MANAGEMENT_MICROSERVICE
            );
            if (response.status === 200) {
                setState((prevData) => ({
                    ...prevData,
                    candidateVerify: true,
                    signAsCandidatePopup: false,
                    iframeUrl: `${process.env.REACT_APP_CDM_SERVICE}/api/get/contract/details?candidate=${candidateId}&canUserId=${state.canUserId}&verify=1&company=${companyId}&presentationId=${presentationId}&role=${userAuth.role}&userId=${userAuth.userId}&lang=${lang_key}`,
                }));
                setErrors({
                    email: "",
                });
            } else {
                const data = response.data;
                setErrors({
                    email: data,
                });
            }
        }
    };

    const handleSubmit = async (toCandidate: boolean) => {
        const formData = {
            approved: toCandidate ? false : true,
            presentationId: presentationId,
            companyId: companyId,
            sendToCandidate: toCandidate,
            candidateId: candidateId,
            recruiterId: !candidate ? userAuth.userId : null,
            todoExtensionId: todoExtensionId,
            todoId: todoId,
            functionName: functionName !== 'undefined' && functionName !== 'null' && functionName !== '' && functionName !== '--' ? functionName : null,
            stage: stageId === "9" ? "Contract" : "Sign",
            canUserId: candidate ? userAuth.userId : state.canUserId,
            email: state.email,
            verify: 1,
            submitType: "accept",
            lang: lang_key
        };
        const response = await ApiCall.service(
            "authenticateCandidate",
            "POST",
            formData,
            true,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );

        if (response.status === 200) {
            CustomNotify({
                type: "success",
                message: t(response.msg),
            });
            navigate(location.state?.origin === "todos" ? "/todos/overview" : "/dashboard");
        } else {
            const data = response.data;
            if (data) {
                setState((prevData) => ({
                    ...prevData,
                    candidateSign: false
                }))
            }
            !data && CustomNotify({ type: "error", message: t(response.message) });
        }
    };

    return (
        <div>
            <iframe
                className="iframeHeight"
                width="100%"
                src={state.iframeUrl}
                title="Embedded Content"
                allowFullScreen
            ></iframe>
            <div className="col-lg-12">
                <div className="row">
                    <div className="d-flex align-items-center mb-3">
                        <CheckBoxField
                            name="acceptTerms"
                            onChangeHandler={changeHandler}
                            isChecked={state.acceptTerms === 1}
                            id="acceptTerms"
                            className="cursor-pointer"
                        />
                        <label
                            htmlFor="acceptTerms"
                            className="checkbox-label cursor-pointer"
                            style={{ lineHeight: "26px" }}
                        >
                            {t(
                                "I hereby declare that I have reviewed the document and confirming that I agree with all details."
                            )}
                        </label>
                    </div>

                    {state.candidateVerify === true && state.acceptTerms === 1 && (
                        <div className="">
                            <Button
                                type="submit"
                                title={t("Submit")}
                                handleClick={() => handleSubmit(false)}
                                className="btn float-end form-button shadow-none rounded-3"
                            />
                        </div>
                    )}

                    {!candidate && state.acceptTerms === 1 && state.candidateVerify === false && (
                        <div className="">
                            <Button
                                type="submit"
                                title={t("Sign as candidate")}
                                handleClick={checkCandidateSign}
                                className="btn float-end form-button shadow-none rounded-3"
                            />
                            {stageId && stageId === "9" &&
                                <Button
                                    type="submit"
                                    title={t("Send to candidate")}
                                    handleClick={() => handleSubmit(true)}
                                    className="btn float-end form-button shadow-none rounded-3 me-4"
                                />
                            }
                        </div>
                    )}
                </div>
            </div>
            <ModalPopup
                show={!state.candidateSign}
                onHide={() => handleModalClose("authenticated")}
                title={t("Signature")}
                body={candidate ? t("Please add the signature to continue further") : t("Candidate not added the signature, Please add the signature to continue further")}
                className="modal-lg"
            />
            <ModalPopup
                show={state.signAsCandidatePopup}
                onHide={() => handleModalClose("candidateSign")}
                title={t("Sign as candidate")}
                body={
                    <form onSubmit={authenticateCandidate}>
                        <div>
                            <LabelWithInputField
                                handleChange={changeHandler}
                                label={t("Email")}
                                type="text"
                                isDisabled={true}
                                id="email"
                                name="email"
                                value={state.email}
                                error={errors.email}
                            />
                            <LabelWithInputField
                                handleChange={changeHandler}
                                isMandatory={true}
                                label={t("Password")}
                                type="password"
                                id="password"
                                name="password"
                                value={state.password}
                                error={errors.password}
                            />
                            <Button
                                type="submit"
                                title={t("Authenticate")}
                                className="btn float-end form-button shadow-none rounded-3 me-2"
                            />
                        </div>
                    </form>
                }
                className="modal-lg"
            />
        </div>
    );
};

export default translate(ContractPage);
