import { t } from "components/CentralDataMangement/translation/Translation";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import LabelField from "components/common/atoms/LabelField";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { GeneralTabProps } from "components/CompanyCreation/AdminFiche/annotations/AdminFicheInterface";
import React from "react";

interface props {
  generalState: GeneralTabProps;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}
const Online: React.FC<props> = ({ generalState, handleChange }) => {
  return (
    <>
      <div className="col-12" style={{ paddingTop: "1.304vw" }}>
        <LabelField title={t("Online")} className="tab-subtitle pb-0 marginBottomPoint5" />
      </div>
      <div className="col-3">
        <LabelWithInputField
          label={t("Electronic invoice email addresses") + ":"}
          name="electronicInvoiceMail"
          handleChange={handleChange}
          value={generalState.electronicInvoiceMail}
        />
      </div>
      <div className="col-3 align-self-center">
        <CheckBoxField
          label={t("Electronic invoices")}
          onChangeHandler={handleChange}
          value={generalState.electronicInvoice ? "1" : "0"}
          isChecked={
            generalState.electronicInvoice !== null ? generalState.electronicInvoice : false
          }
          name="electronicInvoice"
          id="electronicInvoice"
        />
      </div>
      <div className="col-3">
        <LabelWithInputField
          label={t("Email addresses for electronic contracts") + ":"}
          name="electronicContractMail"
          handleChange={handleChange}
          value={generalState.electronicContractMail}
        />
      </div>
      <div className="col-3 align-self-center">
        <CheckBoxField
          label={t("Electronic contracts")}
          onChangeHandler={handleChange}
          name="electronicContract"
          className="me-5"
          value={generalState.electronicContract ? "1" : "0"}
          isChecked={
            generalState.electronicContract !== null ? generalState.electronicContract : false
          }
          id="electronicContract"
        />
      </div>
      {/* <div className="col-3">
        <LabelWithInputField
          label={t("Performance sheet email addresses") + ":"}
          name="performanceSheetMail"
          handleChange={handleChange}
          value={generalState.performanceSheetMail}
        />
      </div>
      <div className="col-3 align-self-center">
        <CheckBoxField
          label={t("Performance sheet")}
          onChangeHandler={handleChange}
          name="performanceSheet"
          value={generalState.performanceSheet ? "1" : "0"}
          isChecked={
            generalState.performanceSheet !== null
              ? generalState.performanceSheet
              : false
          }
          id="performanceSheet"
        />
      </div> */}
      <div className="col-3">
        <LabelWithInputField
          label={t("Reminder email addresses") + ":"}
          name="reminderMail"
          handleChange={handleChange}
          value={generalState.reminderMail}
        />
      </div>
    </>
  );
};

export default React.memo(Online);
