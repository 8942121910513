import { useState } from "react";
import LabelField from "components/common/atoms/LabelField";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { useFormContext } from "components/CandidateCreation/context/Context";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import {
  validateForm,
  validateSSN,
  Option,
  validateIBAN,
  validateTextFieldAlphaCanBeNull,
  validateEmailCanBeNull,
  validatePhoneNumberCanBeNull,
  validateTextFieldAlpha,
  validateRequired,
  validateEmail,
  validatePhoneNumber,
  validateSelectField,
  scrollToTop,
} from "components/common/services/ValidationService";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import Calender from "components/common/molecules/Calender";
import PhoneInput from "react-phone-number-input";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import { GeneralProps } from "components/CandidateCreation/annotations/CandidateAnnotations";
import Navigation from "../formNavigation/Navigation";
import { ApiCall } from "components/common/services/ApiServices";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import CloseFile from "static/images/CloseFile";
import {
  getGenderById,
  getWithHoldingTaxForSeasonalWorker,
  isValidIBANNumber,
} from "components/common/utlis/HelperFunctions";
import { CANDIDATE_MICROSERVICE } from "Constants";
import {
  GET_OFFICE_FOR_CONSULTANT,
  CHECK_FOR_IBAN_EXISTANCE,
} from "routes/ApiEndpoints";
import { addUserDetails, selectAuth } from "features/auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { InputGroupText } from "components/common/atoms/InputGroupText";
import { Link, useParams } from "react-router-dom";
import ModalPopup from "components/common/atoms/ModalPopup";
import { useSSNorEmailValidation } from "./tabContentComponents/SSNOrEmailValidation";
import AddressSearchPopup, {
  AddressDetails,
} from "components/common/molecules/AddressSearchPopUp";
import { SetAddressData } from "../formData/SetAddressData";
import ResumeIcon from "static/images/ResumeIcon";
import ActiveLocation from "static/images/ActiveLocation";

const GeneralTab: React.FC = () => {
  const { state, dispatch, isViewMode } = useFormContext();

  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const { candidateId } = useParams<{ candidateId: string }>();

  ////******** SSN OR EMAIL VALIDATION ****
  const {
    canId,
    SSNorEmailModal,
    modalTitle,
    emailOrSSNModalBody,
    closeModal,
    handleCandidate,
    checkForSSNorEmailExistence,
  } = useSSNorEmailValidation();
  /* */

  const reduxDispatch = useDispatch();

  const userAuth = useSelector(selectAuth);
  const emergencyContactValidation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    index: number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      contactFirstName: [validateTextFieldAlphaCanBeNull],
      contactLastName: [validateTextFieldAlphaCanBeNull],
      contactEmail: [validateEmailCanBeNull],
      contactPhone: [validatePhoneNumberCanBeNull],
    };
    const validationErrors = validateForm(
      { ...state.emergencyContact[index], [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    dispatch({
      type: "UPDATE_EMERGENCY_CONTACT_FIELD_ERROR",
      field: name,
      error: validationErrors[name],
      index,
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      dispatch({
        type: "UPDATE_EMERGENCY_CONTACT_FIELD_ERROR",
        field: name,
        error: "",
        index,
      });
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const validation = (
    name: string,
    value:
      | string
      | boolean
      | Date
      | object[]
      | Option
      | null
      | undefined
      | number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      firstName: [validateRequired, validateTextFieldAlpha],
      lastName: [validateRequired, validateTextFieldAlpha],
      email: [validateRequired, validateEmail],
      mobNumber: [validatePhoneNumber],
      ssn: [validateSSN],
      iban:
        value && typeof value === "string" && value?.startsWith("BE")
          ? [validateIBAN]
          : [],
      street: [validateRequired],
      number: [validateRequired],
      zipCode: [validateRequired],
      city: [validateSelectField],
      country: [validateSelectField],
      office: [validateSelectField],
      countryOfBirth: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...state.general, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: "UPDATE_FIELD_ERROR",
      fieldName: name,
      error: validationErrors[name],
    });

    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      dispatch({ type: "UPDATE_FIELD_ERROR", fieldName: name, error: "" });
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const currentDate = new Date();
  const below15 = new Date();
  below15.setFullYear(currentDate.getFullYear() - 15);

  const handleDateChange = (date: Date | null, name: string) => {
    const updatedDate = date !== null ? date : null;

    dispatch({
      type: "UPDATE_GENERAL_FIELD",
      field: name,
      value: updatedDate,
    });
    validation(name, updatedDate, true);
    if (updatedDate) {
      const birthDate = updatedDate;

      if (!birthDate) {
        return null; // Return null if date of birth is not available
      }

      const inputElement = document.querySelector(`input[name="dateOfBirth"]`) as HTMLInputElement | null;
      const inputValue = inputElement ? inputElement.value.trim() : "";

      // Regular expression to validate dd-MM-yyyy format
      const datePattern = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4})$/;
      const match = inputValue.match(datePattern);
      let age = null as number | null;
      if (match) {
        const birthYear = birthDate.getFullYear(); // Get birth year

        const currentDate = new Date(); // Get current date
        const currentYear = currentDate.getFullYear(); // Get current year
        age = currentYear - birthYear; // Calculate and return the age
      }

      dispatch({
        type: "UPDATE_GENERAL_FIELD",
        field: "age",
        value: age,
      });
    } else {

      dispatch({
        type: "UPDATE_GENERAL_FIELD",
        field: "age",
        value: '',
      });
    }
  };

  const handleCalendarClick = (date: Date | null, name: string) => {
    // This will be triggered when the calendar is clicked
    const birthDate = date;
    if (birthDate) {
      const birthYear = birthDate.getFullYear(); // Get birth year

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const age = currentYear - birthYear;
      dispatch({
        type: "UPDATE_GENERAL_FIELD",
        field: "age",
        value: age,
      });
    }
  };

  // const getAge = () => {
  //   const birthDate = state.general.dateOfBirth;

  //   if (!birthDate) {
  //     return null; // Return null if date of birth is not available
  //   }

  //   const birthYear = birthDate.getFullYear(); // Get birth year

  //   const currentDate = new Date(); // Get current date
  //   const currentYear = currentDate.getFullYear(); // Get current year

  //   return currentYear - birthYear; // Calculate and return the age
  // };

  const handlePhoneNumberChange = (mobNumber: string | undefined) => {
    dispatch({
      type: "UPDATE_GENERAL_FIELD",
      field: "mobNumber",
      value: mobNumber,
    });
    validation("mobNumber", mobNumber, true);
  };

  const copyDomicileFieldsFromResidence = (isChecked: boolean) => {
    if (isChecked) {
      const residenceFields = [
        "street",
        "number",
        "city",
        "country",
        "zipCode",
        "box",
        "cityOther",
      ];
      residenceFields.forEach((fieldName) => {
        const domicileFieldName =
          "dom" + fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
        const fieldValue = state.general[fieldName as keyof GeneralProps];
        dispatch({
          type: "UPDATE_GENERAL_FIELD",
          field: domicileFieldName,
          value: fieldValue,
        });
        validation(domicileFieldName, fieldValue, true);
      });
    } else {
      const domFields = [
        "domStreet",
        "domNumber",
        "domCity",
        "domCountry",
        "domZipCode",
        "domBox",
        "domCityOther",
      ];
      domFields.forEach((fieldName) => {
        const value =
          fieldName === "domCity" || fieldName === "domCountry" ? null : "";
        dispatch({
          type: "UPDATE_GENERAL_FIELD",
          field: fieldName,
          value: value,
        });
        validation(fieldName, value, true);
      });
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isViewMode) return;

    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      const newValue = checked ? 1 : 0;
      dispatch({ type: "UPDATE_GENERAL_FIELD", field: name, value: newValue });
      validation(name, checked, true);
      if (name === "domCheckbox") {
        copyDomicileFieldsFromResidence(checked);
      }
      if (name === "consentToTerms") {
        reduxDispatch(addUserDetails({ consentToTerms: newValue }));
      }
    } else if (type === "radio") {
      dispatch({
        type: "UPDATE_GENERAL_FIELD",
        field: name,
        value: parseInt(value),
      });
    } else if (name === "iban") {
      dispatch({
        type: "UPDATE_GENERAL_FIELD",
        field: name,
        value: value,
      });
      checkForIbanExist(value);
      const IBANValidation = validation(name, value, true);
      if (IBANValidation && value !== "" && value?.startsWith("BE")) {
        getBicForIBAN(value);
      } else {
        dispatch({
          type: "UPDATE_GENERAL_FIELD",
          field: "bic",
          value: "",
        });
        dispatch({
          type: "UPDATE_FIELD_ERROR",
          fieldName: "bic",
          error: "",
        });
      }
    } else if (name === "ssn") {
      const numericValue = value.replace(/[^0-9]/g, "");
      let formattedSSN = "";

      if (numericValue.length <= 11) {
        for (let i = 0; i < numericValue.length; i++) {
          if (i === 2 || i === 4 || i === 9) {
            formattedSSN += ".";
          } else if (i === 6) {
            formattedSSN += "-";
          }
          formattedSSN += numericValue[i];
        }

        // Set the formatted SSN in the state
        dispatch({
          type: "UPDATE_GENERAL_FIELD",
          field: name,
          value: formattedSSN,
        });
        const validatioResult = validation("ssn", formattedSSN, true);
        if (
          validatioResult &&
          formattedSSN !== "" &&
          formattedSSN.length === 15
        ) {
          if (!candidateId) {
            checkForSSNorEmailExistence("", formattedSSN);
          }
          const birthdateString = formattedSSN
            .substring(0, 8)
            .replace(/\./g, ""); // Extract YYMMDD part and remove dots
          const year = birthdateString.substring(0, 2);
          let month = parseInt(birthdateString.substring(2, 4), 10);
          const day = parseInt(birthdateString.substring(4, 6));
          const century = parseInt(year) < 50 ? "20" : "19"; // Determine the century based on the year
          const fullYear = parseInt(century + year);

          if (month >= 1 && month <= 12 && day >= 1 && day <= 31) {
            // Create a Date object with the extracted birthdate
            const dateOfBirth = new Date(fullYear, month - 1, day);

            dispatch({
              type: "UPDATE_GENERAL_FIELD",
              field: "dateOfBirth",
              value: dateOfBirth,
            });

            const genderId =
              parseInt(formattedSSN.substring(9, 12)) % 2 === 0 ? 2 : 1;
            const gender: Option | undefined = getGenderById(genderId);

            if (gender) {
              dispatch({
                type: "UPDATE_OTHER_FIELD",
                field: "gender",
                value: gender, // Assuming you want to use the value property of Gender
              });
            } else {
              dispatch({
                type: "UPDATE_OTHER_FIELD",
                field: "gender",
                value: null, // Assuming you want to use the value property of Gender
              });
            }
          }
        } else {
          dispatch({
            type: "UPDATE_GENERAL_FIELD",
            field: "dateOfBirth",
            value: null,
          });
          dispatch({
            type: "UPDATE_OTHER_FIELD",
            field: "gender",
            value: null, // Assuming you want to use the value property of Gender
          });
        }
      }
    } else if (name === "email") {
      dispatch({ type: "UPDATE_GENERAL_FIELD", field: name, value });
      if (!candidateId && validateEmail(value) === null) {
        checkForSSNorEmailExistence(value, "");
      }
      validation(name, value, true);
    } else {
      dispatch({ type: "UPDATE_GENERAL_FIELD", field: name, value });
      validation(name, value, true);
      if (state.general.domCheckbox) {
        const domFieldName =
          "dom" + name.charAt(0).toUpperCase() + name.slice(1);
        dispatch({ type: "UPDATE_GENERAL_FIELD", field: domFieldName, value });
        validation(domFieldName, value, true);
      }
    }
  };

  async function getBicForIBAN(iban: string) {
    const url = `https://openiban.com/validate/${iban}?getBIC=true&validateBankCode=true`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const ibanData = await response.json();
      if (ibanData.valid) {
        dispatch({
          type: "UPDATE_FIELD_ERROR",
          fieldName: "bic",
          error: "",
        });
        dispatch({
          type: "UPDATE_GENERAL_FIELD",
          field: "bic",
          value: ibanData.bankData.bic,
        });
      } else {
        dispatch({
          type: "UPDATE_GENERAL_FIELD",
          field: "bic",
          value: "",
        });
        // dispatch({
        //   type: "UPDATE_FIELD_ERROR",
        //   fieldName: "bic",
        //   error: t("There is no BIC for the provided IBAN"),
        // });
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  }

  const getOfficeForConsultant = (selectedOption: any) => {
    //To prefill default office for selected consultant
    const consultantId = selectedOption.value;
    if (consultantId) {
      const fetchOfficeForConsultant = async () => {
        const response = await ApiCall.getService(
          `${GET_OFFICE_FOR_CONSULTANT}/${consultantId}`,
          "GET",
          CANDIDATE_MICROSERVICE
        );
        if (response.status === 200) {
          dispatch({
            type: "UPDATE_GENERAL_FIELD",
            field: "office",
            value: response.office,
          });
        }
      };
      fetchOfficeForConsultant();
    }
  };

  const updateGeneralField = (field: string, value: any) => {
    dispatch({
      type: "UPDATE_GENERAL_FIELD",
      field,
      value,
    });
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: "UPDATE_GENERAL_FIELD",
      field: name,
      value: selectedOption,
    });
    if (
      (state.general.domCheckbox && name === "city") ||
      (state.general.domCheckbox && name === "country")
    ) {
      const domFieldName = "dom" + name.charAt(0).toUpperCase() + name.slice(1);
      dispatch({
        type: "UPDATE_GENERAL_FIELD",
        field: domFieldName,
        value: selectedOption,
      });
      validation(domFieldName, selectedOption, true);
      if (name === "country" && state.general.domCheckbox) {
        updateGeneralField("city", null);
        updateGeneralField("cityOther", "");
        updateGeneralField("domCity", null);
        updateGeneralField("domCityOther", "");
      }
    } else if (name === "country") {
      updateGeneralField("city", null);
      updateGeneralField("cityOther", "");
    } else if (name === "domCountry") {
      updateGeneralField("domCity", null);
      updateGeneralField("domCityOther", "");
    } else if (name === "office") {
      const officeId = selectedOption.value;
      let internalEmployee;
      if (officeId === 4) {
        internalEmployee = 1;
      } else {
        internalEmployee = 0;
      }
      dispatch({
        type: "UPDATE_GENERAL_FIELD",
        field: "internalEmployee",
        value: internalEmployee,
      });
    } else if (name === "consultant") {
      getOfficeForConsultant(selectedOption);
    }
    validation(name, selectedOption, true);
  };

  ///othertabs field
  const handleFieldChangeOtherInfo = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isViewMode) return;

    const { name, value, type, checked } = event.target;
    let updatedValue: string | number | null | Option = value;
    let updatedName: string = name;
    if (type === "radio") {
      updatedValue = parseInt(value);
    } else if (type === "checkbox") {
      updatedName = "workType";
      if (name === "frontier") {
        // If 'Frontier worker' is checked, set to 0, otherwise set to null (uncheck)
        updatedValue = checked ? 0 : null;
      } else if (name === "seasonal") {
        // If 'Seasonal worker' is checked, set to 1, otherwise set to null (uncheck)
        updatedValue = checked ? 1 : null;
      }
      let withHoldingTaxOption;
      if (updatedValue === 1) {
        withHoldingTaxOption = getWithHoldingTaxForSeasonalWorker();
      } else {
        withHoldingTaxOption = null;
      }
      dispatch({
        type: "UPDATE_OTHER_FIELD",
        field: "withHoldingTax",
        value: withHoldingTaxOption,
      });
    } else {
      updatedValue = value;
    }

    dispatch({
      type: "UPDATE_OTHER_FIELD",
      field: updatedName,
      value: updatedValue,
    });
  };

  const handleSelectChangeOtherInfo = (selectedOption: any, name: string) => {
    dispatch({
      type: "UPDATE_OTHER_FIELD",
      field: name,
      value: selectedOption,
    });
    validation(name, selectedOption, true);
  };

  ///
  const addFieldSet = () => {
    dispatch({ type: "ADD_EMERGENCY_CONTACT_FIELD" });
  };

  const removeFieldSet = (indexToRemove: number) => {
    dispatch({ type: "REMOVE_EMERGENCY_CONTACT_FIELD", indexToRemove });
  };

  const handleContactChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    if (isViewMode) return;

    const { name, value, type } = event.target;
    dispatch({
      type: "UPDATE_EMERGENCY_CONTACT_FIELD",
      field: name,
      value: value,
      index,
    });
    emergencyContactValidation(name, value, index, true);
  };

  const handleContactPhoneNumberChange = (mobNumber: string, index: number) => {
   dispatch({
      type: "UPDATE_EMERGENCY_CONTACT_FIELD",
      field: "contactPhone",
      value: mobNumber,
      index,
    });
    emergencyContactValidation("contactPhone", mobNumber, index, true);
  };

  const handleRatingChange = (value: number) => {
    dispatch({
      type: "UPDATE_GENERAL_FIELD",
      field: "score",
      value: value,
    });
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop(400);
  };

  //*IBAN warning validation *****
  const checkForIbanExist = async (iban: string) => {
    const isValidIban = isValidIBANNumber(iban);
    if (isValidIban === true) {
      const data = {
        iban: iban,
        candidateId: candidateId ? candidateId : null,
      };
      const response = await ApiCall.service(
        `${CHECK_FOR_IBAN_EXISTANCE}`,
        "POST",
        data,
        false,
        CANDIDATE_MICROSERVICE
      );
      if (response.status === 200) {
        dispatch({
          type: "UPDATE_FIELD_ERROR",
          fieldName: "iban",
          error: t(response.message),
        });
      }
    }
  };

  ////////////// ******** Google maps *******************//
  const [showPopup, setShowPopup] = useState(false);

  const handleConfirm = (details: AddressDetails | null) => {
    if (details) {
      SetAddressData(details, dispatch, state);
    }
    setShowPopup(false); // Close the popup after confirming the address
  };

  const handleAddPopup = () => {
    setShowPopup(true); // Close the popup without saving
  };
  const handleClosePopup = () => {
    setShowPopup(false); // or whatever logic you have to close the popup
  };
  //****************** Google maps ************* */

  return (
    <>
      <form onSubmit={handleSubmit}>
        {/* To disable fields in future in view mode add class name isViewMode ? "form disabled" : "form" */}
        <div className={`row ${isViewMode ? "form disableform" : "form"}`}>
          <div className="col-12">
            <div className="position-relative">
              <div className="form-border" style={{ paddingTop: "3.4vw" }}>
                <div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-xxl-4 col-lg-6">
                      <LabelWithInputField
                        isMandatory={false}
                        name="ssn"
                        handleChange={handleFieldChange}
                        value={state.general.ssn}
                        id="ssn"
                        label={t("SSN number")}
                        type="text"
                        autoComplete="off"
                        error={state.fieldError.ssn}
                        placeholder="XX.XX.XX-XXX.XX"
                      />
                    </div>
                    {/* <div className="col-md-6 checkbox-section">
                  <div className="ssn-left-sec">
                    <CheckBoxField
                      label={t("Internal employee")}
                      name="internalEmployee"
                      onChangeHandler={handleFieldChange}
                      isChecked={state.general.internalEmployee === 1}
                      className="me-5"
                    />
                    <CheckBoxField
                      label={t("Hidden employee")}
                      name="hiddenEmployee"
                      onChangeHandler={handleFieldChange}
                      isChecked={state.general.hiddenEmployee === 1}
                      className="me-5"
                    />
                    <CheckBoxField
                      label={t("Contract ready")}
                      name="contractReady"
                      onChangeHandler={handleFieldChange}
                      isChecked={state.general.contractReady === 1}
                    />
                  </div>
                </div> */}
                    {/* <div className="col-xxl-4 col-lg-6 col-6 mt-2">
                  <LabelField title="Overall score" />
                  <OverallScore
                    value={state.general.score}
                    onClick={handleRatingChange}
                  />
                </div> */}
                    <div className="col-sm-12 col-md-12 col-xxl-4 col-lg-6">
                      <SelectWithSearch
                        title={t("Contact preference")}
                        isMandatory={false}
                        search={true}
                        options={state.dropDowndata.contactPreferenceList}
                        onChange={(e) =>
                          handleSelectChange(e, "contactPreference")
                        }
                        isMulti={true}
                        name="contactPreference"
                        value={state.general.contactPreference}
                        id="contactPreference"
                        isTranslate={true}
                      />
                    </div>

                    <div className="col-sm-12 col-md-12 col-xxl-4 col-lg-6">
                      <SelectWithSearch
                        title={t("Selection group")}
                        isMandatory={false}
                        search={true}
                        options={state.dropDowndata.selectionGroupList}
                        onChange={(e) =>
                          handleSelectChange(e, "selectionGroup")
                        }
                        isMulti={true}
                        name="selectionGroup"
                        value={state.general.selectionGroup}
                        id="selectionGroup"
                        isTranslate={true}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-xxl-4 col-lg-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="firstName"
                        handleChange={handleFieldChange}
                        value={state.general.firstName}
                        id="fname"
                        label={t("First name")}
                        placeholder={t("First name")}
                        error={state.fieldError.firstName}
                      />
                    </div>
                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="lastName"
                        handleChange={handleFieldChange}
                        value={state.general.lastName}
                        id="lname"
                        label={t("Last name")}
                        placeholder={t("Last name")}
                        error={state.fieldError.lastName}
                      />
                    </div>
                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="email"
                        handleChange={handleFieldChange}
                        value={state.general.email}
                        id="email"
                        label={t("Email")}
                        placeholder={t("Email")}
                        type="email"
                        error={state.fieldError.email}
                      />
                    </div>

                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <LabelField
                        title={t("Phone number")}
                        isMandatory={true}
                        key="PhoneInput"
                      />
                      <div
                        className="form-control field-shadow d-flex"
                        style={{ borderRadius: "0.5vw" }}
                      >
                        <PhoneInput
                          defaultCountry="BE" //Belgium country code
                          international
                          placeholder={t("Enter phone number")}
                          onChange={handlePhoneNumberChange}
                          value={state.general.mobNumber}
                          className="w-100"
                          readOnly={isViewMode}
                        />
                      </div>
                      <div
                        className="height-20"
                        style={{ marginBottom: "0.5vw" }}
                      >
                        {state.fieldError.mobNumber && (
                          <span className="text-danger">
                            {state.fieldError.mobNumber}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-xxl-2 col-lg-4 position-relative col-md-6">
                      <Calender
                        onChange={handleDateChange}
                        selectedDate={state.general.dateOfBirth}
                        label={t("Date of birth")}
                        isMandatory={false}
                        name="dateOfBirth"
                        isDisabled={false}
                        maxDate={below15}
                        error={
                          state.fieldError.dateOfBirth
                            ? state.fieldError.dateOfBirth.toString()
                            : undefined
                        }
                        onCalendarClick={handleCalendarClick} // Pass the callback
                      />
                    </div>
                    <div className="col-lg-2 col-md-6 remaining-section">
                      <InputGroupText
                        name="age"
                        handleChange={handleFieldChange}
                        // value={
                        //   state.general.dateOfBirth !== null
                        //     ? `${getAge()}`
                        //     : `${state.general.age}`
                        // }
                        value={state.general.age}
                        type="text"
                        className="ageSection"
                        isDisabled={state.general.dateOfBirth !== null}
                        label={t("Age")}
                        isSuffix={t("years")}
                      />
                    </div>
                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <SelectWithSearch
                        title={t("Gender")}
                        isMandatory={false}
                        search={true}
                        options={state.dropDowndata.genderList}
                        onChange={(e) =>
                          handleSelectChangeOtherInfo(e, "gender")
                        }
                        isMulti={false}
                        name="gender"
                        isDisabled={false}
                        value={state.other.gender}
                        isTranslate={true}
                      />
                    </div>
                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <LabelWithInputField
                        isMandatory={false}
                        name="placeOfBirth"
                        handleChange={handleFieldChange}
                        value={state.general.placeOfBirth}
                        id="pob"
                        label={t("Place of birth")}
                        placeholder={t("Place of birth")}
                        type="text"
                      />
                    </div>
                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <SelectWithSearch
                        title={t("Country of birth")}
                        isMandatory={true}
                        search={true}
                        options={state.dropDowndata.countryList}
                        onChange={(e) =>
                          handleSelectChange(e, "countryOfBirth")
                        }
                        isMulti={false}
                        name="countryOfBirth"
                        value={state.general.countryOfBirth}
                        error={state.fieldError.countryOfBirth}
                        isTranslate={true}
                      />
                    </div>
                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <SelectWithSearch
                        title={t("Nationality")}
                        isMandatory={false}
                        search={true}
                        options={state.dropDowndata.nationalityList}
                        onChange={(e) => handleSelectChange(e, "nationality")}
                        isMulti={true}
                        name="nationality"
                        value={state.general.nationality}
                        isTranslate={true}
                      />
                    </div>
                    {!(
                      userAuth.role === "CANDIDATE" ||
                      userAuth.role === "EMPLOYER"
                    ) && (
                        <>
                          <div className="col-sm-12 col-xxl-4 col-md-6">
                            <SelectWithSearch
                              title={t("Consultant")}
                              isMandatory={false}
                              search={true}
                              options={state.dropDowndata.cosultantsList}
                              onChange={(e) =>
                                handleSelectChange(e, "consultant")
                              }
                              isMulti={false}
                              name="consultant"
                              value={state.general.consultant}
                            />
                          </div>
                          <div className="col-xxl-4 col-md-6">
                            <SelectWithSearch
                              title={t("Office")}
                              isMandatory={true}
                              search={true}
                              options={state.dropDowndata.officesList}
                              onChange={(e) => handleSelectChange(e, "office")}
                              isMulti={false}
                              name="office"
                              value={state.general.office}
                              error={state.fieldError.office}
                              isTranslate={true}
                            />
                          </div>
                        </>
                      )}
                    <div
                      className="col-xxl-2 col-md-6 mt-35"
                      style={{ paddingTop: "0.45vw" }}
                    >
                      <CheckBoxField
                        label={t("Pay roll")}
                        name="payRoll"
                        onChangeHandler={handleFieldChange}
                        isChecked={state.general.payRoll === 1}
                        lineHeight="1vw"
                      />
                    </div>
                    <div
                      className="col-xxl-2 col-md-6 mt-35"
                      style={{ paddingTop: "0.45vw" }}
                    >
                      <CheckBoxField
                        label={t("Consent to terms")}
                        name="consentToTerms"
                        onChangeHandler={handleFieldChange}
                        isChecked={state.general.consentToTerms === 1}
                        lineHeight="1vw"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <LabelField
                      title={t("Payment info")}
                      className="tab-subtitle"
                    />
                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <LabelWithInputField
                        isMandatory={false}
                        name="iban"
                        handleChange={handleFieldChange}
                        value={state.general.iban}
                        id="iban"
                        label={t("IBAN")}
                        placeholder={t("IBAN")}
                        type="text"
                        error={state.fieldError.iban}
                      />
                    </div>
                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <LabelWithInputField
                        isMandatory={false}
                        name="bic"
                        handleChange={handleFieldChange}
                        value={state.general.bic}
                        id="bic"
                        label={t("BIC")}
                        placeholder={t("BIC")}
                        type="text"
                        isDisabled={false}
                        error={state.fieldError.bic}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-start">
                      <LabelField
                        title={t("Residence address")}
                        className="tab-subtitle"
                      />
                      {/* ******* Address pop up START ********** */}
                      <div style={{ marginLeft: "1vw" }}>
                        <div className="d-flex align-items-center">
                          <Link
                            to={""}
                            title={t("Search address via google map...")}
                            onClick={handleAddPopup}
                            className="color-dark-pink marginRightPoint5"
                          >
                            <ActiveLocation />
                          </Link>
                          <Link
                            to={""}
                            className="link-color"
                            onClick={handleAddPopup}
                          >
                            {t("Search address via google map...")}
                          </Link>
                        </div>
                        <AddressSearchPopup
                          initialLat={
                            state.general.initialLat === ""
                              ? 50.8503
                              : parseFloat(state.general.initialLat)
                          } // Replace with your initial latitude
                          initialLng={
                            state.general.initialLn === ""
                              ? 4.3517
                              : parseFloat(state.general.initialLn)
                          } // Replace with your initial longitude
                          initialAddress={{
                            street: state.general.street,
                            nr: state.general.number,
                            bus: state.general.box,
                            postal: state.general.zipCode,
                            city:
                              state.general.country?.value === 29
                                ? state.general.city?.label?.replace(
                                  /^\d+\s*-\s*/,
                                  ""
                                ) || "" // Ensures a fallback empty string if `label` is undefined
                                : state.general.cityOther || "", // Fallback to an empty string if `cityOther` is undefined

                            country: state.general.country?.label || "",
                          }}
                          onConfirm={handleConfirm}
                          showAddPopup={showPopup}
                          onHide={handleClosePopup} // Pass the onHide function from the parent
                        />
                      </div>
                      {/* ******* Address pop up END ********** */}
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="street"
                        handleChange={handleFieldChange}
                        value={state.general.street}
                        id="street"
                        label={t("Street")}
                        type="text"
                        error={state.fieldError.street}
                      />
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <LabelWithInputField
                        isMandatory={true}
                        name="number"
                        handleChange={handleFieldChange}
                        value={state.general.number}
                        id="number"
                        label={t("Number")}
                        type="text"
                        error={state.fieldError.number}
                      />
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <LabelWithInputField
                        isMandatory={false}
                        name="box"
                        handleChange={handleFieldChange}
                        value={state.general.box}
                        id="box"
                        label={t("Box")}
                        type="text"
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <LabelWithInputField
                        isMandatory={true}
                        name="zipCode"
                        handleChange={handleFieldChange}
                        value={state.general.zipCode}
                        id="zipCode"
                        label={t("Zip code")}
                        type="text"
                        error={state.fieldError.zipCode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <SelectWithSearch
                        title={t("Country")}
                        isMandatory={true}
                        search={true}
                        options={state.dropDowndata.countryList}
                        onChange={(e) => handleSelectChange(e, "country")}
                        isMulti={false}
                        name="country"
                        value={state.general.country}
                        error={state.fieldError.country}
                        isTranslate={true}
                      />
                    </div>
                    {state.general.country?.value &&
                      state.general.country.value === 29 ? (
                      <div className="col-sm-12 col-md-4">
                        <SelectWithSearch
                          title={t("City")}
                          isMandatory={true}
                          search={true}
                          options={state.dropDowndata.citiesList}
                          onChange={(e) => handleSelectChange(e, "city")}
                          isMulti={false}
                          name="city"
                          value={state.general.city}
                          error={state.fieldError.city}
                          isTranslate={true}
                        />
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-4">
                        <LabelWithInputField
                          isMandatory={true}
                          name="cityOther"
                          handleChange={handleFieldChange}
                          value={state.general.cityOther}
                          id="cityOther"
                          label={t("City")}
                          error={state.fieldError.cityOther}
                          type="text"
                        />
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <CheckBoxField
                        label={t("Same as residence address")}
                        name="domCheckbox"
                        onChangeHandler={handleFieldChange}
                        isChecked={state.general.domCheckbox}
                        id="residenceAddress"
                        lineHeight="1vw"
                      />
                      <div style={{ marginTop: "1vw" }}>
                        <LabelField
                          title={t("Domicile")}
                          className="tab-subtitle"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={false}
                        name="domStreet"
                        handleChange={handleFieldChange}
                        value={state.general.domStreet}
                        isDisabled={state.general.domCheckbox}
                        id="domStreet"
                        label={t("Street")}
                        type="text"
                      />
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <LabelWithInputField
                        isMandatory={false}
                        name="domNumber"
                        handleChange={handleFieldChange}
                        value={state.general.domNumber}
                        isDisabled={state.general.domCheckbox}
                        id="domNumber"
                        label={t("Number")}
                        type="text"
                      />
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <LabelWithInputField
                        isMandatory={false}
                        name="domBox"
                        handleChange={handleFieldChange}
                        value={state.general.domBox}
                        isDisabled={state.general.domCheckbox}
                        id="domBox"
                        label={t("Box")}
                        type="text"
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <LabelWithInputField
                        isMandatory={false}
                        name="domZipCode"
                        handleChange={handleFieldChange}
                        value={state.general.domZipCode}
                        isDisabled={state.general.domCheckbox}
                        id="domZipCode"
                        label={t("Zip code")}
                        type="text"
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <SelectWithSearch
                        title={t("Country")}
                        isMandatory={false}
                        search={true}
                        options={state.dropDowndata.countryList}
                        onChange={(e) => handleSelectChange(e, "domCountry")}
                        isDisabled={state.general.domCheckbox}
                        isMulti={false}
                        name="domCountry"
                        value={state.general.domCountry}
                        isTranslate={true}
                      />
                    </div>
                    {state.general.domCountry?.value &&
                      state.general.domCountry.value === 29 ? (
                      <div className="col-sm-12 col-md-4">
                        <SelectWithSearch
                          title={t("City")}
                          isMandatory={false}
                          search={true}
                          options={state.dropDowndata.citiesList}
                          onChange={(e) => handleSelectChange(e, "domCity")}
                          isDisabled={state.general.domCheckbox}
                          isMulti={false}
                          name="domCity"
                          value={state.general.domCity}
                        />
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-4">
                        <LabelWithInputField
                          isMandatory={false}
                          name="domCityOther"
                          isDisabled={state.general.domCheckbox}
                          handleChange={handleFieldChange}
                          value={state.general.domCityOther}
                          id="domCityOther"
                          label={t("City")}
                          type="text"
                        />
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <SelectWithSearch
                        title={t("Civil status")}
                        isMandatory={false}
                        search={true}
                        options={state.dropDowndata.civilStatusList}
                        onChange={(e) =>
                          handleSelectChangeOtherInfo(e, "civilStatus")
                        }
                        isMulti={false}
                        name="civilStatus"
                        value={state.other.civilStatus}
                        isTranslate={true}
                      />
                    </div>
                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <SelectWithSearch
                        title={t("Dependent spouse")}
                        search={true}
                        options={state.dropDowndata.dependentSpouseList}
                        onChange={(e) =>
                          handleSelectChangeOtherInfo(e, "dependentSpouse")
                        }
                        isMulti={false}
                        name="dependentSpouse"
                        value={state.other.dependentSpouse}
                        isTranslate={true}
                      />
                    </div>
                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <LabelWithInputField
                        isMandatory={false}
                        name="dependentChildren"
                        handleChange={handleFieldChangeOtherInfo}
                        value={state.other.dependentChildren}
                        isDisabled={false}
                        id="dependentChildren"
                        label={t("Dependent children")}
                        type="number"
                        min={0}
                        step={1}
                      />
                    </div>
                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <SelectWithSearch
                        title={t("Educational level")}
                        isMandatory={false}
                        search={true}
                        options={state.dropDowndata.educationalLevelList}
                        onChange={(e) =>
                          handleSelectChangeOtherInfo(e, "educationalLevel")
                        }
                        isMulti={false}
                        name="educationalLevel"
                        value={state.other.educationalLevel}
                        isTranslate={true}
                      />
                    </div>
                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <SelectWithSearch
                        title={t("Language of documents")}
                        isMandatory={false}
                        search={true}
                        options={state.dropDowndata.languageOfDocumentsList}
                        onChange={(e) =>
                          handleSelectChangeOtherInfo(e, "languageOfDocuments")
                        }
                        isMulti={false}
                        name="languageOfDocuments"
                        value={state.other.languageOfDocuments}
                        isTranslate={true}
                      />
                    </div>
                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <SelectWithSearch
                        title={t("Driving licence")}
                        isMandatory={true}
                        search={true}
                        options={state.dropDowndata.drivingLicenceList}
                        onChange={(e) =>
                          handleSelectChangeOtherInfo(e, "drivingLicence")
                        }
                        isMulti={true}
                        name="drivingLicence"
                        value={state.other.drivingLicence}
                        error={state.fieldError.drivingLicence}
                        isTranslate={true}
                      />
                    </div>
                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <SelectWithSearch
                        title={t("Transport")}
                        isMandatory={false}
                        search={true}
                        options={state.dropDowndata.transportList}
                        onChange={(e) =>
                          handleSelectChangeOtherInfo(e, "transport")
                        }
                        isMulti={true}
                        name="transport"
                        value={state.other.transport}
                        isTranslate={true}
                      />
                    </div>
                    {/**Radio fields */}
                    <div className="col-sm-12 col-xxl-4 mb-4 mb-xl-0 col-md-6">
                      <div style={{ marginBottom: "0.5vw" }}>
                        <LabelField
                          title={t("I want to work as")}
                          isMandatory={false}
                        />
                      </div>
                      <div className="d-inline-block marginRight1">
                        <CheckBoxField
                          label={t("Frontier worker")}
                          name="frontier"
                          onChangeHandler={handleFieldChangeOtherInfo}
                          isChecked={state.other.workType === 0}
                          lineHeight="1vw"
                        />
                      </div>
                      <div className="d-inline-block">
                        <CheckBoxField
                          label={t("Seasonal worker")}
                          name="seasonal"
                          onChangeHandler={handleFieldChangeOtherInfo}
                          isChecked={state.other.workType === 1}
                          className="me-4"
                          lineHeight="1vw"
                        />
                      </div>
                    </div>
                    {/**Radio fields */}
                    <div className="col-sm-12 col-xxl-4 col-md-6">
                      <SelectWithSearch
                        title={t("With holding tax")}
                        isMandatory={false}
                        search={true}
                        options={state.dropDowndata.withHoldingTaxList}
                        onChange={(e) =>
                          handleSelectChangeOtherInfo(e, "withHoldingTax")
                        }
                        isMulti={false}
                        name="withHoldingTax"
                        value={state.other.withHoldingTax}
                        isDisabled={state.other.workType === 1}
                      />
                    </div>

                    <div className="col-sm-12 col-md-6 col-xxl-4 col-lg-6">
                      <SelectWithSearch
                        title={t("Additional")}
                        isMandatory={false}
                        search={true}
                        options={state.dropDowndata.additionalList}
                        onChange={(e) =>
                          handleSelectChangeOtherInfo(e, "additional")
                        }
                        isMulti={false}
                        name="additional"
                        value={state.other.additional}
                        isTranslate={true}
                      />
                    </div>
                    <div
                      className="col-lg-8 col-md-12 checkbox-section"
                      style={{ marginTop: "2.2vw" }}
                    >
                      <div className="ssn-left-sec">
                        <CheckBoxField
                          label={t("Electronic doc")}
                          name="electronicDoc"
                          onChangeHandler={handleFieldChange}
                          isChecked={state.general.electronicDoc === 1}
                          className="marginRight1"
                          id="electronicDoc"
                          lineHeight="1vw"
                        />

                        <CheckBoxField
                          label={t("E-signing")} //framework contract seasonal work
                          name="esign"
                          onChangeHandler={handleFieldChange}
                          isChecked={state.general.esign === 1}
                          className="marginRight1"
                          id="esign"
                          lineHeight="1vw"
                        />

                        <CheckBoxField
                          label={t("Framework contract seasonal work")}
                          name="contractSeasonalWorker"
                          onChangeHandler={handleFieldChange}
                          isChecked={state.general.contractSeasonalWorker === 1}
                          id="contractSeasonalWorker"
                          lineHeight="1vw"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <LabelField
                  title={t("Emergency contact")}
                  className="tab-subtitle"
                />
                <div
                  className="border"
                  style={{ borderRadius: "0.5vw", padding: "1vw" }}
                >
                  {state.emergencyContact.map((contact, index) => (
                    <div key={index}>
                      {index > 0 && (
                        <div className="row">
                          <div className="col-md-12">
                            <span
                              title={t("Delete")}
                              onClick={() => removeFieldSet(index)}
                              className="table-action-icons cursor-pointer float-end rounded-0 shadow-none"
                            >
                              <CloseFile />
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-sm-12 col-xxl-3 col-md-6">
                          <LabelWithInputField
                            isMandatory={false}
                            name="contactFirstName"
                            handleChange={(event) =>
                              handleContactChange(event, index)
                            }
                            value={contact.contactFirstName}
                            id="contactFirstName"
                            label={t("First name")}
                            placeholder={t("First name")}
                            type="text"
                            error={
                              state.emergencyContactErrors[index]
                                ?.contactFirstName
                            }
                          />
                        </div>
                        <div className="col-sm-12 col-xxl-3 col-md-6">
                          <LabelWithInputField
                            isMandatory={false}
                            name="contactLastName"
                            handleChange={(event) =>
                              handleContactChange(event, index)
                            }
                            value={contact.contactLastName}
                            id="contactLastName"
                            label={t("Last name")}
                            placeholder={t("Last name")}
                            type="text"
                            error={
                              state.emergencyContactErrors[index]
                                ?.contactLastName
                            }
                          />
                        </div>
                        <div className="col-sm-12 col-xxl-3 col-md-6">
                          <LabelWithInputField
                            isMandatory={false}
                            name="contactEmail"
                            handleChange={(event) =>
                              handleContactChange(event, index)
                            }
                            value={contact.contactEmail}
                            id="contactEmail"
                            label={t("Email")}
                            placeholder={t("Email")}
                            type="email"
                            error={
                              state.emergencyContactErrors[index]?.contactEmail
                            }
                          />
                        </div>
                        <div className="col-sm-12 col-xxl-3 col-md-6">
                          <LabelField
                            title={t("Phone number")}
                            isMandatory={false}
                            key="PhoneInput"
                          />
                          <div
                            className="form-control field-shadow d-flex"
                            style={{ borderRadius: "0.5vw" }}
                          >
                            <PhoneInput
                              defaultCountry="BE" //Belgium country code
                              international
                              placeholder={t("Enter phone number")}
                              onChange={(mobNumber) =>
                                handleContactPhoneNumberChange(
                                  mobNumber!,
                                  index
                                )
                              }
                              value={contact.contactPhone}
                              className="w-100"
                              readOnly={isViewMode}
                            />
                          </div>
                          <div
                            className="height-20"
                            style={{ marginBottom: "0.5vw" }}
                          >
                            {state.emergencyContactErrors[index]
                              ?.contactPhone && (
                              <span className="text-danger">
                                {
                                  state.emergencyContactErrors[index]
                                    ?.contactPhone
                                }
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="row">
                    <div className="col-md-12">
                      <Button
                        title={"+ " + t("Add another")}
                        handleClick={addFieldSet}
                        className="form-button float-end shadow-none"
                      />
                    </div>
                  </div>
                </div>
                <div className="disclaimerText">
                  {t(
                    "Any form of discrimination based on sex, perceived race, colour, descent, nationality or ethnic origin, sexual orientation, marital status, birth, property, age, religious or philosophical beliefs, current or future state of health, disability or physical characteristic is prohibited."
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Navigation validStatus={validStatus} />
      </form>
      <ModalPopup
        size="lg"
        show={SSNorEmailModal}
        onHide={closeModal}
        title={modalTitle}
        body={emailOrSSNModalBody}
        className="modal-lg"
        onConfirmButtonClick={handleCandidate}
        confirmTitle={canId ? t("Yes") : false}
      />
    </>
  );
};
export default translate(GeneralTab);
