import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LabelField from "../atoms/LabelField";
import "static/css/calender.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

interface CalenderProps {
  onChange: (date: Date | null, name: string) => void;
  selectedDate?: Date | null;
  label?: string;
  isMandatory?: boolean;
  name?: string;
  error?: string | undefined | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  isDisabled?: boolean;
  placeHolder?: string;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  filterDate?: (date: Date) => boolean;
  onCalendarClick?: (date: Date | null, name: string) => void; //to notify parent when calendar is clicked
}

const Calender: React.FC<CalenderProps> = ({
  onChange,
  selectedDate,
  label,
  isMandatory = false,
  name = "",
  error,
  minDate = null,
  maxDate = null,
  isDisabled = false,
  placeHolder = "dd-mm-yyyy",
  filterDate,
  showMonthDropdown = true,
  showYearDropdown = true,
  onCalendarClick,
}) => {
  const datePickerRef = useRef<ReactDatePicker | null>(null);
  const popperContainer = ({ children }: any) => {
    return ReactDOM.createPortal(children, document.body);
  };

  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current?.setOpen(true);
    }
  };

  const [dateFlags, setDateFlags] = useState({
    isUserDeleting: false,
    isUserTyping: false,
    isClicked: false,
    inputValue: ""
  });

  // Formats the input as dd-mm-yyyy
  const formatInput = (value: string): string => {
    let digits = value.replace(/\D/g, "").slice(0, 8);

    if (digits.length >= 2) digits = `${digits.slice(0, 2)}-${digits.slice(2)}`;
    if (digits.length >= 5) digits = `${digits.slice(0, 5)}-${digits.slice(5)}`;

    return digits;
  };

  useEffect(() => {
    if ((!dateFlags.isUserDeleting && !dateFlags.isUserTyping)) {
      if (selectedDate) {
        const dateStr = selectedDate.toLocaleDateString("en-GB").replace(/\//g, "-");
        setDateFlags((prev) => ({
          ...prev,
          inputValue: formatInput(dateStr),
        }));
      } else {
        setDateFlags((prev) => ({
          ...prev,
          inputValue: "",
        }));
      }
    }
    if (dateFlags.isClicked) {
      setErrorMessage(null);
      if (selectedDate) {
        const dateStr = selectedDate.toLocaleDateString("en-GB").replace(/\//g, "-");
        setDateFlags((prev) => ({
          ...prev,
          inputValue: formatInput(dateStr),
        }));
        if (onCalendarClick) {
          onCalendarClick(selectedDate, name); // Notify parent component when date is clicked
        }
      }

      setDateFlags((prev) => ({
        ...prev,
        isClicked: false,
      }));
    }
  }, [selectedDate, dateFlags.isClicked]);

  // Handle raw text input (keyboard input)
  const handleRawInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nativeEvent = event.nativeEvent as InputEvent;
    let newRawValue = event.target.value || "";
    if (event.type === 'change') {
      setDateFlags((prev) => ({
        ...prev,
        isUserTyping: true,
      }));

      // Detect user Backspace action
      if (nativeEvent.inputType === "deleteContentBackward") {
        setDateFlags((prev) => ({
          ...prev,
          isUserDeleting: true,
        }));

        if (dateFlags?.inputValue.endsWith("-")) {
          newRawValue = dateFlags?.inputValue.slice(0, -2);
        }
      } else {
        setDateFlags((prev) => ({
          ...prev,
          isUserDeleting: false,
        }));
      }

      const formatted = formatInput(newRawValue);
      setDateFlags((prev) => ({
        ...prev,
        inputValue: formatted,
      }));

      // Parse date only if it is fully formatted
      if (formatted.length === 10) {
        let [day, month, year] = formatted.split("-").map((part) => parseInt(part, 10));
        if (year.toString().length === 4) {
          const parsedDate = new Date(year, month - 1, day);
          const isValidDate =
            parsedDate.getDate() === day &&
            parsedDate.getMonth() === month - 1 &&
            parsedDate.getFullYear() === year;
          if (!isValidDate) {
            setErrorMessage("Invalid date");
            onChange(null, name);
          } else if (!isNaN(parsedDate.getTime())) {
            setErrorMessage(null); // Clear error if date is valid
            onChange(parsedDate, name);
          } else {
            setErrorMessage(null);
            onChange(null, name);
          }
        } else {
          setErrorMessage(null);
          onChange(null, name);
        }
      } else {
        setErrorMessage(null);
        onChange(null, name);
      }
    } else if (event.type === 'click') {
      setDateFlags((prev) => ({
        ...prev,
        isClicked: true,
      }));
    }
  };

  return (
    <>
      <LabelField title={label} isMandatory={isMandatory} />
      {/* <div className="position-relative"> */}
      <ReactDatePicker
        selected={selectedDate}
        onChange={(date) => onChange(date, name || "")}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        dropdownMode="select"
        dateFormat="dd-MM-yyyy"
        className="form-control field-shadow ps-2 position-relative date-picker-input"
        placeholderText={placeHolder}
        minDate={minDate ? new Date(minDate) : null}
        maxDate={maxDate ? new Date(maxDate) : null}
        calendarStartDay={1}
        name={name}
        closeOnScroll={true}
        fixedHeight
        autoComplete="off"
        ref={datePickerRef}
        disabled={isDisabled}
        filterDate={filterDate}
        popperPlacement="bottom-start"
        popperClassName="custom-datepicker-popper"
        title={selectedDate ? selectedDate.toLocaleDateString() : ""}
        onChangeRaw={handleRawInputChange}
        value={dateFlags.inputValue}
      />

      <div className="input-group-append calender-icon position-absolute ">
        <span
          className="input-group-text bg-transparent border-0"
          onClick={handleIconClick}
        >
          <FontAwesomeIcon icon={faCalendarAlt} className="fa-lg" />
        </span>
      </div>
      {/* </div> */}
      <div className="height-20" style={{ marginBottom: "0.5vw" }}>
        {error ? (error && <span className="text-danger mt-2">{error}</span>) :
          (errorMessage && <span className="text-danger mt-2">{errorMessage}</span>)}
      </div>
    </>
  );
};

export default Calender;
