import React, { useEffect, useState } from "react";
import Navigation from "../form-navigation/Navigation";
import { useFormContext } from "../../context/Context";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { t } from "components/CentralDataMangement/translation/Translation";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import LabelField from "components/common/atoms/LabelField";
import InputTextfield from "components/common/atoms/InputTextField";
import DeviatingCoefficients from "./DeviatingCoefficients";
import {
  BILLING_DETAILS,
  UPDATE_FIELDS,
} from "../../annotation/PlanningConstants";
import InfoIcon from "static/images/InfoIcon";
import { fetchProfileDetails } from "../form-data/SetProfileData";
import { resetCommercialProfileDetails } from "../form-data/ResetPlanningData";
import { useParams } from "react-router-dom";

const BillingTab: React.FC = () => {
  const { state, dispatch } = useFormContext();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const { planningId } = useParams<{ planningId: string }>();

  useEffect(() => {
    if(planningId == undefined) {
      dispatch({
        type: UPDATE_FIELDS,
        tab: BILLING_DETAILS,
        field: "coeffType",
        value: 2,
      });
    }
  }, [planningId]);

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    dispatch({
      type: UPDATE_FIELDS,
      tab: BILLING_DETAILS,
      field: fieldName,
      value: selectedOption,
    });
    if (fieldName === "commercialProfile") {
      resetCommercialProfileDetails(dispatch);
    }
    if (
      fieldName === "commercialProfile" &&
      selectedOption?.value !== undefined &&
      selectedOption?.value !== null
    ) {
      fetchProfileDetails(
        "planningProfile",
        selectedOption.value,
        state,
        dispatch
      );
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    let updatedValue: string | number = value;

    // List of fields that should only allow numbers and commas
    const numberFields = [
      "coefficient",
      "relocationCoeff",
      "dimonaCost",
      "echoCheqCoeff",
      "mealVochrCoeff",
      "holidayCoeff",
      "payrollCoefficient",
      "reduceCoefficient",
    ];

    // Handle checkboxes (including mutually exclusive logic)
    if (type === "checkbox") {
      if (
        name === "payRoll" ||
        name === "reducedRate" ||
        name === "selection"
      ) {
        // Mutually exclusive checkbox logic
        let coeffType;

        switch (name) {
          case "payRoll":
            coeffType = checked ? 0 : null; // Assign 0 if checked, null otherwise
            break;
          case "reducedRate":
            coeffType = checked ? 1 : null; // Assign 1 if checked, null otherwise
            break;
          case "selection":
            coeffType = checked ? 2 : null; // Assign 2 if checked, null otherwise
            break;
          default:
            coeffType = state.billingDetails.coeffType;
        }

        // Update the state with the selected checkbox, ensuring only one can be checked
        dispatch({
          type: UPDATE_FIELDS,
          tab: BILLING_DETAILS,
          field: "coeffType",
          value: coeffType,
        });
      } else {
        dispatch({
          type: UPDATE_FIELDS,
          tab: BILLING_DETAILS,
          field: name,
          value: checked ? 1 : 0,
        });
      }
    } else if (numberFields.includes(name)) {
      // Apply number and comma filtering for specified fields
      updatedValue = value.replace(/[^0-9,]/g, "");
      dispatch({
        type: UPDATE_FIELDS,
        tab: BILLING_DETAILS,
        field: name,
        value: updatedValue,
      });
    } else {
      // For all other non-checkbox fields
      dispatch({
        type: UPDATE_FIELDS,
        tab: BILLING_DETAILS,
        field: name,
        value: updatedValue,
      });
    }
  };

  return (
    <>
      <div className="form-border" style={{ paddingTop: "3.4vw" }}>
        <div className="row">
          <div className="col-md-12">
            <SelectWithSearch
              title={t("Commercial profile")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.pcCommercialProfileOptions}
              onChange={(e) => handleSelectChange(e, "commercialProfile")}
              isMulti={false}
              name="commercialProfile"
              value={state.billingDetails.commercialProfile}
              id="commercialProfile"
              isTranslate={true}
            />
          </div>
          <div className="col-2">
            <CheckBoxField
              label={t("Pay roll")}
              name="payRoll"
              onChangeHandler={handleFieldChange}
              isChecked={state.billingDetails.coeffType === 0}
              lineHeight="1vw"
              id="payRoll"
            />
          </div>
          <div className="col-2">
            <CheckBoxField
              label={t("Reduced rate")}
              name="reducedRate"
              onChangeHandler={handleFieldChange}
              isChecked={state.billingDetails.coeffType === 1}
              lineHeight="1vw"
              id="reducedRate"
            >
              <span
                className="contractBillingInfo ms-2"
                title={
                  t(
                    "“Reduced rate” indicates that the coefficient of the contract has decreased. For example, after an extended period of employment"
                  ) + "."
                }
              >
                <InfoIcon />
              </span>
            </CheckBoxField>
          </div>
          <div className="col-2">
            <CheckBoxField
              label={t("Selection")}
              name="selection"
              onChangeHandler={handleFieldChange}
              isChecked={state.billingDetails.coeffType === 2}
              lineHeight="1vw"
              id="selection"
            />
          </div>
          <div className="col-2">
            <CheckBoxField
              label={t("Dimona invoice")}
              name="dimonaInvoice"
              onChangeHandler={handleFieldChange}
              isChecked={state.billingDetails.dimonaInvoice === 1}
              lineHeight="1vw"
              id="dimonaInvoice"
            />
          </div>
          <div className="col-3">
            <CheckBoxField
              label={t("Invoice ecocheques weekly")}
              name="invoiceEcho"
              onChangeHandler={handleFieldChange}
              isChecked={state.billingDetails.invoiceEcho === 1}
              lineHeight="1vw"
              id="invoiceEcho"
            />
          </div>
          <div className="col-lg-4 mt-3">
            <LabelWithInputField
              isMandatory={false}
              name={
                state.billingDetails.coeffType === 0
                  ? "payrollCoefficient"
                  : state.billingDetails.coeffType === 1
                  ? "reduceCoefficient"
                  : state.billingDetails.coeffType === 2
                  ? "selectionCoefficient"  
                  : "coefficient"
              }
              handleChange={handleFieldChange}
              value={
                state.billingDetails.coeffType === 0
                  ? state.billingDetails.payrollCoefficient
                  : state.billingDetails.coeffType === 1
                  ? state.billingDetails.reduceCoefficient
                  : state.billingDetails.coeffType === 2
                  ? state.billingDetails.selectionCoefficient
                  : state.billingDetails.coefficient
              }
              id="coefficient"
              label={t("Coefficient")}
              placeholder={t("coefficient")}
            />
          </div>
          <div className="col-lg-4 mt-3">
            <LabelWithInputField
              isMandatory={false}
              name="relocationCoeff"
              handleChange={handleFieldChange}
              value={state.billingDetails.relocationCoeff}
              id="relocationCoeff"
              label={t("Coefficient of relocation allowance")}
              placeholder={t("Coefficient of relocation allowance")}
            />
          </div>
          <div className="col-lg-4 mt-3">
            <LabelField title={t("Dimona cost")} isMandatory={false} />
            <div className="input-group field-shadow rounded-3 remunerationInputGroup">
              <InputTextfield
                name="dimonaCost"
                handleChange={handleFieldChange}
                value={state.billingDetails.dimonaCost}
                type="text"
                className="form-control shadow-none border-0 rounded-start-3 rounded-end-0"
              />
              <span
                className="textAppendBg input-group-text justify-content-center field-shadow border-0"
                id="basic-addon1"
              >
                {"€"}
              </span>
            </div>
          </div>
          <div className="col-lg-4">
            <LabelWithInputField
              isMandatory={false}
              name="echoCheqCoeff"
              handleChange={handleFieldChange}
              value={state.billingDetails.echoCheqCoeff}
              id="echoCheqCoeff"
              label={t("Ecocheques coefficient")}
              placeholder={t("Ecocheques coefficient")}
            />
          </div>
          <div className="col-lg-4">
            <SelectWithSearch
              title={t("Bank holiday")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.bankHolidayOptions}
              onChange={(e) => handleSelectChange(e, "bankHoliday")}
              isMulti={false}
              name="bankHoliday"
              value={state.billingDetails.bankHoliday}
              id="bankHoliday"
              isTranslate={true}
            />
          </div>
          <div className="col-lg-4">
            <LabelWithInputField
              isMandatory={false}
              name="holidayCoeff"
              handleChange={handleFieldChange}
              value={state.billingDetails.holidayCoeff}
              id="holidayCoeff"
              label={t("Holiday coefficient")}
              placeholder={t("Holiday coefficient")}
            />
          </div>
          <div className="col-lg-4">
            <SelectWithSearch
              title={t("Disease")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.diseaseOptions}
              onChange={(e) => handleSelectChange(e, "disease")}
              isMulti={false}
              name="disease"
              value={state.billingDetails.disease}
              id="disease"
              isTranslate={true}
            />
          </div>
          <div className="col-lg-4">
            <SelectWithSearch
              title={t("Standard VAT rate")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.vatOptions}
              onChange={(e) => handleSelectChange(e, "standardVatRate")}
              isMulti={false}
              name="standardVatRate"
              value={state.billingDetails.standardVatRate}
              id="standardVatRate"
              isTranslate={true}
            />
          </div>
          <div className="col-lg-4">
            <LabelWithInputField
              isMandatory={false}
              name="mealVochrCoeff"
              handleChange={handleFieldChange}
              value={state.billingDetails.mealVochrCoeff}
              id="mealVochrCoeff"
              label={t("Meal voucher coefficient")}
              placeholder={t("Meal voucher coefficient")}
            />
          </div>
          <div className="col-12">
            <DeviatingCoefficients />
          </div>
        </div>
      </div>
      <Navigation validStatus={validStatus} />
    </>
  );
};

export default BillingTab;
