import Title from "components/common/atoms/Title";
import { ApiCall } from "components/common/services/ApiServices";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";
import { MANAGE_ADMIN_FICHE } from "routes/ApiEndpoints";
import { COMPANY_MICROSERVICE } from "Constants";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PdfIcon from "static/images/Pdf";
import "../../CooperationAgreement/css/Agreement.css";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import GetQueryParams from "components/common/services/GetQueryParams";
import {
  createQueryParams,
  filterAndMerge,
  getQueryParamsFromRedux,
} from "components/common/utlis/HelperFunctions";
import { createFilterConfig } from "./Filter";
import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import PaginationNew from "components/common/atoms/PaginationNew";
import SearchFields from "components/common/atoms/SearchFields";
import ResetBtn from "components/common/atoms/ResetBtn";
import Sorting from "components/common/atoms/Sorting";

interface AdminFicheProps {
  id: number;
  company_id: number;
  companyName: string;
  first_name: string;
  last_name: string;
  saveAsDraft: number;
  file_path: string;
  approved: number;
  unitType: string;
  coeffType: number;
  sendToEmployer: number | null;
  office: string;
  adminFicheId: number | null;
}

interface SearchProps {
  office: number[];
  companyName: string;
  status: number | null;
  salesAgent: Option | null;
  unit: number | null;
  types: number | null;
  orderByColumn: string;
  orderIn: "" | "asc" | "desc"; // Ensure correct typing here
  currentPage: number;
  recordsPerPage: number;
  archiveStatus: number | null;
}

const ManageAdminFiche: React.FC = () => {
  const authUser = useSelector(selectAuth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState<AdminFicheProps[]>([]);
  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    totalRecords: 0,
  });

  const [dropdowns, setDropdowns] = useState({
    agents: [] as OptionProps[],
    status: [] as OptionProps[],
    offices: [] as OptionProps[],
    units: [] as OptionProps[],
    types: [] as OptionProps[],
  });

  const destinationPath = useSelector(
    (state: RootState) => state.navigation.destinationPath
  );

  const getUrlParams = GetQueryParams();
  const paramFilter = getUrlParams.get("filter");
  const approvalFilter = getUrlParams.get("approval");
  let approvalFilterData = {};
  if (approvalFilter) {
    dispatch(clearDestinationPath());
    const unit = {
      value: Number(getUrlParams.get("unit_value")), // Ensure it's a number
      label: getUrlParams.get("unit_label"),
    };

    approvalFilterData = {
      company: getUrlParams.get("company"),
      unit: unit, // Keep unit as an object
    };
  }
  const queryParams = getQueryParamsFromRedux(destinationPath, paramFilter);

  const filters = createFilterConfig(dropdowns);
  // Initialize search data based on query params
  const defaultSearchData: SearchProps = {
    companyName: "",
    // office: authUser?.role === CONST.RECRUITER || authUser?.role === CONST.OFFICE_MANAGER ? [authUser?.officeId, authUser?.correspondingOffice?.id] : [],
    status: null,
    salesAgent: null,
    unit: null,
    types: null,
    orderByColumn: "",
    orderIn: "",
    currentPage: 1,
    recordsPerPage: 10,
    archiveStatus: null,
    office: [],
  };

  const filteredSearchData = filterAndMerge(defaultSearchData, queryParams);

  // Initialize search data based on query params, merging with default values
  const [searchData, setSearchData] = useState<SearchProps>({
    ...defaultSearchData,
    ...(approvalFilter ? approvalFilterData : filteredSearchData),
  });

  useEffect(() => {
    dispatch(clearDestinationPath());
    const filterData = { ...searchData };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchAdminFicheDetais({ ...searchData });
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  const fetchAdminFicheDetais = async (filterData: any) => {
    setLoading(true);
    const data = {
      search: filterData,
    };

    const response = await ApiCall.service(
      MANAGE_ADMIN_FICHE,
      "POST",
      data,
      false,
      COMPANY_MICROSERVICE
    );
    if (response.status === 200) {
      const { apiData, dropdownData, paginationData } = response.data;
      setApiData(apiData);
      setPaginationData(paginationData);
      const usersList = dropdownData.agents.map((item: any) => ({
        value: item.userId,
        label: `${item.first_name} ${item.last_name}`,
      }));

      setDropdowns((prevData) => ({
        ...prevData,
        ...dropdownData,
        agents: usersList,
      }));
    }
    setLoading(false);
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      fromDashboard: false,
      [name]: value,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: pageNumber,
    }));
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      fromDashboard: false,
      [fieldName]: selectedOption,
    }));
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setSearchData((prev) => ({
      ...prev,
      recordsPerPage: newItemsPerPage,
      currentPage: 1,
    }));
  };

  const handleReset = async (e: React.FormEvent) => {
    dispatch(clearDestinationPath());
    setSearchData({ ...defaultSearchData, office: [] });
  };

  const handleOrderByColumn = (column: string) => {
    let newOrder: "" | "asc" | "desc" = ""; // Explicit type declaration
    let newColumn = column;

    if (searchData.orderByColumn === column) {
      // Cycle through the three possible order values: '', 'asc', 'desc'
      if (searchData.orderIn === "") {
        newOrder = "asc";
      } else if (searchData.orderIn === "asc") {
        newOrder = "desc";
      } else {
        newOrder = "";
        newColumn = "";
      }
    } else {
      newOrder = "asc";
    }

    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      orderByColumn: newColumn,
      orderIn: newOrder,
    }));
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Admin fiche",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className={`main-container rightSidebar`}>
        <Title title={t("Admin agreements")} />
        <div className="form-height-dashboard">
          <div className="table-responsive tableSection">
            <table className="table manageAdminFiches border-0 mb-0">
              <thead>
                <tr className="tableSearchFields">
                  <SearchFields
                    searchData={searchData}
                    filters={filters}
                    handleSearchChange={handleSearchChange}
                    handleSelectChange={handleSelectChange}
                  />
                  <th className="border-0">
                    <div className="d-flex align-items-center w-100">
                      <ResetBtn handleResetClick={handleReset} />
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
            <div className="tableContainer scrollBarDesign">
              <table className="table table-hover manageAdminFiches">
                <thead>
                  <tr className="TableHeader">
                    <th onClick={() => handleOrderByColumn("office")}>
                      {t("Office")}
                      <Sorting
                        sortType={
                          searchData.orderByColumn === "office"
                            ? searchData.orderIn
                            : ""
                        }
                      />
                    </th>
                    <th onClick={() => handleOrderByColumn("company")}>
                      {t("Company")}
                      <Sorting
                        sortType={
                          searchData.orderByColumn === "company"
                            ? searchData.orderIn
                            : ""
                        }
                      />
                    </th>
                    <th>{t("Unit")}</th>
                    <th>{t("Type")}</th>
                    <th onClick={() => handleOrderByColumn("sales")}>
                      {t("Sales agent")}
                      <Sorting
                        sortType={
                          searchData.orderByColumn === "sales"
                            ? searchData.orderIn
                            : ""
                        }
                      />
                    </th>
                    <th>{t("Status")}</th>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "Admin fiche",
                          update: true,
                          delete: true,
                        },
                      ]}
                      actions={true}
                      strict={false}
                    >
                      <th>{t("Action")}</th>
                    </AccessControl>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <SpinnerWrapper headerLength={7} />
                  ) : (
                    <>
                      {apiData && apiData.length > 0 ? (
                        apiData.map((item: AdminFicheProps, index: number) => (
                          <tr
                            key={index}
                            className="border-bottom mb-3 box-shadow align-middle"
                          >
                            <td className="d-block d-md-block d-lg-none px-0 pwa-status">
                              <table>
                                <tbody>
                                  <tr>
                                    <td className="text-break px-0">
                                      <OverlayTrigger
                                        placement="top"
                                        trigger="click"
                                        rootClose
                                        overlay={
                                          <Tooltip>
                                            {item.approved === 1
                                              ? t("Active")
                                              : item.sendToEmployer === 1
                                              ? t("Sent to Employer")
                                              : t("Pending")}
                                          </Tooltip>
                                        }
                                      >
                                        <div>
                                          <span
                                            className={`d-inline-block status-icon ${
                                              item.approved === 1
                                                ? "bg-success"
                                                : item.sendToEmployer === 1
                                                ? "bg-primary"
                                                : "bg-warning"
                                            }`}
                                          />
                                          <span className="info-icon">
                                            <FontAwesomeIcon
                                              icon={faCircleInfo}
                                              className="ms-2"
                                            />
                                          </span>
                                        </div>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              className="text-break ps-lg-4"
                              data-label={t("Office")}
                            >
                              {item.office}
                            </td>
                            <td
                              className="text-break"
                              data-label={t("Company")}
                            >
                              {item.companyName}
                            </td>
                            <td className="text-break" data-label={t("Unit")}>
                              {t(`${item.unitType}`)}
                            </td>
                            <td className="text-break" data-label={t("Type")}>
                              {item.coeffType === 1
                                ? t("Fee based")
                                : t("Coefficient")}
                            </td>
                            <td
                              className="text-break"
                              data-label={t("Sales agent")}
                            >
                              {item.first_name
                                ? item.first_name + " " + item.last_name
                                : ""}
                            </td>
                            <td
                              className="text-break ps-4"
                              data-label={"status"}
                            >
                              <span
                                data-toggle="tooltip"
                                className={`d-inline-block rounded-circle status-icon ${
                                  item.approved === 1
                                    ? "bg-success"
                                    : item.sendToEmployer === 1
                                    ? "bg-primary"
                                    : "bg-warning"
                                }`}
                                title={
                                  item.approved === 1
                                    ? t("Active")
                                    : item.sendToEmployer === 1
                                    ? t("Sent to Employer")
                                    : t("Pending")
                                }
                              ></span>
                            </td>
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Admin fiche",
                                  update: true,
                                  delete: true,
                                },
                              ]}
                              actions={true}
                              strict={false}
                            >
                              <td className="table-action-icons px-2">
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "Admin fiche",
                                      read: true,
                                    },
                                  ]}
                                >
                                  {authUser.role === "EMPLOYER" ? (
                                    item.adminFicheId && (
                                      <Link
                                        to={`/admin-fiche/preview/${item.id}/${item.adminFicheId}?companyId=${item.company_id}`}
                                        className="btn p-0 border-0 me-2"
                                        title={t("Admin fiche")}
                                      >
                                        <EditIcon />
                                      </Link>
                                    )
                                  ) : (
                                    <Link
                                      to={`/admin-fiche/create/${item.id}${
                                        item.adminFicheId
                                          ? `/${item.adminFicheId}`
                                          : ""
                                      }`}
                                      className="btn p-0 border-0 me-2"
                                      title={t("Admin fiche")}
                                    >
                                      <EditIcon />
                                    </Link>
                                  )}
                                </AccessControl>
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "Admin fiche",
                                      read: true,
                                    },
                                  ]}
                                >
                                  {item.approved !== null && (
                                    <div className="d-none d-md-none d-lg-inline-block">
                                      <Link
                                        to={item.file_path}
                                        // {`${process.env.REACT_APP_COMPANY_SERVICE}/api/agreement/download/${item.company_id}/${item.id}`}
                                        className="btn p-0 border-0 me-2"
                                        title={t("Download pdf")}
                                        target="_blank"
                                      >
                                        <PdfIcon />
                                      </Link>
                                    </div>
                                  )}
                                </AccessControl>
                              </td>
                            </AccessControl>
                          </tr>
                        ))
                      ) : (
                        <NoRecords headerLength={7} />
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {!loading && (
              <div className="pagination d-block">
                <PaginationNew
                  currentPage={searchData.currentPage}
                  totalPages={paginationData.totalPages}
                  totalRecords={paginationData.totalRecords}
                  recordsPerPage={searchData.recordsPerPage}
                  onItemsPerPageChange={handleItemsPerPageChange}
                  onPageChange={(newPage: any) => handlePageChange(newPage)}
                />
              </div>
            )}
          </div>
        </div>

        <div
          className="col-md-6 align-self-center"
          style={{ padding: "1vw 0" }}
        >
          <Link
            to="/manage-companies"
            className=" back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(ManageAdminFiche);
