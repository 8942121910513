import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import { ApiCall } from "components/common/services/ApiServices";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  COOP_PREVIEW_BACKEND,
  MANAGE_COOPERATION_AGREEMENT,
} from "routes/RouteConstants";
import * as ENDPOINTS from "../../../../../routes/ApiEndpoints";
import { COMPANY_MICROSERVICE } from "Constants";
import ModalPopup from "components/common/atoms/ModalPopup";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  validateEmail,
  validateFileId,
  validateForm,
  validatePassword,
  validateRequired,
  validateSelectField,
} from "components/common/services/ValidationService";
import EncryptDecryptService from "components/common/services/EncryptDecryptService";
import CustomNotify from "components/common/atoms/CustomNotify";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import secureLocalStorage from "react-secure-storage";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import FileUpload from "components/common/molecules/FileUpload";
import GetQueryParams from "components/common/services/GetQueryParams";
import SignAsEmployerModal from "components/common/utlis/SignAsEmployerModal";

interface Preview {
  iframeUrl: string;
  acceptTerms: number;
  // employerSign: boolean;
  // signAsEmployerPopup: boolean;
  employerVerify: boolean;
  email: string;
  password: string;
  employerId: number | null;
  selectedContact: null;
  addSignature: boolean;
  contacts: any[];
}

const CoopAgreementPreview = () => {
  const { agreementId } = useParams<{ agreementId: string }>();
  const { companyId } = useParams<{ companyId: string }>();
  const userAuth = useSelector(selectAuth);
  const employer = userAuth.role === "EMPLOYER";
  const navigate = useNavigate();
  const lang_key = secureLocalStorage.getItem("site_lang") ?? "en";
  const queryParams = GetQueryParams();
  const feeBased = queryParams.get("coeff_type");

  const initialState = {
    iframeUrl: `${process.env.REACT_APP_COMPANY_SERVICE}/${COOP_PREVIEW_BACKEND}/${companyId}/${agreementId}?lang=${lang_key}`,
    acceptTerms: 0,
    // employerSign: true,
    // signAsEmployerPopup: false,
    employerVerify: employer ?? false,
    email: "",
    password: "",
    employerId: null,
    contacts: [],
    selectedContact: null,
    addSignature: false,
  };
  const [state, setState] = useState<Preview>(initialState);
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );
  const [signAsEmployer, setSignAsEmployer] = useState(false);
  const [sendToEmployer, setSendToEmployer] = useState(false);

  //File Upload section
  const [isDocumentModalOpen, setDocumentModalOpen] = useState(false);
  const [documentData, setDocumentData] = useState({
    documentName: "",
    documentCategory: { value: 16, label: "Cooperation Agreement" },
    documentId: null,
    fileName: "",
    filePath: "",
    extension: "",
    selectedContact: null,
  });
  const fileUploadStyleObject: React.CSSProperties = {
    width: "100%",
  };
  const initialDocumentErrors: { [key: string]: string } = {};
  const [documentErrors, setDocumentErrors] = useState<{
    [key: string]: string;
  }>(initialDocumentErrors);

  // const handleModalClose = (type: string) => {
  //   if (type === "employerSign") {
  //     setState((prevData) => ({
  //       ...prevData,
  //       signAsEmployerPopup: false,
  //     }));
  //   } else {
  //   setState((prevData) => ({
  //     ...prevData,
  //     employerSign: true,
  //   }));
  //   }
  // };

  const validation = (
    name: string,
    value: string | boolean | number | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      email: name === 'contacts' ? [] : [validateRequired, validateEmail],
      password: name === 'contacts' ? [] : [validateRequired, validatePassword],
      contacts: name === 'contacts' ? [validateSelectField] : []
    };

    const validationErrors = validateForm(
      { ...state, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    let updatedValue: string | boolean | number;
    if (type === "checkbox") {
      updatedValue = checked ? 1 : 0;
      if (updatedValue === 0) {
        setState(initialState);
      }
    } else {
      updatedValue = value;
    }
    setState((prevData) => ({
      ...prevData,
      [name]: updatedValue,
      iframeUrl: `${process.env.REACT_APP_COMPANY_SERVICE}/${COOP_PREVIEW_BACKEND}/${companyId}/${agreementId}?employer=${state.employerId}&lang=${lang_key}`,
    }));
    validation(name, value, true);
  };

  // const checkEmployerSign = async () => {
  //   const url = `${ENDPOINTS.CHECK_EMPLOYER_SIGN}/${agreementId}`;
  //   const response = await ApiCall.getService(
  //     url,
  //     "GET",
  //     COMPANY_MICROSERVICE,
  //     true
  //   );

  //   if (response.status === 200) {
  //     const data = response.data;
  //     const employerId = data !== null ? data["employerId"] : null;
  //     setState((prevData) => ({
  //       ...prevData,
  //       // employerSign: data !== null,
  //       // signAsEmployerPopup: data !== null,
  //       email: data !== null && data["employerMail"],
  //       employerId: employerId,
  //       iframeUrl: `${process.env.REACT_APP_COMPANY_SERVICE}/${COOP_PREVIEW_BACKEND}/${companyId}/${agreementId}?employer=${employerId}&verify=0&lang=${lang_key}`,
  //     }));
  //   }
  // };

  const authenticateEmployer = async (event: React.FormEvent) => {
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;
    const formData = {
      email: state.email,
      password: EncryptDecryptService.encryptData(
        JSON.stringify(state.password)
      ),
      agreementId: agreementId,
      companyId: companyId,
    };
    if (validation(name, value)) {
      const response = await ApiCall.service(
        ENDPOINTS.AUTHENTICATE_EMPLOYER,
        "POST",
        formData,
        true,
        COMPANY_MICROSERVICE
      );

      if (response.status === 200) {
        setState((prevData) => ({
          ...prevData,
          employerVerify: true,
          // signAsEmployerPopup: false,
          iframeUrl: `${process.env.REACT_APP_COMPANY_SERVICE}/${COOP_PREVIEW_BACKEND}/${companyId}/${agreementId}?employer=${state.employerId}&verify=1&lang=${lang_key}`,
        }));
        setErrors({
          email: "",
        });
        setSignAsEmployer(!signAsEmployer);
      } else {
        const data = response.data;
        setErrors({
          email: data,
        });
      }
    }
  };

  const handleSubmit = async (toEmployer: boolean) => {
    if (toEmployer && !validation('contacts', state.selectedContact)) {
      return;
    }
    const formData = {
      approved: toEmployer ? false : true,
      agreementId: agreementId,
      companyId: companyId,
      sendToemployer: toEmployer,
      employerId: employer ? userAuth.userId : state.employerId,
      salesId: !employer ? userAuth.userId : null,
      email: employer ? userAuth.email : state.email,
      lang_key: lang_key,
      loggedInUserId: userAuth.userId,
    };

    const response = await ApiCall.service(
      ENDPOINTS.AUTHENTICATE_EMPLOYER,
      "POST",
      formData,
      true,
      COMPANY_MICROSERVICE
    );

    if (response.status === 200) {
      CustomNotify({
        type: "success",
        message: t("Mail sent to employer successfully"),
      });
      navigate(MANAGE_COOPERATION_AGREEMENT);
    } else {
      const data = response.data;
      // if (data) {
      //   // setState((prevData) => ({
      //   //   ...prevData,
      //   //   employerSign: false,
      //   // }));
      // }
      // if (response?.message === "Please add signature") {
      CustomNotify({ type: "error", message: t(data) });
      // } else {
      //   !data &&
      //     CustomNotify({ type: "error", message: t("Some error occured!!") });
      // }
    }
  };

  // File upload section
  const documentValidation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      documentName: [validateRequired],
      documentCategory: [validateSelectField],
      documentId: [validateFileId],
      selectedContact: [validateSelectField],
    };
    const validationErrors = validateForm(
      { ...documentData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setDocumentErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setDocumentErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const closeDocumentModal = () => {
    setDocumentModalOpen(false);
    resetDocumentFields();
    resetDocumentErrors();
  };

  const handleDocumentFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    let updatedValue: string | number | null = value;
    setDocumentData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }));
    documentValidation(name, value, true);
  };

  const handleDocumentSelectChange = (
    selectedOption: any,
    fieldName: string
  ) => {
    setDocumentData((prevState) => ({
      ...prevState,
      [fieldName]: selectedOption,
    }));
    documentValidation(fieldName, selectedOption, true);
  };

  const documentUploadResponse = (responseObj: any) => {
    const response = responseObj["response"];
    const fieldNames = ["documentId", "fileName", "filePath"];
    fieldNames.forEach((fieldName: string, key: number) => {
      setDocumentData((prevData) => ({
        ...prevData,
        [fieldName]: response[key],
      }));
    });
    // Extract file name and extension
    const fileNameWithExtension = response[1];
    const lastDotIndex = fileNameWithExtension.lastIndexOf(".");
    const fileName =
      lastDotIndex !== -1
        ? fileNameWithExtension.substring(0, lastDotIndex)
        : fileNameWithExtension;
    const extension =
      lastDotIndex !== -1
        ? fileNameWithExtension.substring(lastDotIndex + 1)
        : "";
    setDocumentData((prevState) => ({
      ...prevState,
      extension: extension,
      documentName: fileName,
    }));
  };

  const documentDeleteResponse = (field: string) => {
    const fieldNames = ["documentId", "fileName", "filePath"];
    fieldNames.forEach((fieldName: string, key: number) => {
      setDocumentData((prevData) => ({
        ...prevData,
        [fieldName]: fieldName.endsWith("Id") ? null : "",
      }));
      setDocumentData((prevData) => ({
        ...prevData,
        documentName: "",
        extension: "",
      }));
    });
  };

  const documentUploadErrors = (errorResposne: any) => {
    setDocumentErrors({
      documentId: errorResposne.error,
    });
  };

  const handleUpload = () => {
    setDocumentModalOpen(true);
  };

  const resetDocumentErrors = () => {
    setDocumentErrors({
      documentName: "",
      documentCategory: "",
      documentId: "",
    });
  };

  const resetDocumentFields = () => {
    setDocumentData({
      documentName: "",
      documentCategory: { value: 16, label: "Cooperation Agreement" },
      documentId: null,
      fileName: "",
      filePath: "",
      extension: "",
      selectedContact: null,
    });
  };

  const saveFileAndNotifyAM = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    const updatedDocumentData = {
      ...documentData,
      uploadedBy: userAuth.name,
      createdAt: new Date(),
      companyId,
      agreementId,
      approved: false,
      loggedInUserId: userAuth.userId,
      type: "file",
      langKey: lang_key,
    };
    if (documentValidation(name, value)) {
      const response = await ApiCall.service(
        ENDPOINTS.SEND_FOR_AM_APPROVAL,
        "POST",
        updatedDocumentData,
        true,
        COMPANY_MICROSERVICE
      );

      if (response.status === 200) {
        CustomNotify({ type: "success", message: t(response.msg) });
        navigate(MANAGE_COOPERATION_AGREEMENT);
      }
      setDocumentModalOpen(false); // Close modal
      resetDocumentFields();
      resetDocumentErrors();
    }
  };

  const handleSendToBright = async () => {
    const data = {
      type: "toBright",
      agreementId,
      companyId,
      langKey: lang_key,
    };
    const response = await ApiCall.service(
      ENDPOINTS.SEND_FOR_AM_APPROVAL,
      "POST",
      data,
      true,
      COMPANY_MICROSERVICE
    );

    if (response.status === 200) {
      CustomNotify({ type: "success", message: t(response.msg) });
      navigate(MANAGE_COOPERATION_AGREEMENT);
    }
  };

  const employerModal = () => {
    setSignAsEmployer(!signAsEmployer);
    setState((prevState) => ({
      ...prevState,
      selectedContact: null
    }));
  };

  const sendToEmployerModal = () => {
    setSendToEmployer(!sendToEmployer);
    setState((prevState) => ({
      ...prevState,
      selectedContact: null
    }));
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (selectedOption?.value !== "" || selectedOption?.value !== null) {
      setState((prevData) => ({
        ...prevData,
        selectedContact: selectedOption,
        // employerSign: selectedOption?.signature_exists,
        // signAsEmployerPopup: data !== null,
        email: selectedOption?.signature_exists && selectedOption?.email,
        password: '',
        employerId: selectedOption?.value,
        iframeUrl: `${process.env.REACT_APP_COMPANY_SERVICE}/${COOP_PREVIEW_BACKEND}/${companyId}/${agreementId}?employer=${selectedOption?.value}&verify=0&lang=${lang_key}`,
      }));
    }
    validation(fieldName, selectedOption);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Cooperation agreeement",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div>
        <iframe
          width="100%"
          src={state.iframeUrl}
          title="Embedded Content"
          allowFullScreen
          className="iframeHeight"
        ></iframe>
        <div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center mb-3">
                <CheckBoxField
                  name="acceptTerms"
                  onChangeHandler={changeHandler}
                  isChecked={state.acceptTerms === 1}
                  id="acceptTerms"
                  className="cursor-pointer"
                  label={t(
                    "I hereby declare that I have reviewed the document and confirming that I agree with all details."
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row py-4">
            <div className="col-md-2 align-self-center">
              <Link
                to={
                  employer
                    ? MANAGE_COOPERATION_AGREEMENT
                    : `/cooperation-agreement/create/${companyId}/${agreementId}?tabId=invoice`
                }
                className="back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            </div>

            <div className="col-10 align-self-center">
              {state.employerVerify === true && state.acceptTerms === 1 && (
                <Button
                  type="submit"
                  title={t("Approve")}
                  handleClick={() => handleSubmit(false)}
                  className="float-end form-button shadow-none marginRightPoint5"
                />
              )}
              {!employer &&
                state.acceptTerms === 1 &&
                state.employerVerify === false && (
                  <>
                    <Button
                      type="submit"
                      title={t("Sign as employer")}
                      handleClick={() => employerModal()}
                      className="float-end form-button shadow-none"
                    />
                    <Button
                      type="submit"
                      title={t("Send to employer")}
                      handleClick={() => sendToEmployerModal()}
                      className="float-end form-button shadow-none marginRightPoint5"
                    />
                    {feeBased !== "1" && (
                      <Button
                        type="submit"
                        title={t("Send to Bright")}
                        handleClick={handleSendToBright}
                        className="float-end form-button shadow-none marginRightPoint5"
                      />
                    )}
                  </>
                )}
              {state.acceptTerms === 1 && (
                <>
                  <Link
                    to={`${process.env.REACT_APP_COMPANY_SERVICE}/api/agreement/download/${companyId}/${agreementId}?lang=${lang_key}`}
                    className="float-end form-button shadow-none marginRightPoint5 d-flex align-items-center justify-content-center"
                  >
                    {t("Download")}
                  </Link>
                  <Button
                    type="submit"
                    title={t("Upload")}
                    handleClick={handleUpload}
                    className="float-end form-button shadow-none marginRightPoint5"
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <ModalPopup
          show={sendToEmployer}
          onHide={sendToEmployerModal}
          title={t("Send to employer")}
          body={
            <>
              <SelectWithSearch
                search={true}
                options={state.contacts}
                value={state.selectedContact}
                onChange={(e) => handleSelectChange(e, "contacts")}
                name="contacts"
                title="Contacts"
                error={errors.contacts}
                isMandatory={true}
              />
              <Button
                type="submit"
                title={t("Send")}
                handleClick={() => handleSubmit(true)}
                className="float-end form-button shadow-none marginRightPoint5"
              />
            </>
          }
          className="modal-lg"
        />
        {/* <ModalPopup
          show={state.signAsEmployerPopup}
          onHide={() => handleModalClose("employerSign")}
          title={t("Sign as employer")}
          body={
            <form onSubmit={authenticateEmployer}>
              <div>
                <LabelWithInputField
                  handleChange={changeHandler}
                  label={t("Email")}
                  type="text"
                  isDisabled={true}
                  id="email"
                  name="email"
                  value={state.email}
                  error={errors.email}
                  autoComplete="off"
                />
                <LabelWithInputField
                  handleChange={changeHandler}
                  isMandatory={true}
                  label={t("Password")}
                  type="password"
                  id="password"
                  name="password"
                  value={state.password}
                  error={errors.password}
                  autoComplete="new-password"
                />
                <Button
                  type="submit"
                  title={t("Authenticate")}
                  className="btn float-end form-button shadow-none rounded-3 me-2"
                />
              </div>
            </form>
          }
          className="modal-lg"
        /> */}
        {/* File upload modal popup */}
        <ModalPopup
          size="md"
          show={isDocumentModalOpen}
          onHide={closeDocumentModal}
          title={t("Document")}
          headerClassName={`col-12 mx-auto`}
          body={
            <>
              <div className="row">
                <div className="col-sm-12 col-md-6 upload-certificate">
                  <SelectWithSearch
                    search={true}
                    options={state.contacts}
                    value={documentData.selectedContact}
                    onChange={(e) =>
                      handleDocumentSelectChange(e, "selectedContact")
                    }
                    name="selectedContact"
                    title="Contacts"
                    isMandatory={true}
                    error={documentErrors.selectedContact}
                  />
                </div>
                <div className="col-sm-12 col-md-6 upload-certificate">
                  <FileUpload
                    id="documentId"
                    name="documentId"
                    label={t("File upload")}
                    isMandatory={true}
                    edit={false}
                    fileId={documentData.documentId ?? null}
                    fileName={documentData.fileName ?? ""}
                    filePath={documentData.filePath ?? ""}
                    style={fileUploadStyleObject}
                    multiple={false}
                    uploadPath={"company-cooperationAgreement"}
                    formats=".pdf"
                    returnFileUploadErrors={(errorResponse: any) =>
                      documentUploadErrors(errorResponse)
                    }
                    fileUploadResponse={(uploadResposne: any) => {
                      documentUploadResponse(uploadResposne);
                    }}
                    deleteResponse={(field: any) =>
                      documentDeleteResponse(field)
                    }
                    maxFileSize={10 * 1024 * 1024}
                    microserviceURL={`${process.env.REACT_APP_COMPANY_SERVICE}`}
                  />
                  <div
                    className="text-danger height-20"
                    style={{ marginBottom: "0.5vw" }}
                  >
                    <span className="text-danger">
                      {documentErrors.documentId}
                    </span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 d-flex align-items-center">
                  <div
                    style={{ width: documentData.extension ? "90%" : "100%" }}
                  >
                    <LabelWithInputField
                      isMandatory={true}
                      name="documentName"
                      handleChange={(event) => handleDocumentFieldChange(event)}
                      value={documentData.documentName}
                      label={t("File name")}
                      placeholder={t("File name")}
                      type="text"
                      error={documentErrors.documentName}
                    />
                  </div>
                  {documentData.extension && (
                    <span
                      className="fw-bold"
                      style={{ marginLeft: "0.25vw", width: "10%" }}
                    >
                      {documentData.extension !== ""
                        ? `.${documentData.extension}`
                        : ""}
                    </span>
                  )}
                </div>
                <div className="col-sm-12 col-md-6 upload-certificate">
                  <SelectWithSearch
                    title={t("Category")}
                    isMandatory={true}
                    search={true}
                    options={[{ value: 16, label: "Cooperation Agreement" }]}
                    onChange={(e) =>
                      handleDocumentSelectChange(e, "documentCategory")
                    }
                    isMulti={false}
                    isDisabled={true}
                    name="attestType"
                    value={{ value: 16, label: "Cooperation Agreement" }}
                    error={documentErrors.documentCategory}
                    isTranslate={true}
                  />
                </div>
              </div>
              <div className="col-12 mx-auto">
                <Button
                  handleClick={saveFileAndNotifyAM}
                  className="mx-0 shadow-none form-button float-end"
                >
                  {t("Save")}
                </Button>
              </div>
            </>
          }
          className="modal-lg documentsCertificates"
        />

        <SignAsEmployerModal
          companyId={companyId}
          show={signAsEmployer}
          handleSelectChange={handleSelectChange}
          state={state}
          setState={setState}
          employerModal={employerModal}
          errors={errors}
          changeHandler={changeHandler}
          checkAuthentication={authenticateEmployer}
        />
      </div>
    </AccessControl>
  );
};

export default translate(CoopAgreementPreview);
