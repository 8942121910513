import React, { useCallback, useEffect, useRef, useState } from "react";
import * as CONST from "../../../annotations/AdminFicheConstants";
import TableNames from "./TableNames";
import {
  calculateWeekTotal,
  initialTimeStates,
  TimeStates,
} from "components/common/utlis/PlanningUtility";
import { GetTableData } from "./GetTableData";
import ShiftData from "./ShiftData";
import { useFormContext } from "components/CompanyCreation/AdminFiche/context/Context";
import {
  scrollToTop,
  validateForm,
  validateRequired,
  validateSelectField,
} from "components/common/services/ValidationService";
import Navigation from "../../form-navigation/Navigation";
import {
  Option,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";

const WorkingHours = () => {
  const { state, dispatch } = useFormContext();
  const [activeTab, setActiveTab] = useState<number | null>(0);
  const shiftTables = Object.values(state.shiftTables ?? {});

  const col10Ref = useRef<HTMLDivElement | null>(null);
  const col2Ref = useRef<HTMLDivElement | null>(null);

  // Function to update the height of col-2 based on col-10's height
  const updateCol2Height = useCallback(() => {
    if (col10Ref.current && col2Ref.current) {
      const col10Height = col10Ref.current.offsetHeight;
      col2Ref.current.style.height = `${col10Height}px`;
      col2Ref.current.style.overflowY = "auto"; // Apply overflow styling
    }
  }, []);

  useEffect(() => {
    // Ensure the height is set correctly when the component first mounts or shiftTables length changes
    updateCol2Height(); // Set the height initially

    window.addEventListener("resize", updateCol2Height);

    return () => {
      window.removeEventListener("resize", updateCol2Height);
    };
  }, [updateCol2Height, shiftTables.length]); // Add shiftTables.length to dependencies

  const addTimeTable = useCallback(() => {
    dispatch({ type: CONST.ADD_NEW_TIMETABLE });
    const newTableIndex = shiftTables.length;
    setActiveTab(newTableIndex);
  }, [dispatch, shiftTables.length]);

  const deleteTimeTable = useCallback(
    (tableId: number) => {
      dispatch({ type: CONST.DELETE_TIMETABLE, tableId });
      if (shiftTables.length === 0) {
        setActiveTab(null);
      } else {
        const newActiveIndex = tableId === 0 ? 0 : tableId - 1;
        setActiveTab(newActiveIndex);
      }
    },
    [dispatch, shiftTables.length]
  );

  const handleTabClick = useCallback((tabId: number) => {
    setActiveTab(tabId);
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    tableId: number
  ) => {
    const { name, value } = e.target;
    let processedValue = value;
    if (name !== 'name' && (value === "" || !/[a-zA-Z]/.test(value))) {
      processedValue = value;
    } else if(name !== 'name') {
      return; // If it contains alphabets, do not dispatch the update
    }
    dispatch({
      type: CONST.UPDATE_SHIFT_DATA,
      field: name,
      value: processedValue,
      tableId,
    });

    validation(name, value, tableId);
  };

  const validation = (
    name: string,
    value:
      | string
      | boolean
      | Date
      | object[]
      | Option
      | null
      | undefined
      | number,
    index: number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      name: [validateRequired],
      regime: [validateSelectField],
      shift: [validateSelectField],
      pc: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...state.general, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: CONST.UPDATE_SHIFT_DATA,
      field: name + "Error",
      value: validationErrors[name],
      tableId: index,
    });

    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      dispatch({
        type: CONST.UPDATE_SHIFT_DATA,
        field: name + "Error",
        value: "",
        tableId: index,
      });
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSelectChange = async (
    selectedOption: any,
    title: string,
    tableId: number
  ) => {
    if (title === "regime") {
      dispatch({
        type: CONST.UPDATE_SHIFT_DATA,
        field: "shift",
        value: null,
        tableId,
      });
    }
    dispatch({
      type: CONST.UPDATE_SHIFT_DATA,
      field: title,
      value: selectedOption,
      tableId,
    });
    if (title === "shift") {
      const regime = shiftTables[tableId].regime?.value;
      const shift = selectedOption?.value;
      if (regime && shift) {
        const data = await GetTableData(regime, shift);
        dispatch({
          type: CONST.UPDATE_SHIFT_DATA,
          field: "timeTable",
          value: data,
          tableId,
        });
      } else {
        dispatch({
          type: CONST.UPDATE_SHIFT_DATA,
          field: "timeTable",
          value: initialTimeStates,
          tableId,
        });
      }
    }
    validation(title, selectedOption, tableId);
  };

  const handleWeeklyPlanningChange = (
    timeStates: TimeStates,
    tableId: number
  ) => {
    dispatch({
      type: CONST.UPDATE_SHIFT_DATA,
      field: "timeTable",
      value: timeStates,
      tableId,
    });
    dispatch({
      type: CONST.UPDATE_SHIFT_DATA,
      field: "totalTime",
      value: calculateWeekTotal(timeStates),
      tableId,
    });
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="form-border" style={{paddingTop:"3.4vw"}}>
            <div className="row">
              <div
                className="col-2 weeklyWorkingHours scrollBarDesign"
                id="style-1"
                ref={col2Ref}
                style={{ minHeight: "10vw" }}
              >
                <TableNames
                  state={state}
                  addTimeTable={addTimeTable}
                  deleteTimeTable={deleteTimeTable}
                  handleTabClick={handleTabClick}
                  activeTab={activeTab}
                />
              </div>
              <div className="col-10" ref={col10Ref}>
                <div className="row">
                  <ShiftData
                    state={state}
                    activeTab={activeTab}
                    handleChange={handleChange}
                    handleSelectChange={handleSelectChange}
                    handleWeeklyPlanningChange={handleWeeklyPlanningChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navigation />
    </form>
  );
};

export default WorkingHours;
