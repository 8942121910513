import { t } from "components/CentralDataMangement/translation/Translation";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import WageCodeAccordion from "./WageCodeAccordion";
import {
  CommProfileProps,
  WageTabProps,
} from "components/CompanyCreation/AdminFiche/annotations/AdminFicheInterface";
import Calender from "components/common/molecules/Calender";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import DeleteIcon from "static/images/DeleteIcon";
import CloneIcon from "static/images/CloneIcon";
import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";
import ModalPopup from "components/common/atoms/ModalPopup";
import MealAndEcoCheques from "./MealAndEcoCheques";

interface Props {
  wageData: WageTabProps;
  wageId: number;
  addWageData: (
    selectedOption: any,
    field: string,
    wageId?: number,
    profileId?: number
  ) => void;
  removeWageData: (
    wageId?: number,
    profileId?: number,
    codeId?: number
  ) => void;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    wageId: number,
    profileId?: number,
    codeId?: number,
    applicabilityId?: number
  ) => void;
  handleDateChange: (
    date: Date | null,
    filed: string,
    wageId: number,
    profileId?: number,
    codeId?: number
  ) => void;
  handleSelectChange: (
    value: Option | null,
    filed: string,
    wageId: number,
    profileId?: number,
    codeId?: number
  ) => void;
  clonePayProfile: (
    value: Option | null,
    wageId: number,
    profileId: number
  ) => void;
  perfCodeList: Object[];
  pcId: number | undefined;
  autoTypeList: OptionProps[];
  getCommProfileDropdown: (
    wageId: number,
    pcId: number | undefined
  ) => Option[];
  ecoBenefitList: OptionProps[];
}

const CommProfileAccordion: React.FC<Props> = ({
  wageData,
  wageId,
  addWageData,
  removeWageData,
  handleChange,
  handleDateChange,
  handleSelectChange,
  perfCodeList,
  pcId,
  autoTypeList,
  getCommProfileDropdown,
  clonePayProfile,
  ecoBenefitList
}) => {
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => setShowModal(!showModal);
  const cloneCommProfile = () => {
    setShowModal(true);
  };
  const getCodeDropdown = (profileId: number) => {
    // Check if wageData is available and profileId is defined
    if (!wageData || profileId === undefined) return [];

    // Filter perfCodeList based on pcId
    const filteredByPcId = perfCodeList.filter(
      (option: any) => option.paritaircommittee_id === pcId
    );

    // Get selected codes for the specified wageId and profileId
    const wageCodesArray = Object.values(
      wageData?.commProfile?.[profileId]?.wageCodes ?? {}
    );
    const selectedCodes = wageCodesArray.map((code) => code.wageCode) || [];

    // Filter the options that are not in selectedCodes
    const availableOptions = filteredByPcId
      .filter(
        (option: any) =>
          !selectedCodes.some(
            (code) => code && code.value === option.salary_benefit_main_id
          )
      )
      .map((option: any) => ({
        value: option.salary_benefit_main_id,
        label: `${option.code} - ${option.name}`,
      }));

    return availableOptions.length ? availableOptions : [];
  };

  const handleProfile = (
    selectedOption: Option | null,
    wageId: number,
    profileId: number
  ) => {
    clonePayProfile(selectedOption, wageId, profileId);
    setShowModal(false);
  };
  const wageDataArray = Object.values(wageData?.commProfile ?? {});

  return (
    <>
      <div className="row" key={`pc-${wageData.pc?.value}-mtc`}>
        {/* <div className="col-md-3">
          <div className="input-group adminFicheSuffixText">
            <LabelWithInputField
              type="text"
              label={t("Mtc total value")}
              handleChange={(e: any) => handleChange(e, wageId)}
              value={wageData.totalValue ?? ""}
              name="totalValue"
            />
            <span
              className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute end-0"
              id="basic-addon1"
            >
              €
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="input-group adminFicheSuffixText">
            <LabelWithInputField
              type="text"
              label={t("Mtc employer part")}
              handleChange={(e: any) => handleChange(e, wageId)}
              value={wageData.employerPart ?? ""}
              name="employerPart"
            />
            <span
              className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute end-0"
              id="basic-addon1"
            >
              €
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="input-group adminFicheSuffixText">
            <LabelWithInputField
              type="text"
              label={t("Mtc minimum hours")}
              handleChange={(e: any) => handleChange(e, wageId)}
              value={wageData.minimumHours ?? ""}
              name="minimumHours"
            />
            <span
              className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute end-0"
              id="basic-addon1"
            >
              €
            </span>
          </div>
        </div> */}
        <MealAndEcoCheques
          wageData={wageData}
          wageId={wageId}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
          ecoBenefitList={ecoBenefitList}
        />
      </div>
      {wageDataArray?.length > 0 &&
        wageDataArray.map((profile: CommProfileProps, profileId: number) => (
          <div
            className="d-flex align-items-start mb-3 w-100"
            key={`${wageId}-${profileId}`}
          >
            <Accordion
              defaultActiveKey=""
              key={`${wageId}-${profileId}`}
              className="w-100 coomercialProfileAccordion"
            >
              <Accordion.Item eventKey="">
                <Accordion.Header>
                  <div className="d-flex justify-content-between w-100 fw-bold">
                    <span>{profile.profile?.label}</span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-grid">
                    <div className="position-relative">
                      <Calender
                        onChange={(date) =>
                          handleDateChange(date, "startDate", wageId, profileId)
                        }
                        selectedDate={
                          profile.startDate ? new Date(profile.startDate) : null
                        }
                        label={t("Start date")}
                        name="startDate"
                        isMandatory={false}
                      />
                    </div>
                    {/* <div className="position-relative">
                      <Calender
                        onChange={(date) =>
                          handleDateChange(date, "endDate", wageId, profileId)
                        }
                        selectedDate={
                          profile.endDate ? new Date(profile.endDate) : null
                        }
                        label={t("End date")}
                        name="endDate"
                        isMandatory={false}
                        minDate={
                          profile?.startDate
                            ? new Date(profile.startDate)
                            : null
                        }
                      />
                    </div> */}
                    <div>
                      <div className="input-group mealVouchers">
                        <LabelWithInputField
                          type="text"
                          label={t("Mtc total value")}
                          handleChange={(e: any) =>
                            handleChange(e, wageId, profileId)
                          }
                          value={profile.mtcTotal ?? ""}
                          name="mtcTotal"
                        />
                        <span
                          className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute end-0"
                          id="basic-addon1"
                        >
                          €
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="input-group mealVouchers">
                        <LabelWithInputField
                          type="text"
                          label={t("Mtc employer part")}
                          handleChange={(e: any) =>
                            handleChange(e, wageId, profileId)
                          }
                          value={profile.mtcEmployer ?? ""}
                          name="mtcEmployer"
                        />
                        <span
                          className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute end-0"
                          id="basic-addon1"
                        >
                          €
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="input-group mealVouchers">
                        <LabelWithInputField
                          type="text"
                          label={t("Mtc minimum hours")}
                          handleChange={(e: any) =>
                            handleChange(e, wageId, profileId)
                          }
                          value={profile.mtcMinHrs ?? ""}
                          name="mtcMinHrs"
                        />
                        <span
                          className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute end-0"
                          id="basic-addon1"
                        >
                          €
                        </span>
                      </div>
                    </div>
                  </div>
                  <WageCodeAccordion
                    wageCode={profile.wageCodes}
                    wageId={wageId}
                    profileId={profileId}
                    removeWageData={removeWageData}
                    handleChange={handleChange}
                    handleSelectChange={handleSelectChange}
                    autoTypeList={autoTypeList}
                  />
                  <div className="hideLabel">
                    <SelectWithSearch
                      search={true}
                      options={getCodeDropdown(profileId)}
                      value={""}
                      onChange={(e) => addWageData(e, "code", wageId, profileId)}
                      name={"code"}
                      placeHolder={t("Select code")}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="ms-3 d-flex wagePremiumActions">
              {getCommProfileDropdown(wageId, pcId).length > 0 && (
                <span
                  title={t("Clone")}
                  onClick={() => cloneCommProfile()}
                  className="table-action-icons cursor-pointer rounded-0 shadow-none me-2"
                >
                  <CloneIcon />
                </span>
              )}
              <span
                title={t("Delete")}
                onClick={() => removeWageData(wageId, profileId)}
                className="table-action-icons cursor-pointer rounded-0 shadow-none"
              >
                <DeleteIcon />
              </span>
            </div>
            <ModalPopup
              show={showModal}
              onHide={handleModalClose}
              title={t("Commercial profile")}
              body={
                <div className="row">
                  <div className="col-12">
                    <SelectWithSearch
                      search={true}
                      options={getCommProfileDropdown(wageId, pcId)}
                      value={""}
                      onChange={(e) => handleProfile(e, wageId, profileId)}
                      name={"profile"}
                      placeHolder={t("Select commercial profile")}
                    />
                  </div>
                </div>
              }
            />
          </div>
        ))}
    </>
  );
};

export default React.memo(CommProfileAccordion);
